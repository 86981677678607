// External
import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { map, catchError, takeUntil, last } from 'rxjs/operators';
import { Observable, forkJoin, of, ReplaySubject, Subject, concat } from 'rxjs';

// Internal
import { CommercialidsService } from '../process/commercialids/commercialIds.service';
import { DevicesService } from '../process/devices/devices.service';
import { HideComponentService } from '../global/hide-component/hide-component.service';
import { IProfilesService } from '../interfaces/iprofiles/iprofiles.service';
import { NotificationsService } from '../process/notifications/notifications.service';
import { OffersService } from '../process/offers/offers.service';
import { PrivacyService } from '../process/privacy/privacy.service';
import { ProfilesService } from '../process/profiles/profiles.service';
import { PushService } from '../core/push.service';
import { ValuesService } from '../../values/values.service';
import { ISubscriptionsService } from '../interfaces/isubscriptions/isubscriptions.service';
import { NavigationService } from '../../navigation/navigation.service';
import { AdobeDataLayerService } from '../core/adobe.datalayer.service';
import { AppsConfigService } from '../../config/apps.config.service';
import { ModalRoutelessService } from '../../components/ui/ui-modal-routeless/modal.routeless.service';
import { SpecialCasesService } from './helpers/special.cases.service';
import { InvitesService } from '../process/subscriptions/invites.service';
import { DeviceInfoService } from '../global/device-info/device-info.service';
import { IdTheftProtectionService } from '../process/idtheftprotection/idtheftprotection.service';
import { PasswordManagerService } from '../process/password-manager/password-manager.service';
import { BusinessService } from '../process/business/business.service';
import { ATOService } from '../process/ato/ato.service';

@Injectable({
    providedIn: 'root'
})

export class DataResolver {

    private readonly routeResolved: ReplaySubject<boolean> = new ReplaySubject();
    private readonly onDestroy$: Subject<void> = new Subject<void>();

    _resolved = false;
    dataIsReady = false;

    constructor(
        @Inject(DOCUMENT) readonly document: any,
        private readonly commercialidsService: CommercialidsService,
        private readonly devicesService: DevicesService,
        private readonly hideComponentService: HideComponentService,
        private readonly iProfilesService: IProfilesService,
        private readonly notificationsService: NotificationsService,
        private readonly offersService: OffersService,
        private readonly privacyService: PrivacyService,
        private readonly profilesService: ProfilesService,
        private readonly pushService: PushService,
        private readonly valuesService: ValuesService,
        private readonly iSubscriptionsService: ISubscriptionsService,
        private readonly navigationService: NavigationService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly appsConfigService: AppsConfigService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly specialCasesService: SpecialCasesService,
        private readonly invitesService: InvitesService,
        private readonly deviceInfoService: DeviceInfoService,
        private readonly idTheftProtectionService: IdTheftProtectionService,
        private readonly passwordManagerService: PasswordManagerService,
        private readonly businessService: BusinessService,
        private readonly atoService: ATOService
    ) {}

    restartResolverRequests() {
        // ! TopPriority
        return forkJoin([
            this.commercialidsService.list(),
            this.devicesService.listDevices(),
            this.invitesService.list(),
            this.offersService.list().pipe(
                catchError( err => of(err))
            ),
            this.listProvidedIdentityNoError(),
            this.listProvidedIdentityBusinessAssetsExposureNoError(),
            this.pushService.registerPush(),
            this.deviceInfoService.list(),
            this.atoService.checkIdentityStatus().pipe(
                catchError(() => of(true))
            )
        ]).pipe(
            takeUntil(this.onDestroy$),
            map(() => {
                this.iSubscriptionsService.createBundlesInterfaceWrapper();
                this.appsConfigService.showInstallButtonForAllApps();
                this.iProfilesService.manage_device_profiles();
                this.notificationsService.sendEventNotifs(true);
                this.adobeDataLayerService.setRedirectStatus(true);
                this.idTheftProtectionService.listOnboardingStatus().pipe(
                    catchError( err => of(err))
                ).subscribe();
                this.passwordManagerService.listSaferpassStatus().pipe(
                    catchError( err => of(err))
                ).subscribe();
                return of(true);
            }),
            catchError(err => {
                return of(err)
            })
        );
    }

    /**
     * Makes listProvidedIdentity request from privacy but only returns a success response
     * so other requests in central do not fail and all pages load
     * @returns Observable
     */
    listProvidedIdentityNoError(): Observable<any> {
        return this.privacyService.listProvidedIdentity().pipe(
            map(() => of(true)),
            catchError(() => of(true))
        );
    }

    /**
     * Makes listIdentity request from business but only returns a success response
     * @returns {Observable<amy>} The observable
     */
    private listProvidedIdentityBusinessAssetsExposureNoError(): Observable<any> {
        return this.businessService.listIdentity().pipe(
            map(() => of(true)),
            catchError(() => of(true))
        );
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const urlProperties = this.navigationService.processUrl(route, state);

        const devicesList = (): Observable<boolean> => {
            return new Observable(subscriber => {
                this.devicesService.listDevices()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: () => {
                        subscriber.next(false);
                        subscriber.complete();
                    },
                    error: () => {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                });
            });
        };

        const specialCasesLogic = new Observable( subscriber => {
            const actionResponses = [];
            concat(
                this.specialCasesService._decideOpenInstallModalToSetupVSB(),
                this.specialCasesService._decideOnboardingVSB(),
                this.specialCasesService._decideAutomaticJoinGroup(),
                this.specialCasesService._decideReferral(),
                this.specialCasesService._decideMspXspState(urlProperties),
                this.specialCasesService._decideAutomaticRedeemRedirect(),
                this.specialCasesService._decideAutomaticTrialRedirect(),
                this.specialCasesService._decideAutomaticInviteRedirect(),
                this.specialCasesService._decideOnboarding(),
                this.specialCasesService._decideInstallRedirectFromQueryParams(),
                this.specialCasesService._decideRedeemRedirectFromQueryParams(),
                this.specialCasesService._decideRedirectFromExpiredSubscription(urlProperties),
                this.specialCasesService._decideSecuredPayment(),
                this.specialCasesService._decideCongrats(),
                this.specialCasesService._decideUnfinishedVSBOnboarding(),
            )
            .pipe(
                takeUntil(this.onDestroy$),
                map(response => {
                    if (response) {
                        actionResponses.push(response);
                    }
                }),
                last()
            ).subscribe({
                    next: () => {
                    //! Facem ce trebuie cu actiunile
                    // functie care rezolva actiunile in functii de prioritate
                    if (actionResponses.length) {
                        this.modalRoutelessService.open(
                                                        actionResponses[0].name,
                                                        actionResponses[0].containerOptions,
                                                        actionResponses[0].contentOptions,
                                                        false,
                                                        {
                                                            automatic: true
                                                        });
                    }
                    //! Dam drumul la data resolver
                    subscriber.next(true);
                    subscriber.complete();
                }
            });
        });

        return forkJoin([
            concat(
                devicesList(),
                specialCasesLogic
            ).pipe(last()),
            this.restartResolverRequests()
        ])
        .pipe(
            map(() => {
                this.hideComponentService.hideTabsSideMenu(this.profilesService.getOwner());
                this.dataIsReady = true;
                this.routeResolved.complete();
                this._resolved = true;
            }),
            catchError(() => of(true))
        );
    }

    decidePrivacy() {
        if (!this.appsConfigService.showApp(this.valuesService.appDIP)) {
            this.privacyService.updateProvidedIdentity();
        }
    }

    getState(): Observable<boolean> {
        return this.routeResolved.asObservable();
    }
}
