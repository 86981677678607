// External
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { filter, takeUntil } from 'rxjs/operators';

// Internal
import { TooltipService } from '../app/common/services/global/tooltip/tooltip.service';
import { ConfigService } from './common/config/config.service';
import { fadeAnimation } from './../ux/animations/fade.animations';
import { ValuesService, OperatingSystems } from './common/values/values.service';
import { ScriptService } from './common/services/core/scripts.service';
import { Subject } from 'rxjs';
import { MessageService } from './common/services/core/message.service';
import { AuthService } from './common/services/global/auth/auth-service.service';
import { ThemeService } from './common/services/core/theme.service';
import { AdobeDataLayerService } from './common/services/core/adobe.datalayer.service';
import { UsefulService } from './common/services/global/useful/useful.service';
import { ProductsConfigService } from './common/config/products.config.service';
import { ProfilesService } from './common/services/process/profiles/profiles.service';
import { ModalRoutelessService } from './common/components/ui/ui-modal-routeless/modal.routeless.service';
import { NavigationService } from './common/navigation/navigation.service';
import { CommercialidsService } from './common/services/process/commercialids/commercialIds.service';
import { DevicesService } from './common/services/process/devices/devices.service';
import { OffersService } from './common/services/process/offers/offers.service';
import { PrivacyService } from './common/services/process/privacy/privacy.service';
import { Outlet, OutletStatus, OutletsStatusService } from './common/services/core/outlets.status.service';
import { LogUpdateService } from './common/services/global/log-update/log-update.service';
import { CheckForUpdateService } from './common/services/global/check-for-update/check-for-update.service';
import { PromptUpdateService } from './common/services/global/prompt-update/prompt-update.service';
import { HandleUnrecoverableStateService } from './common/services/global/handle-unrecoverable-state/handle-unrecoverable-state.service';
import { PwaNotificationsService } from './common/services/global/pwa-notifications/pwa-notifications.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { getPwaActive } from './app.module';
import { ServiceWorkerHelperService } from './common/services/global/service-worker-helper/service-worker-helper.service';
import { HotjarService } from './common/services/core/hotjar.service';

// * Extend the existing window interface to tell it about the new property "adobeDataLayer"
declare global {
    interface Window {
        adobeDataLayer: any;
        hj: any;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [fadeAnimation],
    host: {
        '[class.elevation-0]': 'true'
    }
})

export class AppComponent implements OnInit {

    static loadingApp = true;
    triedToLoadAdobe = false;
    adobeAlreadySent = false;
    public canShowServiceWorkerBannerForPublicPages = false;
    url = "";
    private refreshHappened = false;
    auxUrl;
    private readonly onDestroy$: Subject<void> = new Subject<void>();
    _storedPath;

    constructor(
        private readonly router              : Router,
        private readonly authService        : AuthService,
        private readonly valuesService       : ValuesService,
        private readonly meta                : Meta,
        private  readonly configService      : ConfigService,
        private  readonly location           : Location,
        public  readonly tooltipService      : TooltipService,
        private  readonly translateService   : TranslateService,
        private readonly scriptService       : ScriptService,
        private readonly messageService      : MessageService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        public readonly usefulService        : UsefulService,
        private readonly themeService        : ThemeService,
        private readonly productsConfigService     : ProductsConfigService,
        private readonly profileService: ProfilesService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly navigationService: NavigationService,
        private readonly commercialIdsService: CommercialidsService,
        private readonly devicesService: DevicesService,
        private readonly offersService: OffersService,
        private readonly privacyService: PrivacyService,
        private readonly outletsStatusService: OutletsStatusService,
        private readonly logUpdateService: LogUpdateService,
        private readonly checkForUpdateService: CheckForUpdateService,
        private readonly pwaNotificationsService: PwaNotificationsService,
        public readonly promptUpdateService: PromptUpdateService,
        public readonly handleUnrecoverableStateService: HandleUnrecoverableStateService,
        public readonly serviceWorkerHelperService: ServiceWorkerHelperService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly hotjarService: HotjarService
    ) {
        // * Create empty array for data layer
        this.adobeDataLayerService.setOs();
        this.adobeDataLayerService.setPageAttributes();
        this.adobeDataLayerService.setServerName();

        this.themeService.initTheme();

        // ! CWEBII-3300 - Temporary until vpn page will be removed from Central ( Adobe should not load on VPN account page )
        const vpnPath = this.valuesService.centralPaths.services.path.concat(this.valuesService.centralPaths.services.vpn.path,
                                                                                this.valuesService.centralPaths.services.vpn.account.path);
        if (this.location.path().indexOf(vpnPath) === -1) {
            this.adobeDataLayerService.loadApi()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => true,
                error: () => true
            });
        }

        this.messageService.getMessage()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(message => {
            if (message.text === this.valuesService.events.targetActivityLoaded
                && this.url && this.url !== '/'
                && this.triedToLoadAdobe
                && !this.adobeAlreadySent
                && !this.url.includes(vpnPath)) {
                this.triedToLoadAdobe = false;
                this.adobeAlreadySent = true;
            }
        });

        this.prepareEntrypointForPwa();
    }

    /**
     * Getter for the loadingApp flag. Used for displaying the loader at page change.
     * @returns {boolean} True if the app is loading, false otherwise.
     */
    static getLoadingApp(): boolean {
        return AppComponent.loadingApp;
    }

    /**
     * Works as an entry point for all the PWA related stuff.
     */
    private prepareEntrypointForPwa(): void {
        if (getPwaActive()) {
            this.loadManifestDinamically();
        }
        this.pwaNotificationsService.listenToServiceWorkerMessageEvents();
    }

    /**
     * Dynamicaly adds the manifest file
     * @private
     */
    private loadManifestDinamically(): void {
        const element = document.createElement('link');
        const os = this.deviceDetectorService.os.toLocaleLowerCase() as OperatingSystems;
        element.setAttribute('rel', 'manifest');
        element.setAttribute('href', this.configService.getPwaManifestPathByOs(os));
        document.querySelector('head').appendChild(element);
    }

    decideOutlets(route) {
        if (route.url === '/' && !this.authService.checkLogin()) {
            this.router.navigate([this.valuesService.centralPaths['home'].path]);
        }
    }

    ngOnInit() {
        this.translateService.get('main.meta.description')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
            next: res => {
                this.meta.updateTag({
                    name: 'description',
                    content: res.replace('{{manufacturerName}}', this.productsConfigService.getManufacturerName()).replace('{{siteName}}', this.configService.config.siteName) },
                    'name=description'
                );
            },
            error: () =>{
                this.meta.updateTag({
                    name: 'description',
                    content: 'Tango is your control panel for subscription management, product installation, device security monitoring, and 24/7 support.' },
                    'name=description'
                );
            }
        });

        if (!navigator.cookieEnabled) {
            this.router.navigate([this.valuesService.centralPaths.cookies.path]);
            return;
        }

        //* Listener for location change end (Adobe)
        this.router.events.pipe(
            takeUntil(this.onDestroy$),
            filter(event => event instanceof NavigationStart
                            || event instanceof NavigationEnd
                            || event instanceof NavigationCancel
                            || event instanceof NavigationError)
        ).subscribe((event: NavigationStart|NavigationEnd|NavigationCancel|NavigationError) => {
            // sent adobe info only when the path from the navigation event is not empty or === to "/"
            // this + logic from constructor handles the following cases:
            // adobe loads, start navigation, end navgation
            // start navigation, adobe loads, end navigation
            // start navigation, ened navigation, adobe loads
            if (event instanceof NavigationStart) {
                /**
                 * Showing the loader at page change (next url is different than the current one),
                 * especially for service workers.
                 * Because I make all get request at page change, a second time, after I initiate the service woorker,
                 * the page stands still while the requests are made and it looks like there is no feedback for the user and nothing happens,
                 * while in reality the requests are being made and the data is updated.
                 */
                AppComponent.loadingApp = event.url !== this._storedPath;
                this.outletsStatusService.setStatus(Outlet.NAVIGATION, OutletStatus.PENDING);
                this.navigationService.setIsNavigationInProgress(true);

                if (!this.router.navigated) {
                    this.refreshHappened = true;
                }

                // ! Detect path change
                if (AppComponent.loadingApp) {
                    //! For leaving dashboard
                    this.outletsStatusService.setStatus(Outlet.PAGE, OutletStatus.PENDING);
                    this.adobeDataLayerService.resetInteractionValues();
                    this.adobeDataLayerService.resetPathSections();
                    this.adobeDataLayerService.resetUserDeviceValues();
                    this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_ONBOARDING, false);
                    this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL, false); //! NOT NEEDED NOW
                }
                // ! ------------------

                this.adobeAlreadySent = false;
                this.url = event.url;

                this.adobeDataLayerService.setUserLoggedIn(this.authService.checkLogin());
            } else if (event instanceof NavigationEnd) {
                let _url = event.urlAfterRedirects;
                _url = this.profileService.sanitizeUrlForParental(_url);

                if (this._storedPath !== _url) {
                    if (!this.modalRoutelessService.getPendingModal()) {
                        this.adobeDataLayerService.setUrl(_url);
                        this.adobeDataLayerService.setDestinationUrl(_url);
                        this.adobeDataLayerService.triggerPageLoad();
                        if (this._storedPath !== undefined) {
                            this.adobeDataLayerService.resetTrackingID();
                        }
                    }
                    this.adobeDataLayerService.setUrl(_url);
                    this._storedPath = _url;
                }
                this.navigationService.setIsNavigationInProgress(false);
                this.handleUnrecoverableStateService.checkIfServiceWorkerIsBroken(this.refreshHappened);
                this.refreshHappened = false;

                this.setActiveSessionForReloginScreen(_url);
                this.hotjarService.triggerSurveys();
                this.messageService.sendMessage(this.valuesService.events.dataResolverDone, {});
                this.outletsStatusService.setStatus(Outlet.NAVIGATION, OutletStatus.DONE);
                AppComponent.loadingApp = false;
            } else if (event instanceof NavigationCancel) {
                this.commercialIdsService.resetInProgressFlags();
                this.devicesService.resetInProgressFlags();
                this.offersService.resetInProgressFlags();
                this.privacyService.resetInProgressFlags();
                AppComponent.loadingApp = false;
            } else if (event instanceof NavigationError) {
                /* I also rese it here because I am scared it will not be reset in the other cases */
                AppComponent.loadingApp = false;
            }

            if (!this.scriptService.isAdobeScriptLoaded()) {
                this.triedToLoadAdobe = true;
            } else if (event instanceof NavigationStart && event?.url && event.url !== '/') {
                this.adobeAlreadySent = true;
                this.triedToLoadAdobe = false;
            } else if (event instanceof NavigationEnd && event?.urlAfterRedirects && event.urlAfterRedirects !== '/' && !this.adobeAlreadySent) {
                this.triedToLoadAdobe = false;
                this.adobeAlreadySent = true;
            }
        });

        this.router.events.pipe(
            takeUntil(this.onDestroy$),
            filter(event => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            this.decideOutlets(event);
        });
    }

    /**
     * Sets active session in order to figure out if user is logged in and successfully made all mandatory requests.
     * That's because the user successfully navigated to a page that requires authentication.
     * @param {string} url An url like '/services/vpn?param1=value1&param2=value2'
     */
    private setActiveSessionForReloginScreen(url: string): void {
        const path = this.usefulService.computePathPieces(url).path;
        if (this.valuesService.centralPaths[path]?.authenticated) {
            this.canShowServiceWorkerBannerForPublicPages = false;
            this.authService.setHasActiveSession(true);
        } else if (path !== this.valuesService.centralPaths['500'].path && path !== this.valuesService.centralPaths['404'].path) {
            this.canShowServiceWorkerBannerForPublicPages = true;
        }
    }
}
