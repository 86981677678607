import { CustomValidatorErrorInterface } from '../services/global/useful/useful.service';
import { SharedSubscriptionScenarios } from '../values/subscriptions.values.service';
import { GroupMgmtDataErrorCodes, GroupMgmtErrorCodes, GroupRoles } from './subscriptions/Groups.model';
import { ImageDescription } from '../values/values.service'

export type OnboardingBenefitsInterface = OnboardingBenefits[];

export interface OnboardingBenefits {
    icon: {
        type: string;
        icon: string;
    };
    title: string;
    description: string;
    hideForSecondary?: boolean;
}

interface OnboardingBenefitsCta {
    label: string;
    cta: Function;
}

export interface OnboardingBenefitsButtonsCtas {
    left: OnboardingBenefitsCta;
    right: OnboardingBenefitsCta;
}

type OnboardingBenefitsButtonsCtasByRole = {
    [Key in GroupRoles]?: OnboardingBenefitsButtonsCtas
}

export interface OnboardingBenefitsButtonsCtasByScenario {
    [key: string]: OnboardingBenefitsButtonsCtasByRole|OnboardingBenefitsButtonsCtas
}

export type OnboardingBenefitsCtasByShareScenarios = {
    [key in SharedSubscriptionScenarios]?: OnboardingBenefitsButtonsCtasByScenario|OnboardingBenefitsButtonsCtas
}

interface ActivateExtendTitle {
    extendTitle: string;
    activateTitle: string;
    extendTitleOther?: string;
}

export interface OnboardingActivateExtendTitle {
    [key: string]: ActivateExtendTitle
}

export enum OnboardingFormNames {
    GROUP_NAME = 'groupName',
    IS_GROUP_OWNER = 'isGroupOwner',
    ADMIN_EMAL = 'adminEmail',
    REQUEST = 'request'
}

export type OnboardingGroupFormErros =  {
    [key in OnboardingFormNames]?: CustomValidatorErrorInterface|string
}

export interface OnboardingModalFlags {
    switchLoading: boolean,
    switchError: boolean,
    activateLoading: boolean,
    confirmActivationLoading: boolean,
    tryAgainLoading: boolean,
    hideSlots: boolean,
    hideEndDate: boolean,
    extensionOnly: boolean,
    isActivationFlow: boolean,
    showPassManager: boolean,
    showInstallBtn: boolean,
    isInvite: boolean,
    isGroupInvitation: boolean,
    isSharedSubscription: boolean,
    isVSBAdminInvite: boolean,
    sharedSubscriptionScenario: SharedSubscriptionScenarios,
    hasGroupManagement: boolean,
    groupSetupForm: {
        hasError: boolean,
        isLoading: boolean
    },
    showATO: boolean
}

export interface OnboardingErrorDetails {
    title: string;
    description: string;
    button: string;
    buttonAction: OnboardingActions;
    illustration?: ImageDescription;
}

export interface OnboardingActivateErrorDetails {
    [key: string]: OnboardingErrorDetails
}

type OnboardingJoinDataError = {
    [key in GroupMgmtDataErrorCodes]?: OnboardingErrorDetails
}

export type OnboardingJoinErrors = {
    [key in GroupMgmtErrorCodes]?: OnboardingJoinDataError|OnboardingErrorDetails
}

export enum OnboardingActions {
    BUY = 'buy',
    TRY = 'try',
    ACTIVATE = 'activate',
    GET_STARTED = 'getStarted',
    LATER = 'later',
    DONE = 'done',
    TRY_AGAIN_REDEEM = 'tryAgainRedeem',
    REACTIVATE = 'reactivate',
    OFFERS = 'offers',
    TRY_AGAIN_INVITE = 'tryAgainInvite',
    BUY_PASSWORD_MANAGER = 'buyPasswordManager',
    GO_TO_SUBSCRIPTIONS = 'goToSubscriptions',
    SETUP_GROUP = 'setupGroup',
    CONFIRM_CONTEXT_SWITCH = 'confirmContextSwitch',
    CONFIRM_INVITE_SWITCH = 'confirmInviteSwitch',
    CONFIRM_GROUP_SWITCH = 'confirmGroupSwitch',
    TRY_AGAIN_JOIN_GROUP = 'tryAgainJoinGroup',
    CONTACT_SUPPORT = 'contactSupport',
    VIEW_OFFER = 'viewOffer'
}

export interface OnboardingActionDetails {
    show?: boolean;
    action: Function;
}

export type OnboardingActionsDetails = {
    [key in OnboardingActions]: OnboardingActionDetails
}

export interface ExpiredScenarioInterface {
    image: ImageDescription,
    title: string,
    warning: string,
    description: string,
    reactivate?: string,
    viewOffer?: string
}

interface ExpiredTexts {
    description: string;
    warning: string;
    viewOffer?: string;
}

export type ExpiredTextsByRole = {
    [Key in GroupRoles]?: ExpiredTexts
}