import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, HostListener, ViewContainerRef, Type, NgModuleRef, createNgModuleRef, Injector, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MessageService } from '../../../services/core/message.service';
import { ValuesService } from '../../../../common/values/values.service';
import { modalAnimation } from '../../../../common/values/modal.values';
import { OutletsStatusService, Outlet, OutletStatus } from '../../../../common/services/core/outlets.status.service';
import { ModalRoutelessService } from './modal.routeless.service';
import { AdobeDataLayerService } from '../../../../common/services/core/adobe.datalayer.service';
import { ProfilesService } from '../../../../common/services/process/profiles/profiles.service';
import { ParentalService } from '../../../../common/services/process/parental/parental.service';
import { toPascalCase } from '../../../utils/utils-common.service';
import { ModalContainerOptions } from '../../../services/global/modal/Modal.model';
import { browserAnimationsDisabled } from '../../../../app.module';
import { ContextService } from '../../../services/global/context/context.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'ui-modal-routeless',
    template: `
        <div class="modal-dialog"
            [class]="containerOptions.exceptionClass"
            [ngClass]="containerOptions.isFlow ? 'flow' : 'single'"
            tabindex="0" #firstFocus>

            <ng-container #container></ng-container>

            <button id="btn_1580731950" ui-button [iconMid]="closeIcon" class="modal-close" (click)="closeModal()"
                *ngIf="containerOptions.buttonDismissable && !isAnimating"><span>&times;</span></button>

            <span tabindex="0" #lastFocus></span>
        </div>
        <span class="modal-backdrop" [ngClass]="{'dismissable': containerOptions.backdropDismissable}"
            (click)="containerOptions.backdropDismissable && closeModal()"></span>
    `,
    host: {
        '[class.modal]': 'true',
        '[class.show]': 'isOpen',
        '[class.animating]': 'isAnimating',
        '[class.mobile]': 'isMobile'
    }
})

export class UiModalRoutelessComponent implements OnInit, OnDestroy {

    containerOptions = new ModalContainerOptions();
    contentOptions: any;

    pathToRedirect = "";
    extrasToRedirect = null;

    private readonly onDestroy$: Subject<void> = new Subject<void>();

    private shiftedTabDirection = false;
    private isOpen = false;
    private activatedComponent: ComponentRef<any>;
    isAnimating = false; // for hiding external elements, like close btn and preventing clicks while animating

    // focus trap
    private firstFocus;
    private lastFocus;
    @ViewChild('firstFocus', { read: ElementRef }) set firstFocusRef(el: ElementRef) {
        if (el) {
            this.firstFocus = el.nativeElement;
        }
    }
    @ViewChild('lastFocus', { read: ElementRef }) set lastFocusRef(el: ElementRef) {
        if (el) {
            this.lastFocus = el.nativeElement;
        }
    }
    @ViewChild('container', { static: false, read: ViewContainerRef })
    public viewRef: ViewContainerRef;
    public closeIcon = {
        type: 'general',
        value: 'x'
    };

    public readonly isMobile = this.deviceDetectorService.isMobile();

    constructor(
        private readonly modalRoutelessService  : ModalRoutelessService,
        private readonly el                     : ElementRef,
        private readonly renderer               : Renderer2,
        private readonly messageService         : MessageService,
        private readonly valuesService          : ValuesService,
        private readonly outletsStatusService   : OutletsStatusService,
        private readonly _injector              : Injector,
        private readonly adobeDataLayerService  : AdobeDataLayerService,
        private readonly router                 : Router,
        private readonly profileService         : ProfilesService,
        private readonly contextService         : ContextService,
        private readonly deviceDetectorService  : DeviceDetectorService,
        public readonly parentalService         : ParentalService,

    ) {
    }

    ngOnInit() {
        this.modalRoutelessService.openEventBroadcaster
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
            modalInfo => {
                import(`src/app/common/modal-dialog/${modalInfo.name}.module`)
                .then(
                    importedFile => {
                        this.outletsStatusService.setStatus(Outlet.MODAL, OutletStatus.PENDING);
                        this.setIsAnimating(true);
                        this.renderer.addClass(document.body, 'no-overflow');
                        this.isOpen = true;

                        this.contentOptions = modalInfo.contentOptions;
                        this.containerOptions = this.computeContainerOptions(modalInfo.containerOptions);
                        const newComponent = this.createNewComponent(modalInfo, importedFile);

                        if (this.activatedComponent) {
                            this.animateExitingEl(this.activatedComponent.location, null, this.containerOptions.reverseAnimation, () => {
                                this.viewRef.remove()
                            });
                        }
                        this.animateEnteringEl(newComponent.location, this.viewRef.length === 1, this.containerOptions.reverseAnimation, () => {
                            this.firstFocus.focus();
                            this.setIsAnimating(false);
                        });

                        this.activatedComponent = newComponent;

                        this.addOmnitureInformation();
                    }
                );
            }
        );

        this.modalRoutelessService.closeEventBroadcaster
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
            value => {
                this.closeModal(value);
            }
        );

        /**
         * If navigaion happens while a modal is opened, the destination path is recieved here
         * That path is used for navigation after the modal closes
         */
        this.messageService.getMessage()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(message => {
            if (message.text === this.valuesService.events.locationToRedirect && message?.options?.path) {
                this.pathToRedirect = message.options.path;
                this.extrasToRedirect = message.options.extras;
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    closeModal(newRoute?: string) {
        if (!this.isOpen) {
            return;
        }

        this.setIsAnimating(true);

        this.animateExitingEl(this.activatedComponent.location, true, this.containerOptions.reverseAnimation, () => {
            // ! A fost adaugata pt cazurile in care e nevoie sa se inchida modala si sa duca pe alta pagina principala.
            // ! Putea fi scoasa din metoda insa suntem siguri ca nimeni nu greseste redirectul.
            this.modalRoutelessService.resetVarsOnClose();

            this.messageService.sendMessage(this.valuesService.events.uiModalClose, {options: this.contentOptions, style: this.containerOptions});

            this.viewRef.remove();
            this.activatedComponent = undefined;
            this.setIsAnimating(false);

            this.isOpen = false;
            this.renderer.removeClass(document.body, 'no-overflow');

            //! On close we should trigger a route reload for the Adobe events
            if (newRoute) {
                this.router.navigate([newRoute])
                .then(() => {
                    this.reloadPageAfterContextSwitch();
                });
                this.adobeDataLayerService.removeModal();
            } else {
                const _url = this.profileService.sanitizeUrlForParental(this.router.url);
                this.adobeDataLayerService.setDestinationUrl(_url);
                this.adobeDataLayerService.setPageInfo(_url, this.modalRoutelessService.getPendingModal());
                this.adobeDataLayerService.removeModal();
                this.adobeDataLayerService.triggerPageLoad();
                this.reloadPageAfterContextSwitch();
            }
        });
    }

    /**
     * Method used to reload the page after a context switch
     * @private
     * @memberof UiModalRoutelessComponent
     * @param {none}
     * @returns {void}
     */
    private reloadPageAfterContextSwitch(): void {
        if (this.contextService.getReloadAfterContextSwitchFlag()) {
            window.location.reload();
        }
    }

    /**
     * Cmputes a neew component based on the modal info and the imported file in order to replace the current one at opening
     * @param modalInfo The object givn to the modal to retrieve info about the flow, aspect and other flags and options
     * @param importedFile The imported modal file
     * @returns The new component to be injected
     */
    private createNewComponent(modalInfo: any, importedFile: any) {
        const moduleName = `${toPascalCase(modalInfo.name)}Module`;
        const componentName = `${toPascalCase(modalInfo.name)}Component`;

        const componentToOpen = importedFile[moduleName].components[componentName];
        const module: Type<any> = importedFile[moduleName];
        const moduleRef: NgModuleRef<any> = createNgModuleRef(module, this._injector);

        return this.viewRef.createComponent(componentToOpen, { ngModuleRef: moduleRef, index: 0  });
    }

    /**
     * Method used to add omniture information for the modal.
     */
    private addOmnitureInformation() {
        this.adobeDataLayerService.resetModalSections();
        this.modalRoutelessService.setActiveStep('first');

        const _url = this.profileService.sanitizeUrlForParental(window.location.pathname);
        this.adobeDataLayerService.setModal(this.modalRoutelessService.getPendingModal());
        this.adobeDataLayerService.setPageInfo(_url, this.modalRoutelessService.getPendingModal());
        this.adobeDataLayerService.triggerPageLoad();
    }

    // animating function
    animateEnteringEl(el: ElementRef, isFirstEl?, reverseAnimation?, onFinishCallback?) {
        this.renderer.addClass(el.nativeElement, 'modal-component');
        this.renderer.addClass(el.nativeElement, `size-${this.containerOptions.size}`);

        if (browserAnimationsDisabled) {
            onFinishCallback();
            return;
        }

        const horizontalEnter = reverseAnimation ? modalAnimation.fromLeft : modalAnimation.fromRight;
        const enter = isFirstEl ? modalAnimation.fromBottom : horizontalEnter;
        const enterAnimation = el.nativeElement.animate(enter.animation, enter.timing);

        if (onFinishCallback) {
            enterAnimation.addEventListener('finish', onFinishCallback);
        }
    }

    animateExitingEl(el: ElementRef, isLastElement?, reverseAnimation?, onFinishCallback?) {
        if (isLastElement) {
            this.containerOptions.isFlow = false;
        } else {
            this.renderer.setStyle(el.nativeElement, 'position', 'absolute');
        }

        if (browserAnimationsDisabled) {
            onFinishCallback();
            return;
        }

        const horizontalExit = reverseAnimation ? modalAnimation.toRight : modalAnimation.toLeft;
        const exit = isLastElement ? modalAnimation.toBottom : horizontalExit;
        const exitAnimation = el.nativeElement.animate(exit.animation, exit.timing);

        if (onFinishCallback) {
            exitAnimation.addEventListener('finish', onFinishCallback, true);
        }
    }

    /**
     * Computes containerOptions needed in modal's styling.
     * @param {ModalContainerOptions} options
     * * If no options are given default modalContainer styling options will be applied.
     * * If some options are given but is not a flow situation (isFlow flag is not mentioned or it's false),
     * the new options will be merged with default options
     * * If it's a flow (isFlow is set as true), isFlow and reverseAnimation options will be merged with the previous modal's containerOptions
     * (all other defined options will be ignores)
    */
    computeContainerOptions(options: ModalContainerOptions) {
        if (options && Object.keys(options).length) {
            if (options.isFlow) {
                return {
                    ...this.containerOptions,
                    isFlow: options.isFlow,
                    reverseAnimation: options.reverseAnimation || this.containerOptions.reverseAnimation
                }
            }
            else {
                return { ...new ModalContainerOptions(), ...options }
            }
        } else {
            return new ModalContainerOptions();
        }

    }

    setIsAnimating(value){
        this.isAnimating = value;
        this.modalRoutelessService.setIsAnimating(value);
    }

    // before browser back
    @HostListener('window:popstate', ['$event']) onPopState(event) {
        if(this.isOpen) {
            this.closeModal();
        }
    }

    // Dismiss on escape should be possible only if modal is of dismissable type (from backdrop pr close-btn)
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event) {
        if (this.containerOptions.backdropDismissable || this.containerOptions.buttonDismissable) {
            this.closeModal();
        }
    }

    // Backdrop click event
    @HostListener('mousedown', ['$event']) onMouseDownHandler(event) {
        if (event.button === 0 && event.target === this.el.nativeElement && this.containerOptions.backdropDismissable) {
            this.closeModal();
        }
    }

    @HostListener('keydown.tab', ['$event']) onTabPress(event) {
        this.shiftedTabDirection = false;
        if(event.target === this.lastFocus) {
            this.firstFocus.focus();
        }
    }

    @HostListener('keydown.shift.tab', ['$event']) onShiftTabPress(event) {
        this.shiftedTabDirection = true;
        if(event.target === this.firstFocus) {
            this.lastFocus.focus();
        }
    }

    @HostListener('focusin', ['$event']) onFocusIn(event) {
        if(!this.shiftedTabDirection && event.target === this.lastFocus) {
            this.firstFocus.focus();
        }
    }
}
