//External
import { BehaviorSubject, catchError, map, Observable, of, skipWhile } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

//Internal
import { IdentityModel } from '../../common/models/privacy/Identity.model';
import { PrivacyEducationArticle, PrivacySetArticlesInterface } from '../../common/models/privacy/PrivacyEducationArticle.model';
import { PrivacyValuesService } from '../../common/values/privacy.values.service';
import { ValuesService } from '../../common/values/values.service';
import { UtilsCommonService } from '../../common/utils/utils-common.service';
import { LanguageService } from '../../common/services/core/language.service';
import { ModalName, ModalRoutelessService } from '../../common/components/ui/ui-modal-routeless/modal.routeless.service';
import { ModalSize } from '../../common/services/global/modal/Modal.model';
import { PrivacySettings } from '../../common/models/PrivacySettings.model';
import { SettingsService } from '../../common/services/process/settings/settings.service';
import { SettingsMgmtService } from '../../common/services/requests/connect-settings/connect-settings.service';

export enum PrivacyModalFlow {
    ONBOARDING = 'GENERAL',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    VERIFY_PHONE = 'VERIFY_PHONE',
    VERIFY_SM = 'VERIFY_SM',
    EDIT_NAME = 'EDIT_NAME'
}

@Injectable({
    providedIn: 'root'
})

export class PrivacyActionsService {

    private readonly onListPrivacySettings$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private readonly onSetConfigParam$ : BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private markToUpdatePrivacySettings = true;
    private configSettings: PrivacySettings;

    public selectedCategoryForEducation;

    constructor(
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly router: Router,
        private readonly settingsMgmtService: SettingsMgmtService,
        private readonly settingsService: SettingsService,
        private readonly utilsService: UtilsCommonService,
        private readonly languageService: LanguageService,
        private readonly valuesService: ValuesService,
        private readonly translateService: TranslateService,
        private readonly privacyValuesService: PrivacyValuesService
    ) {}

    /**
     * Function that gets all DIP settings and saves them
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {none}
     * @returns {Observable<PrivacySettings>}
     */
    public listConfigParams(): Observable<any> {
        if (!this.markToUpdatePrivacySettings) {
            return of(this.configSettings);
        }

        if (this.onListPrivacySettings$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListPrivacySettings$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        }

        this.onListPrivacySettings$.next(this.valuesService.processServiceState.INPROGRESS);
        return this.settingsService.listDipSettings()
        .pipe(
            map(() => {
                this.configSettings = this.settingsService.getDipSettings();
                if (!this.configSettings || this.utilsService.isEmptyObject(this.configSettings)) {
                    this.configSettings = new PrivacySettings();
                    this.settingsMgmtService.setAll(JSON.parse(JSON.stringify(this.configSettings)), this.valuesService.appDIP).subscribe();
                }
                this.onListPrivacySettings$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdatePrivacySettings = false;
                return of(this.configSettings);
            }),
            catchError(err => {
                this.onListPrivacySettings$.next(this.valuesService.processServiceState.DONE);
                this.markToUpdatePrivacySettings = false;
                throw err;
            })
        );
    }

    /**
     * Function that sets the value for one setting
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {string} setting representing the setting name
     * @param {boolean|number} value representing the value of the setting
     * @returns {Observable}
    */
    setConfigParam(setting: string, value: boolean|number): Observable<any> {
        if (this.onSetConfigParam$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onSetConfigParam$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onSetConfigParam$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.settingsMgmtService.setOne(setting, value, this.valuesService.appDIP)
            .pipe(
                map(() => {
                    this.onSetConfigParam$.next(this.valuesService.processServiceState.DONE);
                    this.configSettings[setting] = value;
                }),
                catchError(() => {
                    this.onSetConfigParam$.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                })
            );
        }
    }

    /**
     * Function that returns all dip settings saved
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {none}
     * @returns {PrivacySettings}
     */
    public getConfigParams(): PrivacySettings {
        return this.configSettings;
    }

    /**
     * Function that returns the value of a setting
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {string} setting representing the setting name
     * @returns {string|boolean|number} representing the value of the setting
     */
    public getConfigParam(setting: string): string|boolean|number {
        return this.configSettings?.[setting];
    }

    /**
     * Function that opens DIP onboarding modal standard email/phone
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {PrivacyModalFlow} type representing the flow type
     * @param {PrivacyExposure} item representing metadata info
     * @returns {void}
     */
    public openDpyOnboarding(type: PrivacyModalFlow): void {
        const containerOptions = {
            size: ModalSize.XL,
            buttonDismissable: true,
            backdropDismissable: true
        };
        const contentOptions = {
            flow: type,
            finished_step: null,
        };

        if (type === PrivacyModalFlow.VERIFY_EMAIL) {
            this.modalRoutelessService.open(ModalName.onboardingStandardEmailModal, containerOptions, contentOptions);
        } else if (type === PrivacyModalFlow.VERIFY_PHONE) {
            this.modalRoutelessService.open(ModalName.onboardingStandardChoosePhoneModal, containerOptions, contentOptions);
        } else if (type === PrivacyModalFlow.EDIT_NAME) {
            this.modalRoutelessService.open(ModalName.onboardingStandardNameModal, containerOptions, contentOptions);
        }
    }
    //#endregion

    //#region education
    /**
     * Function that compose the article object
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {Array<string>} educationArticlesTags
     * @param {object} cardsArticles
     * @returns {void}
     */
    public setArticleObject(educationArticlesTags: Array<string>, cardsArticles: object): void {
        for (const article of educationArticlesTags) {
            cardsArticles[article] = [];
        }
    }

    /**
     * Function that sets and returns articles
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {PrivacySetArticlesInterface} setArticlesInfo
     * @returns {Array<PrivacyEducationArticle>}
     */
    public setArticles(setArticlesInfo: PrivacySetArticlesInterface): Array<PrivacyEducationArticle> {
        for (const articleCard in setArticlesInfo.cardsArticles) {
            let articles = [];
            for (const art of setArticlesInfo.infoArticles) {
                if (art.topic === setArticlesInfo.articlesTopic && art.tags.includes(articleCard)) {
                    articles.push(art);
                }
            }
            setArticlesInfo.cardsArticles[articleCard] = articles;
        }
        return setArticlesInfo.cardsArticles[setArticlesInfo.staticTag];
    }

    /**
     * Function that returns the article path by tag
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {string} tag representing the article
     * @param {object} cardsArticles contains all articles for each tag
     * @returns {string}
     */
    public getArticlePathByTag(tag: string, cardsArticles: object): string {
        let articlePath = '';
        if (cardsArticles[tag]) {
            articlePath = this.getArticlePath(cardsArticles[tag][0]);
        }
        return articlePath;
    }

    /**
     * Function that returns the path for given article
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {object} article representing the article info
     * @returns {string}
     */
    public getArticlePath(article: object): string {
        let articlePath = '';
        if (article) {
            articlePath = this.privacyValuesService.pages.article + article['article_id'];
        }
        return articlePath;
    }

    /**
     * Function that returns language code
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {none}
     * @returns {string}
     */
    public getLanguageCategory(): string {
        return this.languageService.getLang()?.split('_')?.[0] ?? '';
    }

    /**
     * Function that computes prefixes for all countries
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {none}
     * @returns {Array<any>} representing an array of computed country objects
     */
    public computeCountryPrefixes(): Array<any> {
        /* this should not be needed, the problem is inside the component where this is used, it needs refactor */
        const completedPrefixes = JSON.parse(JSON.stringify(this.privacyValuesService.country_prefixes_all));

        for (const prefix of completedPrefixes) {
            prefix.country_name = this.translateService.instant('country.'.concat(prefix.iso2));
        }

        return completedPrefixes.sort((a, b) => a.country_name.localeCompare(b.country_name));
    }

    public openEducation(topic: string): void {
        this.selectedCategoryForEducation = topic;
        this.router.navigate([this.privacyValuesService.pages.education]);
    }

    /**
     * Function that sets and returns the new articles, different from the static ones
     *
     * @public
     * @memberof PrivacyActionsService
     * @param {IdentityModel} identity
     * @param {PrivacyEducationArticle} newArticles
     * @param {PrivacyEducationArticle} staticArticles
     * @returns {Array<PrivacyEducationArticle>}
     */
    public setArticlesForEducationCard(identity: IdentityModel, newArticles: PrivacyEducationArticle[], staticArticles: PrivacyEducationArticle[]): Array<PrivacyEducationArticle> {
        const now = new Date();
        const one_day = 1000 * 60 * 60 * 24;
        const dateDiff = parseInt((Math.round(now.getTime() - new Date(identity.date_created).getTime()) / (this.valuesService.MILISECONDS_IN_A_DAY)).toFixed(0), 10);
        const maxArticleLength = 5;

        const weeksOldArticles = {
            ONE:    7,
            TWO:   14,
            THREE: 21
        };
        const staticArticlesNo = {
            ONE:    1,
            TWO:    2,
            THREE:  3
        };

        let articles: PrivacyEducationArticle[] = [];

        if (dateDiff < weeksOldArticles.ONE) {
            if (staticArticles.length > staticArticlesNo.THREE) {
                staticArticles = staticArticles.slice(0, staticArticlesNo.THREE);
            }
        } else if (dateDiff < weeksOldArticles.TWO) {
            if (staticArticles.length > staticArticlesNo.ONE) {
                staticArticles = staticArticles.slice(0, maxArticleLength - staticArticles.length - staticArticlesNo.ONE);
            }
        } else if (dateDiff < weeksOldArticles.THREE) {
            if (staticArticles.length > staticArticlesNo.TWO) {
                staticArticles = staticArticles.slice(0, maxArticleLength - staticArticles.length - staticArticlesNo.TWO);
            }
        } else {
            staticArticles = [];
        }

        for (const staticArticle of staticArticles) {
            staticArticle.isNew = false;
        }

        const newArticlesNoDuplicates = [];
        for (const newArt of newArticles) {
            const temp = parseInt((Math.round(now.getTime() - new Date(newArt.publish_date).getTime()) / (one_day)).toFixed(0));
            newArt.isNew = temp < 7;
            let isAlreadyInStaticList = false;
            for (const staticArt of staticArticles) {
                if (staticArt.article_id === newArt.article_id) {
                    isAlreadyInStaticList = true;
                    break;
                }
            }
            if (!isAlreadyInStaticList) {
                newArticlesNoDuplicates.push(newArt);
            }
        }

        articles = articles.concat(newArticlesNoDuplicates.slice(0, maxArticleLength - staticArticles.length)).concat(staticArticles);
        return articles;
    }

}

