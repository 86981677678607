// External
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

// Internal
import { DevicesService } from '../../process/devices/devices.service';
import { SubscriptionsService } from '../../process/subscriptions/subscriptions.service';
import { ValuesService } from '../../../../common/values/values.service';
import { LanguageService } from '../../core/language.service';
import { SubscriptionsHelperService } from '../../../../pages/subscriptions/subscriptions.helper.service';
import { ConnectMgmtService } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { InvoicesService } from '../../process/subscriptions/invoices.service';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ProductsConfigService } from '../../../../common/config/products.config.service';
import { CampaignMediaIdentifiers, CampaignNames, CampaignParameters } from '../../core/AdobeParams.model';
import { BuyLinksService } from '../../../../common/links/buyLinks.service';
import {
    AppModel,
    BundleModel,
    DeviceAllocationDisplayComponents,
    AppConfigurationStatus,
    LinksServiceModel,
    DeviceModel,
    SharedSubscriptionInvite,
    SharedSubscriptionInvitesInfo
} from '../../../../common/models/Services.model';
import { InvitesService } from '../../process/subscriptions/invites.service';
import { PrivacyService } from '../../process/privacy/privacy.service';
import { PasswordManagerService } from '../../process/password-manager/password-manager.service';
import { IdTheftProtectionService } from '../../process/idtheftprotection/idtheftprotection.service';
import { ATOService } from '../../process/ato/ato.service';
import {
    SubscriptionInviteStatus,
    SubscriptionsValuesService,
    UpsellTypes
} from '../../../values/subscriptions.values.service';
import { GroupTypes } from '../../../models/subscriptions/Groups.model';
import { GroupManagementService } from '../../process/subscriptions/group-management.service';
import { defaultRoleByGroup } from '../../../../common/values/business.values.service';
import { BusinessService } from '../../process/business/business.service';
import { AdobeDataLayerService } from '../../../../common/services/core/adobe.datalayer.service';
import { UsefulService } from '../../global/useful/useful.service';

export interface InstallModalAppInterface {
    occupiedSlots: number;
    occupiedSlotsRelated?: number;
    totalSlots?: number;
    devices?: Array<DeviceModel>,
    app_id?: string;
}

interface InstallModalAppsInterface {
    [appIdLevel: string]: InstallModalAppInterface
}

interface SlotsTextModel {
    label: string;
    placeholders: {
        number: number;
        total?: number;
    };
}

export const NO_LEVEL = 'no_level';
export const ALL_PLATFORMS = 'all_platforms';
export const ALL_PLATFORMS_NO_WINSERVER = 'all_platforms_no_winserver';

@Injectable({
    providedIn: 'root'
})

export class ISubscriptionsService {

    private installModalAppsInterface: InstallModalAppsInterface = {};

    constructor(
        private readonly devicesService: DevicesService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly translate: TranslateService,
        private readonly languageService: LanguageService,
        private readonly valuesService: ValuesService,
        private readonly translateService: TranslateService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly subscriptionsHelperService: SubscriptionsHelperService,
        private readonly connectMgmtService: ConnectMgmtService,
        private readonly productsConfigService: ProductsConfigService,
        private readonly profilesService: ProfilesService,
        private readonly invoicesService: InvoicesService,
        private readonly appsConfigService: AppsConfigService,
        private readonly buyLinksService: BuyLinksService,
        private readonly invitesService: InvitesService,
        private readonly privacyService: PrivacyService,
        private readonly passwordManagerService: PasswordManagerService,
        private readonly idTheftProtectionService: IdTheftProtectionService,
        private readonly atoService: ATOService,
        private readonly groupManagementService: GroupManagementService,
        private readonly businessService: BusinessService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly usefulService: UsefulService
    ) {}

    /**
     * Get bundle image
     * For compatibily reasons isubscriptions.getImage is used in more then one place still. should include in refactor.
     */
    public getImage = function(bundle) {
        return  this.subscriptionsService.getImage(bundle);
    };

    /**
     * Get bundle level (bundle_id & app_id must be the same for bundle with basic/premium levels in values)
     */
    private getLevel = function(bundle): string {
        let level;
        bundle.applications.forEach(app => {
            if (app.hasOwnProperty('app_params')) {
                if (app.app_params.hasOwnProperty('level')) {
                    level = app.app_params.level;
                    return;
                }
            }
        });
        return level;
    };

    /**
     * Get bundle description
     */
    private getDescription(bundle: BundleModel, typeOfDescription: string): string {
        const bundleObj = this.productsConfigService.getBundle(bundle.bundle_id, bundle?.processed?.isSharedBundle);
        const level = this.getLevel(bundle);
        let desc: any;

        if (bundleObj) {
            const type = typeOfDescription === 'maindescription' ? 'mainDesc' : 'desc';
            desc = bundleObj[type];

            if (desc && typeof desc === 'object') {
                desc = desc[level];
            }
        }

        return desc;
    }

    public daysRemaining(bundle): number|undefined {
        let result;
        if (bundle.end_date) {
            result = Math.round((bundle.end_date - bundle.server_time) / this.valuesService.SECONDS_IN_A_DAY);
        }
        return result;
    }

    private computeBillingCycle(bundle) {
        let result;
        const billingCycle  = bundle?.metadata?.billing_cycle;
        const billingPeriod = bundle?.metadata?.billing_period;

        if (bundle.metadata && billingCycle) {
            // Conditie temporara in cazul subscriptiilor monthly - pana se repara in checkout
            if ((billingPeriod && billingPeriod === this.subscriptionsValuesService.cycles.monthlyBillingPeriod) || billingCycle === this.subscriptionsValuesService.monthDays) {
                result = this.translate.instant('subscriptions.service.monthly');
            }

            if ((!result && billingCycle === this.subscriptionsValuesService.year)
                || (billingPeriod && billingPeriod === this.subscriptionsValuesService.cycles.yearlyBillingPeriod)) {
                result = this.translate.instant('subscriptions.service.yearly');
            }

            if (billingCycle === this.subscriptionsValuesService.biennal) {
                result = this.translate.instant('subscriptions.service.biennial');
            }


            if (billingCycle === this.subscriptionsValuesService.triennal) {
                result = this.translate.instant('subscriptions.service.triennial');
            }
        }
        return result;
    }

    public createBundlesInterfaceWrapper(): void {
        this.createBundlesInterface();
        this.createBundlesInterfaceButtons();
    }

    private createBundlesInterfaceButtons(): void {
        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            for (const bundle of this.subscriptionsService.get()[serviceType]) {
                bundle.interface.buttons = this.createDropdownManageButtonsInterface(bundle);
            }
        }
    }

    // Creates interface for < /subscriptions >. Property interface is attached to _subscriptions.bundles
    private createBundlesInterface(): void {
        const tempInstallModalAppsInterface: InstallModalAppsInterface = {};
        const lang = this.languageService.getLang();
        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            for (const bundle of this.subscriptionsService.get()[serviceType]) {
                this.filterBISApp(bundle);
                this.filterBAApp(bundle);
                const endDate = bundle.end_date ? bundle.end_date * 1000 : 0;
                const renewalDate = bundle.metadata && bundle.metadata.billing_date ? bundle.metadata.billing_date : bundle.end_date;
                moment.locale(lang.split('_')[0]);

                const bundleInterface = {
                    name:               bundle.bundle_friendly_name,
                    renewalDate:        renewalDate,
                    price:              bundle.metadata && bundle.metadata.billing_cycle_price ? bundle.metadata.billing_cycle_price : 0,
                    currency:           bundle.metadata && bundle.metadata.currency ? bundle.metadata.currency : 'no currency' ,
                    daysRemaining:      this.daysRemaining(bundle),
                    devices:            this.subscriptionsService.getActiveDevices(bundle),
                    expired:            bundle.status === "EXPIRED" ? true : false,
                    image:              this.subscriptionsService.getImage(bundle),
                    description:        this.getDescription(bundle, 'description'),
                    mainDesc:           this.getDescription(bundle, 'maindescription'),
                    type:               bundle.type,
                    serviceTypeLocal:   bundle.service_type === this.subscriptionsValuesService.serviceType.license
                                        ? 'subscription.type.license'
                                        : 'subscription.type.subscription',
                    billing_cycle:      this.computeBillingCycle(bundle),
                    end_date:           endDate,
                    level:              this.getLevel(bundle),
                    hideEndDate:        this.subscriptionsService.hideEndDate(bundle),
                    owner:              this.subscriptionsHelperService.getSubscriptionsOwner(bundle),
                    endDateLocale:      moment(endDate).format('LL'),
                    renewalDateLocale:  moment(renewalDate * 1000).format('LL'),
                    billingDateLocale:  bundle.metadata && bundle.metadata.billing_date ? moment(bundle.metadata.billing_date * 1000).format('LL') : '',

                    startPlan:          bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.startPlan,
                    personalPlan:       bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.personalPlan,
                    familyPlan:         bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.familyPlan,
                    ultimatePlan:       bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.ultimatePlan
                };
                bundle.interface = bundleInterface;
                // links
                bundle.interface.install        = this.computeInstallInterface(bundle);
                bundle.interface.invites        = this.createInvitesInterface(bundle);
                bundle.interface.badge          = this.createBadgeInterface(bundle);
                bundle.interface.expiryAndUsage = this.computeExpiryAndUsageInterface(bundle);
                bundle.isSinglePlatformSubscription = this.subscriptionsService.isSinglePlatformBundle(bundle);
                bundle.isMultiPlatformSubscription  = this.subscriptionsService.isMultiPlatformBundle(bundle);

                this.createApplicationsInterface(bundle, tempInstallModalAppsInterface);
            }
        }

        this.installModalAppsInterface = tempInstallModalAppsInterface;
    }

    public createServiceDetailsInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        bundle.interface.links = this.createLinksInterface(bundle);
    }

    /**
     * Create service details modal interface for given bundle
     * @param {BundleModel} bundle
     * @returns {void}
     */
    public createServiceDetailsModalInterface(bundle: BundleModel): void {
        const details = {
            applications: bundle.applications.filter(item => !this.valuesService.appsNotShownServiceDetails.has(item.app_id)),
            singleAppBundleFeatures: this.getSingleAppBundleFeatures(bundle)
        };
        bundle.interface.details = details;
    }

    private getSingleAppBundleFeatures(bundle): string[] {
        let features = [];
        if (this.valuesService.singleAppBundlesFeatures.hasOwnProperty(bundle.bundle_id)) {
            features = this.valuesService.singleAppBundlesFeatures[bundle.bundle_id];
        }
        return features;
    }

    private showConvert(bundle) {
        const isZuora           = this.subscriptionsService.hasZuora() || this.invoicesService.hasInvoicesCookie();
        const isSubscription    = bundle.service_type === this.subscriptionsValuesService.serviceType.license;
        const expired           = bundle?.interface?.expired;
        const twoMonthsDays     = bundle?.interface?.daysRemaining
                                    && bundle.interface.daysRemaining <= this.subscriptionsValuesService.twoMonthsDays;
        const availableSlots    = this.subscriptionsValuesService.availableForConversionBundleSlots[bundle.bundle_id]?.has(bundle.slots);

        return isZuora && isSubscription && (twoMonthsDays || expired) && availableSlots;
    }

    // arr(auto renewal) off - adica fie reactivezi auto renewal (zuora), fie apare butonul de renew/buy
    // arr(auto renewal) on - adica fie dezactivezi auto renewal (zuora), fie nu faci nimic pt ca nu ai nimic de facut (nu apare renew ca se face automat renew)
    private createDropdownManageButtonsInterface(bundle) {
        let arrON = false;
        const canHaveRenewLink  = !this.valuesService.bundlesWithoutRenewLink.has(bundle.bundle_id);
        const buttonsInterface = {
            manageButtons: [],
            manageDevices: false,
            // upsell
            arrOFFButton: null,
            zuoraConversion: null,
            showBuyMoreProtection: this.subscriptionsService.showBuyMoreProtectionLink(bundle),
            buyOrRenew: null
        };

        // Used for shorter conditions
        const check = {
            // source type
            isZuora:        this.subscriptionsService.isZuora(bundle),
            isVerifone:     this.subscriptionsService.isVerifone(bundle),
            isGooglePlay:   this.subscriptionsService.isGooglePlay(bundle),
            isApple:        this.subscriptionsService.isApple(bundle),
            isOffline:      this.subscriptionsService.isOffline(bundle),
            // service type
            isLicense:      bundle.service_type === this.subscriptionsValuesService.serviceType.license,
            // life cycle
            isActive:       bundle.status === this.subscriptionsValuesService.status.active,
            isLifetime:     bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.lifetime
                            && bundle.interface.devices.length > 0,
            isDpy:          this.subscriptionsService.isDpy(bundle),
            isIDTP:         this.subscriptionsService.isIdTheftProtection(bundle),
            isPM:           this.subscriptionsService.isPasswordManager(bundle),
            isHva:          this.subscriptionsService.isHva(bundle),
            isTrial:        bundle.type === this.subscriptionsValuesService.type.trial,
            isRenewableDpy: this.subscriptionsService.isDpy(bundle)
                            && bundle.end_date
                            && bundle?.metadata?.billing_cycle,
            isGainer:       this.subscriptionsService.accountOwnerIsGainerForBundle(bundle),
            lastMonth:      bundle.interface.daysRemaining !== undefined
                            && bundle.interface.daysRemaining <= this.subscriptionsValuesService.monthDays,
            hasMsp:         this.profilesService.hasMspOrXspLevelOne(),
            showConvert:    false && this.showConvert(bundle)
        };

        if (!check.isDpy
            && !check.isIDTP
            && !check.isHva
            && !check.isPM
            && bundle.interface.devices.length
            && bundle.status === this.subscriptionsValuesService.status.active
            && !check.isLifetime) {
            buttonsInterface.manageDevices = true;
        }

        if (!check.isGainer
            && check.isActive
            && !check.hasMsp
            && bundle.processed.arrON
            && this.appsConfigService.showDployFlowForBundle(bundle)) {
            if (check.isZuora || check.isVerifone) {
                buttonsInterface.manageButtons.push(this.subscriptionsValuesService.manageButtons.autoRenewON);
            }
            this.subscriptionsService.setHasAutoRenewalOn();
            arrON = true;
        }

        if (!check.isGainer
            && !arrON
            && !check.hasMsp
            && bundle.processed.arrOFF
            && check.isZuora) {
            buttonsInterface.arrOFFButton = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.ARROFF];
        }

        // will never show, showConvert is always false
        if (check.showConvert) {
            buttonsInterface.zuoraConversion = this.subscriptionsValuesService.upsellScenariosByTypeAndStatus[UpsellTypes.ZUORA][bundle.status];
        };

        if (!check.isGainer
            && (this.bundleHasInstallButton(bundle) || bundle?.interface?.install?.showBuyOrRenew)
            && !arrON
            && !buttonsInterface.arrOFFButton
            && !check.hasMsp
            && (check.isTrial
                || this.isUpgradeableBIS(bundle)
                || (check.isRenewableDpy && check.lastMonth)
                || (canHaveRenewLink && check.lastMonth && !check.isZuora && !check.isVerifone
                    && (check.isLicense || check.isGooglePlay || check.isApple)))) {
            const source = this.buyLinksService.computeRenewSource(bundle);
            const campaign = this.buyLinksService.computeRenewCampaign(bundle);
            const properties = this.computeBuyOrRenewProperties(bundle);
            buttonsInterface.buyOrRenew = {
                link: this.buyLinksService.getStaticOrCommercialBuyLink(bundle, false, source, '', true, campaign, CampaignMediaIdentifiers.LINK),
                cta: properties.cta,
                description: properties.description
            };
        }

        return buttonsInterface;
    }

    /**
     * Crestes links interface
     * @private
     * @memberof ISubscriptionsService
     * @param {BundleModel} bundle The bundle object
     * @returns {LinksServiceModel} The links interface for given bundle
     */
    private createLinksInterface(bundle: BundleModel): LinksServiceModel {
        const linksInterface: LinksServiceModel = {};
        const check = {
            hasMsp: this.profilesService.hasMspOrXspLevelOne()
        };

        const bundleConfig = this.productsConfigService.getBundle(bundle.bundle_id, bundle?.processed?.isSharedBundle);
        const website = bundleConfig?.website;
        const seeAllFeatures = bundleConfig?.seeAllFeatures;
        const systemRequirements = bundleConfig?.systemRequirements;
        const userGuide = bundleConfig?.userGuide;

        if (!check.hasMsp && website) {
            const adobeParameters: CampaignParameters = {
                internal: true,
                mediaId: CampaignMediaIdentifiers.LINK,
                campaignName: CampaignNames.WEBSITE
            };
    
            const website = this.productsConfigService.getBundleWebsiteLink(bundle.bundle_id, bundle?.processed?.isSharedBundle);
            linksInterface.website  = this.adobeDataLayerService.addCidOrIcidRefAndVisitor(website, adobeParameters);
        }

        if (!check.hasMsp && seeAllFeatures) {
            linksInterface.features = seeAllFeatures;
        }

        if (!check.hasMsp && systemRequirements) {
            linksInterface.systemRequirements = systemRequirements;
        }

        if (!check.hasMsp && userGuide) {
            linksInterface.userGuide = this.productsConfigService.getBundleUserGuideLink(bundle.bundle_id, bundle?.processed?.isSharedBundle);
        }

        return linksInterface;
    }

    private isUpgradeableBIS(bundle) {
        return bundle.bundle_id === this.valuesService.bundleBIS
                && bundle.status === this.subscriptionsValuesService.status.active
                && !this.subscriptionsService.hasBIP();
    }

    private computeBuyOrRenewProperties(bundle) {
        let cta = '';
        let description = '';

        if (bundle.type === this.subscriptionsValuesService.type.trial) {
            cta = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.TRIAL].cta;
            description = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.TRIAL].description;
        } else if (this.isUpgradeableBIS(bundle)) {
            cta = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.FREE].cta;
            description = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.FREE].description;
        } else {
            cta = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.EXPIRED].cta;
            description = this.subscriptionsValuesService.upsellScenariosByType[UpsellTypes.EXPIRED].description;
        }
        return {description, cta};
    }

    /**
     * Creates the interface of apps needed for device allocation
     * @private
     * @memberof ISubscriptionsService
     * @param {BundleModel} bundle
     * @returns {Array<AppModel>}
     */
    private createApplicationsAllocationInterface(bundle: BundleModel): Array<AppModel> {

        const applications = [];
        const allPlatforms = bundle.interface?.protectionApps?.[this.concatAppIdAndLevel(ALL_PLATFORMS_NO_WINSERVER, NO_LEVEL)];

        if (allPlatforms) {
            applications.push(this.createAllPlatformsSecurityApp(allPlatforms, bundle));
        }

        for (const app of bundle.applications) {
            if (allPlatforms && this.appsConfigService.isInAllProtectionAppNoWinserver(app)) {
                continue;
            }
            if (!this.valuesService.appsNotShownInManageDeviceAllocation.has(app.app_id)) {
                applications.push(app);
            }
        }

        return applications.sort((a, b) => this.serviceAppsPrioritySort(a.app_id, b.app_id));
    }

    /**
     * Custom sorting function for apps using subscriptionsValuesService.serviceAppsPriority as preset order
     * @public
     * @memberof ISubscriptionsService
     * @param {string} firstAppId app_id of the first app to be sorted
     * @param {string} secondAppId app_id of the second app to be sorted
     * @returns {number} The priority sorting
     */
    public serviceAppsPrioritySort(firstAppId: string, secondAppId: string): number {
        const firstAppIdIndex = this.subscriptionsValuesService.serviceAppsPriority.indexOf(firstAppId);
        const secondAppIdIndex = this.subscriptionsValuesService.serviceAppsPriority.indexOf(secondAppId);

        // If one of the items is not found in the preferred order, place it at the end
        if (firstAppIdIndex === -1) {
            return 1;
        }
        if (secondAppIdIndex === -1) {
            return -1;
        }

        return firstAppIdIndex - secondAppIdIndex;
    }

    /**
     * Creates generic 'Security' app that contains data about all security apps combined
     * @public
     * @memberof ISubscriptionsService
     * @param {InstallModalAppInterface} combinedAppsInfo
     * @param {BundleModel} bundle
     * @returns {AppModel} the generic app for all platforms
     */
    public createAllPlatformsSecurityApp(combinedAppsInfo: InstallModalAppInterface, bundle: BundleModel): AppModel {
        const allPlatformsSecurity = {
            app: {
                app_id: ALL_PLATFORMS_NO_WINSERVER,
                app_params: {
                    level: NO_LEVEL
                }
            },
            appKey : this.concatAppIdAndLevel(ALL_PLATFORMS_NO_WINSERVER, NO_LEVEL)
        };

        return {
            app_id: allPlatformsSecurity.app.app_id,
            countable: 0,
            slots: {
                max: combinedAppsInfo.totalSlots,
                min: 0,
            },
            interface: {
                showDeploy: true,
                configurationStatus: this.computeAppConfigurationStatus(allPlatformsSecurity.app.app_id, combinedAppsInfo.occupiedSlots),
                appFriendlyName: this.productsConfigService.getProductName(this.valuesService.productNameSecurity),
                image: this.productsConfigService.getAppImage(allPlatformsSecurity.app),
                slotsText: this.computeSlotsText(allPlatformsSecurity.app.app_id, combinedAppsInfo.totalSlots),
                occupiedSlots: combinedAppsInfo.occupiedSlots,
                occupiedSlotsTotal: combinedAppsInfo.occupiedSlots + combinedAppsInfo.occupiedSlotsRelated,
                osText: this.computeLabelForAppOses(this.valuesService.appProtectionNoWinserver),
                description: this.productsConfigService.getAppDescription(allPlatformsSecurity.app),
                devices: combinedAppsInfo.devices,
                usage: this.computeAppsDeviceAllocationUsageInterface(combinedAppsInfo.devices.length),
            },
        };
    }

    /**
     * Computes the condition for excluding a device from the devices interface for a bundle
     * @param {any} account The account object from the usage summary of the bundle
     * @returns {boolean} true if the device must be excluded, false otherwise
     */
    private excludeDeviceFromBundleDeviceInterface(account: any): boolean {
        return !account?.usage_summary || (this.profilesService.getOwnerEmail() !== account?.email && !this.profilesService.isOwnerVSBAdmin());
    }

    private createDevicesInfoAllocationInterface(bundle) {
        if (!bundle?.accounts) {
            return;
        }

        const appsForEveryDevice = {};
        for (const deviceId of bundle.interface.devices) {
            const appIdsForCurrentBundleAndDevice = [];
            const compatibleBundlesForEveryDevice = [];
            for (const account of bundle.accounts) {
                if (this.excludeDeviceFromBundleDeviceInterface(account)) {
                    continue;
                }

                for (const usage of account.usage_summary) {
                    if (usage.slot_id === deviceId) {
                        appIdsForCurrentBundleAndDevice.push(usage.app_id);
                    }
                }
            }

            for (const otherBundle of this.subscriptionsService.getAllServices()) {
                const otherAppIds = [];
                if (otherBundle.slots - otherBundle.interface.devices.length >= 1
                    && !otherBundle.interface.expired
                    && otherBundle.service_id !== bundle.service_id) {
                        for (const application of otherBundle.applications) {
                            otherAppIds.push(application.app_id);
                        }

                        // testam egalitatea array-urilor de app ids
                        const equalitySet = new Set(Array.from(appIdsForCurrentBundleAndDevice).concat(Array.from(otherAppIds)));
                        if (equalitySet.size >= appIdsForCurrentBundleAndDevice.length && equalitySet.size === otherAppIds.length) {
                            compatibleBundlesForEveryDevice.push(otherBundle);
                        }
                }
            }
            appsForEveryDevice[deviceId] = {
                appIds: Array.from(appIdsForCurrentBundleAndDevice),
                bundles: compatibleBundlesForEveryDevice
            };
        }
        return appsForEveryDevice;
    }

    public createDeviceAllocationInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        const allocation = {
            applications: this.createApplicationsAllocationInterface(bundle),
            devices: this.createDevicesInfoAllocationInterface(bundle)
        };
        bundle.interface.allocation = allocation;
    }

    private createBadgeInterface(bundle) {
        // conteaza ordinea !!
        const badgeChecks = {
            [this.subscriptionsValuesService.badgesTypes.expiringToday]:  this.subscriptionsHelperService.expiringToday(bundle),
            [this.subscriptionsValuesService.badgesTypes.expiredToday]:   this.subscriptionsHelperService.expiredToday(bundle),
            [this.subscriptionsValuesService.badgesTypes.trial]:          this.subscriptionsHelperService.isTrial(bundle),
            [this.subscriptionsValuesService.badgesTypes.new]:            this.subscriptionsHelperService.isNew(bundle),
            [this.subscriptionsValuesService.badgesTypes.endingInDay]:    this.subscriptionsHelperService.isEndingInDay(bundle),
            [this.subscriptionsValuesService.badgesTypes.endingInDays]:   this.subscriptionsHelperService.isEndingInDays(bundle),
            [this.subscriptionsValuesService.badgesTypes.expiredDayAgo]:  this.subscriptionsHelperService.expiredDayAgo(bundle),
            [this.subscriptionsValuesService.badgesTypes.expiredDaysAgo]: this.subscriptionsHelperService.expiredDaysAgo(bundle)
        };

        for (const badgeScenario in badgeChecks) {
            if (badgeChecks[badgeScenario] && this.subscriptionsValuesService.badges[badgeScenario]) {
                return this.subscriptionsValuesService.badges[badgeScenario];
            }
        }
        return {};
    }

    /**
     * Filter the BIS (free) because the bundle already has BIP (payed)
     * @param {BundleModel} bundle The bundle
     */
    public filterBISApp(bundle: BundleModel): void {
        const applications = bundle?.applications ?? [];
        let doNotIgnoreBISApp = 2;
        const noBISApplicattions = [];
        for (const application of applications) {
            if (application.app_id !== this.valuesService.appBIS) {
                noBISApplicattions.push(application);
            }

            if (application.app_id === this.valuesService.appBIS || application.app_id === this.valuesService.appBIP) {
                doNotIgnoreBISApp--;
            }
        }

        if (!doNotIgnoreBISApp) {
            bundle.applications = noBISApplicattions;
        }
    }

    /**
     * Filter the BA because the user is an employee
     * @param {BundleModel} bundle The bundle
     */
    private filterBAApp(bundle: BundleModel): void {
        if (!this.profilesService.isOwnerVSBBasicEmployee()) {
            return;
        }

        const applications = bundle?.applications ?? [];
        const noBAApplicattions = [];
        for (const application of applications) {
            if (application.app_id !== this.valuesService.appBA) {
                noBAApplicattions.push(application);
            }
        }

        bundle.applications = noBAApplicattions;
    }

    public createApplicationsInterface(bundle, installModalAppsInterface: InstallModalAppsInterface) {
        const protectionAppsForTotalSecurityInterface: InstallModalAppsInterface = {};

        for (const application of bundle.applications) {
            const devices = this.getAppDevices(application, bundle);
            const appInterface = {
                image:            this.productsConfigService.getAppImage(application),
                installIncentive: this.getAppInstallIncentive(application),
                appIdLevel:       this.getAppIdLevel(application),
                appFriendlyName:  this.productsConfigService.getAppName(application),
                devices:          devices,
                usage:            this.computeAppsDeviceAllocationUsageInterface(devices.length),
                deviceName:       '',
                // subscriptions service-item details
                isBox:            application.app_id === this.valuesService.appBOX1 || application.app_id === this.valuesService.appBOX2,
                configurationStatus: this.computeAppConfigurationStatus(application.app_id, devices.length),
                showDeploy:       this.appsConfigService.showDeployFlow(application.app_id),
                slotsText:        this.computeSlotsText(application.app_id, application.slots.max),
                occupiedSlots:    devices.length,
                occupiedSlotsTotal: devices.length + this.countOccupiedSlotsOfRelatedAccounts(application.app_id, bundle),
                osText:           this.computeLabelForAppOses(application.app_id),
                product:          this.productsConfigService.getAppProductToInstall(application.app_id),
                description:      this.productsConfigService.getAppDescription(application),
                extraText:        this.computeExtraText(application)
            };

            application.interface = appInterface;

            this.addProtectionAppsInfoAndOtherAppsInfoToSeparateObjects(protectionAppsForTotalSecurityInterface, installModalAppsInterface, application, bundle, devices);

            if (bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.lifetime) {
                if (devices.length > 0) {
                    application.interface.deviceName = this.getDeviceNameFromDevices(application, bundle, devices);
                } else {
                    this.getDeviceNameFromService(application, bundle).then(resp => {
                        application.interface.deviceName = resp;
                    });
                }
            }
        }

        this.addProtectionAppsInfoToBundleInterface(protectionAppsForTotalSecurityInterface, bundle);
        this.addProtectionAppsInfoToInstallModalInterface(protectionAppsForTotalSecurityInterface, installModalAppsInterface, bundle);
    }

    /**
     * Adds the interface info of the given app to the protection apps included in total security interface info or to the interface info of the other apps
     * @param protectionAppsForTotalSecurityInterface The protection apps included in total security interface info
     * @param installModalAppsInterface The interface info for all the other apps that are not protection apps included in total security
     * @param app The given app
     * @param bundle The bundle that contains the given app
     * @param devices The devices on which the given app is installed
     */
    private addProtectionAppsInfoAndOtherAppsInfoToSeparateObjects(protectionAppsForTotalSecurityInterface: InstallModalAppsInterface,
                                                                    installModalAppsInterface: InstallModalAppsInterface,
                                                                    app, bundle, devices: any[]): void {
        let destinationRefference;
        const level = app?.app_params?.level ?? NO_LEVEL;
        const finalKey = this.concatAppIdAndLevel(app.app_id, level);
        if (this.appsConfigService.isInAllProtectionApp(app)) {
            destinationRefference = protectionAppsForTotalSecurityInterface;
        } else {
            if (bundle.interface.expired) {
                return;
            }
            destinationRefference = installModalAppsInterface;
        }

        if (!destinationRefference[finalKey]) {
            destinationRefference[finalKey] = {
                occupiedSlots: 0,
                occupiedSlotsRelated: 0,
                totalSlots: 0,
                devices: [],
                app_id: app.app_id
            };
        }

        destinationRefference[finalKey].totalSlots += (app.slots.max === -1 ? this.subscriptionsValuesService.slotsNo.limit : app.slots.max);
        destinationRefference[finalKey].occupiedSlots += devices.length;
        destinationRefference[finalKey].devices = destinationRefference[finalKey].devices.concat(devices);
        destinationRefference[finalKey].occupiedSlotsRelated += this.countOccupiedSlotsOfRelatedAccounts(app.app_id, bundle);
    }

    /**
     * Adds the interface info of the protection apps included in total security to the interface info of the other apps.
     * If the interface info of the protection apps included in total security is complete, adds another total security app to the interface,
     * otherwise every protection app is added separetely to the interface
     * @param protectionAppsForTotalSecurityInterface The protection apps included in total security interface info
     * @param installModalAppsInterface The interface info for all the other apps that are not protection apps included in total security
     * @param bundle The bundle that contains the given app
     */
    private addProtectionAppsInfoToInstallModalInterface(protectionAppsForTotalSecurityInterface: InstallModalAppsInterface,
                                                        installModalAppsInterface: InstallModalAppsInterface,
                                                        bundle): void {
        if (bundle.interface.expired) {
            return;
        }

        if (Object.keys(protectionAppsForTotalSecurityInterface).length === this.appsConfigService.getNumberOfProtectionApps()) {
            let usedDevices = 0;
            let totalSlots = 0;
            const uniqueTotalSlots: Set<number> = new Set();
            for (const finalKey in protectionAppsForTotalSecurityInterface) {
                usedDevices += protectionAppsForTotalSecurityInterface[finalKey].occupiedSlots;
                uniqueTotalSlots.add(protectionAppsForTotalSecurityInterface[finalKey].totalSlots);
                // I also add winserver app tot the install modal interface even though the total security app includes it
                // because I need to be able to install it separately from subscriptions page
                if (protectionAppsForTotalSecurityInterface[finalKey].app_id === this.valuesService.appWS) {
                    if (!installModalAppsInterface[finalKey]) {
                        installModalAppsInterface[finalKey] = {
                            occupiedSlots: 0,
                            totalSlots: 0
                        };
                    }
                    installModalAppsInterface[finalKey].occupiedSlots += protectionAppsForTotalSecurityInterface[finalKey].occupiedSlots;
                    installModalAppsInterface[finalKey].totalSlots += protectionAppsForTotalSecurityInterface[finalKey].totalSlots;
                }
            }

            for (const slots of uniqueTotalSlots) {
                totalSlots += slots;
            }

            const allPlatformAndLevel = this.concatAppIdAndLevel(ALL_PLATFORMS, NO_LEVEL);
            if (!installModalAppsInterface[allPlatformAndLevel]) {
                installModalAppsInterface[allPlatformAndLevel] = {
                    occupiedSlots: 0,
                    totalSlots: 0
                };
            }
            installModalAppsInterface[allPlatformAndLevel].occupiedSlots += usedDevices;
            installModalAppsInterface[allPlatformAndLevel].totalSlots += totalSlots;
        } else {
            for (const finalKey in protectionAppsForTotalSecurityInterface) {
                if (!installModalAppsInterface[finalKey]) {
                    installModalAppsInterface[finalKey] = {
                        occupiedSlots: 0,
                        totalSlots: 0
                    };
                }
                installModalAppsInterface[finalKey].occupiedSlots += protectionAppsForTotalSecurityInterface[finalKey].occupiedSlots;
                installModalAppsInterface[finalKey].totalSlots += protectionAppsForTotalSecurityInterface[finalKey].totalSlots;
            }
        }
    }

    /**
     * Adds the interface info of the protection apps included in total security, by bundle,
     * only if the interface info of the protection apps is complete (all security apps are present).
     * Adds another total security app to the interface to hold the combined information
     * @private
     * @memberof ISubscriptionsService
     * @param {InstallModalAppsInterface} protectionApps The protection apps included in total security interface info
     * @param {BundleModel} bundle The bundle that contains the given apps
     */
    private addProtectionAppsInfoToBundleInterface(protectionAppsForTotalSecurityInterface: InstallModalAppsInterface, bundle: BundleModel): void {
        if (Object.keys(protectionAppsForTotalSecurityInterface).length >= this.appsConfigService.getNumberOfProtectionApps()) {
            let occupiedSlots = 0;
            let occupiedSlotsRelated = 0;
            let devices = [];
            let totalSlots = 0;
            const uniqueTotalSlots: Set<number> = new Set();

            for (const appKey in protectionAppsForTotalSecurityInterface) {
                if (!this.appsConfigService.isInAllProtectionAppNoWinserverStringVersion(protectionAppsForTotalSecurityInterface[appKey].app_id)) {
                    continue;
                }
                occupiedSlots += protectionAppsForTotalSecurityInterface[appKey].occupiedSlots;
                occupiedSlotsRelated += protectionAppsForTotalSecurityInterface[appKey].occupiedSlotsRelated;
                devices.push(...protectionAppsForTotalSecurityInterface[appKey].devices);
                uniqueTotalSlots.add(protectionAppsForTotalSecurityInterface[appKey].totalSlots);
            }

            for (const slots of uniqueTotalSlots) {
                totalSlots += slots;
            }

            const allPlatformAndLevelNoWinserver = this.concatAppIdAndLevel(ALL_PLATFORMS_NO_WINSERVER, NO_LEVEL);
            if (!bundle.interface.protectionApps) {
                bundle.interface.protectionApps = {...protectionAppsForTotalSecurityInterface};
                bundle.interface.protectionApps[allPlatformAndLevelNoWinserver] = {
                    occupiedSlots,
                    occupiedSlotsRelated,
                    totalSlots,
                    devices
                };
            }
        }
    }

    /**
     * Concats the app id and the level of the app
     * @param appId The app id
     * @param level The level of the app
     * @returns {string} The app id and the level of the app concatenated
     */
    public concatAppIdAndLevel(appId: string, level: string): string {
        return appId.concat(level);
    }

    /**
     * Get status of user having installable apps
     * @public
     * @memberof ISubscriptionsService
     * @returns {boolean} If there are installable apps
     */
    public getHasInstallableApps(): boolean {
        return !!Object.keys(this.installModalAppsInterface).length;
    }

    /**
     * Get install interface info for a given app
     * @param appAndLevel The app id and level concatenated
     * @returns {InstallModalAppInterface} The install interface info for the given app
     */
    public getInstallableAppInfo(appAndLevel: string): InstallModalAppInterface {
        return this.installModalAppsInterface?.[appAndLevel] ?? null;
    }

    /**
     * Get the install interface total slots a given app can occupy
     * @param appAndLevel The app id and level concatenated
     * @returns {number} The install interface total slots a given app can occupy
     */
    public getInstallableAppTotalSlots(appAndLevel: string): number {
        return this.installModalAppsInterface?.[appAndLevel]?.totalSlots ?? 0;
    }

    /**
     * Get the install interface total slots a given app already occupies
     * @param appAndLevel The app id and level concatenated
     * @returns {number} The install interface total slots a given app already occupies
     */
    public getInstallableAppOccupiedSlots(appAndLevel: string): number {
        return this.installModalAppsInterface?.[appAndLevel]?.occupiedSlots ?? 0;
    }

    /**
     * Create shared invites interface for given bundle if it is eligible to share the subscription
     *
     * @private
     * @memberof ISubscriptionsService
     * @param {Object} bundle
     * @returns {Object}
     */
    private createInvitesInterface(bundle: BundleModel): SharedSubscriptionInvitesInfo {
        const isEligibleForSharingSub = this.subscriptionsService.isEligibleForSharingSubscription(bundle);
        const isPayer = this.subscriptionsService.accountOwnerIsPayerForBundle(bundle);
        const hasGroupManagement = this.subscriptionsService.isSharedSubscriptionWithGroupManagement(bundle.bundle_id);
        let maxShares = this.subscriptionsService.getBundleMaxShares(bundle);
        const invitesInfo: SharedSubscriptionInvitesInfo = {
            manageInvites: [],
            availableInvites: 0
        };

        if (!isEligibleForSharingSub || !(isPayer || this.subscriptionsService.meetsVSBConditionForInviteFlow(bundle))) {
            return invitesInfo;
        }

        if (!isPayer && this.subscriptionsService.meetsVSBConditionForInviteFlow(bundle)) {
            maxShares--;
        }

        // First of all, push all active invites
        if (hasGroupManagement) {
            invitesInfo.manageInvites = this.computeInvitesForGroupSubscriptions(bundle);
        } else {
            invitesInfo.manageInvites = this.computeInvitesForSharedSubscriptions(bundle);
        }
        // Thirdly, the number of remaining invites is calculated
        invitesInfo.availableInvites = maxShares - invitesInfo.manageInvites.length;

        return invitesInfo;
    }

    /**
     * Create shared invites interface for shared subscriptions
     * @param {BundleModel} bundle The bundle object
     * @returns {Array<SharedSubscriptionInvite>} The shared invites interface for given bundle
     */
    private computeInvitesForSharedSubscriptions(bundle: BundleModel): Array<SharedSubscriptionInvite> {
        const gainers = bundle?.processed?.sharedInfo?.gainers ?? [];
        const pendingInvites = this.invitesService.getInvitesByServiceId(bundle.service_id);
        const isFamilyPlan = this.subscriptionsService.isSharedSubscriptionFamilyPlanBundle(bundle.bundle_id);
        const planType = isFamilyPlan ? GroupTypes.FAMILY : GroupTypes.STANDALONE;
        const invites: Array<SharedSubscriptionInvite> = [];
        // First of all, push all active invites
        for (const gainer of gainers) {
            const activeInvite: SharedSubscriptionInvite = {
                email: gainer.email,
                sanitizedEmail: this.usefulService.sanitizeValues(gainer.email),
                role: gainer?.role ?? defaultRoleByGroup[planType],
                status: SubscriptionInviteStatus.ACTIVE,
                inviteLink: gainer.invite_link,
                inviteId: gainer.invite_id,
                shareId: gainer.shareId
            };
            invites.push(activeInvite);
        }
        // Secondly, push pending invites
        for (const invite of pendingInvites) {
            const pendingInvite: SharedSubscriptionInvite = {
                email: invite.invited_email,
                sanitizedEmail: this.usefulService.sanitizeValues(invite.invited_email),
                role: invite?.age_group ?? defaultRoleByGroup[planType],
                status: SubscriptionInviteStatus.INVITED,
                inviteLink: invite.invite_link,
                inviteId: invite.invite_id
            };
            invites.push(pendingInvite);
        }
        return invites;
    }

    /**
     * Create shared invites interface for griup subscriptions
     * @param {BundleModel} bundle The bundle object
     * @returns {Array<SharedSubscriptionInvite>} The shared invites interface for given bundle
     */
    private computeInvitesForGroupSubscriptions(bundle: BundleModel): Array<SharedSubscriptionInvite> {
        const invites: Array<SharedSubscriptionInvite> = [];
        const members = this.groupManagementService.getGroupMembers();

        for (const member of members) {
            const isPayer = bundle?.processed?.sharedInfo?.payer?.email === member.email;
            if (member.email === this.profilesService.getOwnerEmail() || isPayer) {
                continue;
            }
            const invite: SharedSubscriptionInvite = {
                member_id: member.member_id,
                email: member.email,
                sanitizedEmail: this.usefulService.sanitizeValues(member.email),
                name: member.name,
                role: member.role,
                member_label: this.groupManagementService.getGroupRoleLabel(member.role, member?.member_label),
                status: member.invite_accepted ? SubscriptionInviteStatus.ACTIVE : SubscriptionInviteStatus.INVITED
            };

            if (this.subscriptionsService.isBusinessOwner(invite)) {
                invite.isBusinessOwner = true;
            }

            invites.push(invite);
        }

        return invites;
    }

    /**
     * Function that computes the usage and expiry for bundle and bundle apps for general display
     * (in /subscription/service) and device allocation (in device allocation modal for bundle and app)
     * We need to gather all logic inside one function in order to better control how we show/hide usage and expiry across subscription module
     * @param bundle Bundle object
     */
    private computeExpiryAndUsageInterface(bundle) {
        let expiryAndUsageInterface = {
            devicesUsage: '',
            expiryInfo: '',
            step2Usage: '',
            step2WillHave: '',
            serviceWillEnd: '',
            step1Header: '',
            step2Remove: ''
        };

        expiryAndUsageInterface = this.computeGeneralExpiryAndUsageInterface(bundle, expiryAndUsageInterface);
        expiryAndUsageInterface = this.computeDeviceAllocationExpiryAndUsageInterface(bundle, expiryAndUsageInterface);
        bundle.interface.expiryAndUsage = expiryAndUsageInterface;
        return expiryAndUsageInterface;
    }

    private computeGeneralExpiryAndUsageInterface(bundle, expiryAndUsageInterface) {
        const strings                   = this.subscriptionsValuesService.strings;
        const slotsAllocationCases      = this.subscriptionsHelperService.computeSlotsAllocationCases(bundle);
        const devicesAllocationCases    = this.subscriptionsHelperService.computeDevicesAllocationCases(bundle);
        const isDpy                     = this.subscriptionsService.isDpy(bundle);
        const isIdTP                    = this.subscriptionsService.isIdTheftProtection(bundle);
        const isPM                      = this.subscriptionsService.isPasswordManager(bundle);
        const isSI                      = this.subscriptionsService.isSecureIdentity(bundle);
        const isActive                  = bundle.status === this.subscriptionsValuesService.status.active;
        const isBox                     = this.subscriptionsService.isBox(bundle);
        const isEligibleForSharingSub   = this.subscriptionsService.isEligibleForSharingSubscription(bundle);
        const isGainer                  = this.subscriptionsService.accountOwnerIsGainerForBundle(bundle);

        // usage and expiry in subscriptions-service component
        if (isActive) {
            expiryAndUsageInterface.devicesUsage = this.subscriptionsValuesService.generalUsageCases?.[slotsAllocationCases]?.[devicesAllocationCases]
                                                    ?? this.subscriptionsValuesService.generalUsageCases?.[slotsAllocationCases];

            if (bundle.type !== this.subscriptionsValuesService.type.free && !bundle.interface.hideEndDate) {
                if (bundle?.processed?.arrON) {
                    expiryAndUsageInterface.expiryInfo = 'subscriptions.services.licenserenew.new';
                    // pt licentele de avangate ce au ar pe on si au end date, afisam end date in loc de billing date
                    if ((this.subscriptionsService.isAvangate(bundle) || this.subscriptionsService.isDigitalRiver(bundle))
                        && bundle?.interface?.endDateLocale) {
                        expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
                    } else {
                        expiryAndUsageInterface.expiryDate = bundle.interface.renewalDateLocale;
                    }
                } else if (bundle?.processed?.arrOFF || bundle?.end_date) {
                    expiryAndUsageInterface.expiryInfo = 'subscriptions.services.licenseend.new';
                    expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
                }
            }

        } else {
            expiryAndUsageInterface.devicesUsage = bundle.interface.devices.length === 1 ? strings.wasActiveOnDevice : strings.wasActiveOnDevices;
            expiryAndUsageInterface.expiryInfo = 'subscriptions.services.licenseexpired.new';
            expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
        }

        // hide usage in subscriptions-service
        if ((isBox && bundle.interface.devices.length >= this.subscriptionsValuesService.slotsNo.limit)
            || bundle.slots <= 0
            || isEligibleForSharingSub
            || isDpy
            || isIdTP
            || isPM
            || isSI) {
            expiryAndUsageInterface.devicesUsage = '';
        }

        // hide expiry in subscriptions-service
        if (bundle?.interface?.owner || (isGainer && !isEligibleForSharingSub)) {
            expiryAndUsageInterface.expiryInfo = '';
        }

        return expiryAndUsageInterface;
    }

    private computeDeviceAllocationExpiryAndUsageInterface(bundle, expiryAndUsageInterface) {
        const slotsAllocationCases      = this.subscriptionsHelperService.computeSlotsAllocationCases(bundle);
        const devicesAllocationCases    = this.subscriptionsHelperService.computeDevicesAllocationCases(bundle);
        const showUsedBy                = !this.valuesService.boxBundles.has(bundle.bundle_id)
                                            || (this.valuesService.boxBundles.has(bundle.bundle_id) && bundle.interface.devices.length === 0);
        const usageCases = this.subscriptionsValuesService.serviceSlotsAllocationCases?.[slotsAllocationCases]?.[devicesAllocationCases]
                            ?? this.subscriptionsValuesService.serviceSlotsAllocationCases?.[slotsAllocationCases];

        // usage and expiry in device allocation modal
        if (!showUsedBy) {
            expiryAndUsageInterface.step1Header = usageCases?.[DeviceAllocationDisplayComponents.STEP1_HEADER]?.[bundle.service_type];
            expiryAndUsageInterface.step2WillHave = usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]?.[bundle.service_type]
                                                    ?? usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_SINGLE]?.[bundle.service_type];
        } else {
            expiryAndUsageInterface.step1Header = usageCases?.[DeviceAllocationDisplayComponents.USED_BY_BOX]?.[bundle.service_type];
            expiryAndUsageInterface.step2WillHave = usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]?.[bundle.service_type]
                                                    ?? usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_MULTI]?.[bundle.service_type];
        }

        if (!bundle.interface.hideEndDate) {
            expiryAndUsageInterface.serviceWillEnd = usageCases?.[DeviceAllocationDisplayComponents.SERVICE_WILL_END]?.[bundle.service_type];
        }
        expiryAndUsageInterface.step2Usage = usageCases?.[DeviceAllocationDisplayComponents.STEP2_USAGE]?.[bundle.service_type];
        expiryAndUsageInterface.step2Remove = usageCases?.[DeviceAllocationDisplayComponents.STEP2_REMOVE]?.[bundle.service_type];

        return expiryAndUsageInterface;
    }

    public computeAppsDeviceAllocationUsageInterface(devicesLength) {
        const allocationCase = this.subscriptionsHelperService.computeAllocationCaseForApplication(devicesLength);
        return this.subscriptionsValuesService.serviceApplicationsDevicesAllocationCases[allocationCase] ?? null;
    }

    public computeConvertToSubscriptionInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        let features = [];
        const protectionFeature1 = bundle.slots === 1
                                ? 'subscriptions.convertservice.included.protection.device.included'
                                : 'subscriptions.convertservice.included.protection.devices.included';
        const protectionFeature2 = bundle.slots === 1
                                ? 'subscriptions.convertservice.included.protection.single'
                                : 'subscriptions.convertservice.included.protection.multiple';
        if (bundle.bundle_id === this.valuesService.bundleIS) {
            features = [
                'subscriptions.convertservice.included.description.all.1',
                'subscriptions.convertservice.included.description.all.2',
                'subscriptions.convertservice.included.description.all.parental',
                'subscriptions.convertservice.included.description.all.extensive',
                protectionFeature1,
                'subscriptions.convertservice.included.price.year'
            ];
        } else if (bundle.bundle_id === this.valuesService.bundleCLTSMD || bundle.bundle_id === this.valuesService.bundleTS) {
            features = [
                'subscriptions.convertservice.included.description.ts.complete',
                'subscriptions.convertservice.included.description.ts.protection',
                'subscriptions.convertservice.included.description.all.1',
                'subscriptions.convertservice.included.description.all.2',
                'subscriptions.convertservice.included.description.all.parental',
                'subscriptions.convertservice.included.description.all.extensive',
                protectionFeature1,
                'subscriptions.convertservice.included.price.year'
            ];
        } else if (bundle.bundle_id === this.valuesService.bundleAV) {
            features = [
                'subscriptions.convertservice.included.description.all.1',
                'subscriptions.convertservice.included.description.all.2',
                protectionFeature1,
                'subscriptions.convertservice.included.price.year'
            ];
        } else {
            features = [
                protectionFeature2,
                'subscriptions.convertservice.included.yearly.renewal',
                'subscriptions.convertservice.included.price.year'
            ];
        }

        if (!bundle.interface?.convert) {
            bundle.interface.convert = {};
        }

        const newBillingDate = this.computeOverOneYearBillingDate(serviceId);
        if (features.length >= 3 && features[2] === 'subscriptions.convertservice.included.yearly.renewal' && !newBillingDate) {
            features.splice(2, 1);
        }
        bundle.interface.convert.features = features;
        bundle.interface.convert.newBillingDate = newBillingDate;
    }

    private computeOverOneYearBillingDate(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        if (bundle.status === this.subscriptionsValuesService.status.expired) {
            return moment().add(1, 'year').format('MM/DD/YYYY');
        } else if (bundle?.end_date) {
            return moment.unix(bundle.end_date).add(1, 'year').format('MM/DD/YYYY');
        }
        return '';
    }

    /**
     * This functions computes the flags needed to show/hide install text for a bundle across subscriptions module
     * For password manager is a special case, where install text includes product name
     * @param bundle Bundle object
     * @returns
     */
    private computeInstallInterface(bundle) {
        const cantHaveInstallProtection = this.valuesService.bundlesWithoutDeployFlow.has(bundle.bundle_id);
        const bundleHasFreeSlots  = bundle.interface.devices.length < bundle.slots || bundle.bundle_id === this.valuesService.bundlePM;
        const isActive = bundle.status === this.subscriptionsValuesService.status.active;
        const lessThanAWeekValability = bundle.interface.daysRemaining <= this.subscriptionsValuesService.weekDays;

        // aceste flaguri fac sa apara flowul de deploy pentru un serviciu
        // daca install protection e true, apare textul "Install protection on a device" pentru un bundle care deschide modala de install
        // daca installProduct e true, apare textul "Install <productName> on a device" care deschide modala de install
        // daca installProduct e pus pe true, trebuie dat si o valoare lui productName
        // daca showBuyOrRenew e true, nu avem flow de deploy in modala de install pt unul din app-urile idn bundle,
        // dar afisam linkuri de buy/renew/ etc pentru acel bundle
        const installInterface = {
            installProtection: false,
            installProduct: false,
            showBuyOrRenew: false,
            productName: ''
        };

        // conditii pentru care nu avem flow de deploy pentru bundle
        if (cantHaveInstallProtection
            || !this.appsConfigService.showDployFlowForBundle(bundle)) {
            return installInterface;
        }

        // daca a expirat, poate avea link de buy/renew
        if (!isActive) {
            installInterface.showBuyOrRenew = true;
            return installInterface;
        }

        if (this.subscriptionsService.isPasswordManager(bundle) || this.subscriptionsService.isSecureIdentity(bundle)) {
            installInterface.installProduct = true;
            installInterface.productName = this.productsConfigService.getProductName(this.valuesService.productNamePasswordManager);
        } else if (this.subscriptionsService.isIdTheftProtection(bundle)) {
            if (lessThanAWeekValability) {
                installInterface.showBuyOrRenew = true;
            }
        } else if (this.subscriptionsService.isUltimateSecurityProtection(bundle)) {
            if (bundleHasFreeSlots) {
                installInterface.installProtection = true;
            }

            if (lessThanAWeekValability) {
                installInterface.showBuyOrRenew = true;
            }
        } else if (bundleHasFreeSlots) {
            installInterface.installProtection = true;
        }
        return installInterface;
    }

    private bundleHasInstallButton(bundle) {
        return bundle?.interface?.install?.installProtection || bundle?.interface?.install?.installProduct;
    }

    /**
     * Computes label for oses of a given app - eg: 'Mac only', 'Windows, Android, iOS', etc
     * @public
     * @memberof ISubscriptionsService
     * @param {string} appId The app id
     * @return {*} An object with a label and placeholder replacement if it's only one OS, a coma separated string of oses otherwise
     */
    public computeLabelForAppOses(appId: string): any {
        const appOses = this.productsConfigService.computeDisplayedOses(appId);

        if (appId === this.valuesService.appPassManager) {
            return {
                label: 'subscriptions.app.devices.all'
            }
        }

        if (appOses.length === 1) {
            return {
                label: 'subscriptions.app.os.onlyOne',
                placeholders: {
                    operatingSystem: appOses[0]
                }
            };
        }

        if (appOses.length > 1) {
            return {
                list: appOses.join(', ')
            };
        }
    }

    /**
     *
     * Counts the extra occupied slots by related accounts, for a given app
     * * for a payer account owner gainers can occupy extra slots
     * @private
     * @memberof ISubscriptionsService
     * @param {string} appId The app id
     * @param {BundleModel} appId The app id
     * @return {number} number of used slots
     */
    private countOccupiedSlotsOfRelatedAccounts(appId: string, bundle: BundleModel): number {
        let occupiedSlots = 0;
        const accountOwnerIsPayer = this.subscriptionsService.accountOwnerIsPayerForBundle(bundle);

        if (!accountOwnerIsPayer || this.profilesService.isOwnerVSBAdmin()) {
            return occupiedSlots;
        }

        const gainers = bundle?.processed?.sharedInfo?.gainers ?? [];
        for (const gainer of gainers) {
            const usages = gainer?.usage ?? [];
            for (const usage of usages) {
                if (usage.app_id === appId) {
                    occupiedSlots++;
                }
            }
        }
        return occupiedSlots;
    }

    /**
     * Computes label for slots text of a given app - eg: '5 devices', '4 users', etc
     * @param {string} appId The app id
     * @param {number} totalSlots The bundle total slots number
     * @return {SlotsTextModel} An object with a label and placeholder replacement for optional present number
     */
    public computeSlotsText(appId: string, totalSlots: number): SlotsTextModel {
        const slotsText: SlotsTextModel = {
            label: '',
            placeholders: {
                number: totalSlots
            }
        };

        if (appId === this.valuesService.appPA || appId === this.valuesService.appPANCC) {
            slotsText.label = 'subscriptions.app.devices.all.child';
            return slotsText;
        }

        if (appId === this.valuesService.appBA) {
            slotsText.label = 'subscriptions.app.admin.users';
            return slotsText;
        }

        if (appId === this.valuesService.appATO) {
            return {
                label: 'subscriptions.app.devices.monitoring.all',
                placeholders: {
                    number: !this.atoService.atoIdentityOnboardingInProgress() ? 1 : 0,
                    total: totalSlots
                }
            } as SlotsTextModel;
        }

        if (totalSlots >= this.subscriptionsValuesService.slotsNo.limit) {
            slotsText.label = 'subscriptions.app.devices.all';
        } else if (totalSlots === this.subscriptionsValuesService.slotsNo.single) {
            slotsText.label = this.valuesService.appsWithoutActiveDevices.has(appId) ? 'subscriptions.app.user.one' : 'subscriptions.app.devices.one';
        } else {
            slotsText.label = this.valuesService.appsWithoutActiveDevices.has(appId) ? 'subscriptions.app.user.multiple' : 'subscriptions.app.devices.multiple';
        }

        return slotsText;
    }

    /**
     *
     * Computes custom extra details for a given app
     * * by design, for now, only VPN has traffic info extra details (unlimited vs 200 MB / day)
     * @private
     * @memberof ISubscriptionsService
     * @param {string} appId The app id
     * @return {string} a text key
     */
    private computeExtraText(app: AppModel): string {
        return this.subscriptionsValuesService.appExtraDetails[app.app_id]?.[app.app_params?.level];
    }

    /**
     * Computes app's configuration status of a given app
     * * appPA is considered set if child profiles were created
     * * appDIP is considered set if onboarding is done
     * * appPassManager's is considered set if master password is set (user is registered)
     * * appIdTheft is considered set if user is enrolled
     * * other apps are set (installed) if they have at least 1 occupied slot
     * @public
     * @memberof ISubscriptionsService
     * @param {string} appId The app id
     * @param {number} occupiedSlots The number of occupied slots
     * @return {AppConfigurationStatus} The app status: configured, unconfigured, installed, not installed
     */
    public computeAppConfigurationStatus(appId: string, occupiedSlots?: number): AppConfigurationStatus {

        if (appId === this.valuesService.appPA || appId === this.valuesService.appPANCC) {
            const hasChildProfiles = Object.keys(this.profilesService.getParentalProfiles()).length;
            return hasChildProfiles ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
        }

        if (appId === this.valuesService.appDIP) {
            const onboardingDone = this.privacyService.onboardingIsDone();
            return onboardingDone ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
        }

        if (appId === this.valuesService.appBA) {
            const onboardingDone = this.businessService.isBusinessAssetsExposureSetUp();
            return onboardingDone ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
        }

        if (appId === this.valuesService.appPassManager) {
            return this.passwordManagerService.getConfigurationStatus();
        }

        if (appId === this.valuesService.appIdTheft) {
            return this.idTheftProtectionService.getConfigurationStatus();
        }

        if (appId === this.valuesService.appATO) {
            const identity = this.atoService.getCreatedIdentityStatus();
            return identity?.created ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
        }

        return occupiedSlots > 0 ? AppConfigurationStatus.INSTALLED : AppConfigurationStatus.NOT_INSTALLED;
    }

    /**
     * Computes if app should have danger status deppending on app's configurationStatus and free slots
     * @public
     * @memberof ISubscriptionsService
     * @param {AppModel} app The app object
     * @return {boolean}
    */
    public appHasDangerStatusInInstallModal(app: AppModel): boolean {
        return app.interface?.configurationStatus === AppConfigurationStatus.NOT_INSTALLED
        || app.interface?.configurationStatus === AppConfigurationStatus.NOT_CONFIGURED
        || (app.interface?.configurationStatus === AppConfigurationStatus.INSTALLED && app.interface?.occupiedSlots === app.slots.max);
    }

    private getAppInstallIncentive(app) {
        let installIncentive;
        if (this.subscriptionsValuesService.installIncentives[app.app_id]) {
            if (app.app_params && app.app_params.level && this.subscriptionsValuesService.installIncentives[app.app_id][app.app_params.level]) {
                installIncentive = this.subscriptionsValuesService.installIncentives[app.app_id][app.app_params.level];
            } else {
                installIncentive = this.subscriptionsValuesService.installIncentives[app.app_id];
            }
        }
        return installIncentive;
    }

    // returns app_id.level (ex: com.bitdefender.vpn.premium) or just app_id
    private getAppIdLevel(app) {
        let appIdLevel;
        if (this.subscriptionsValuesService.installIncentives[app.app_id]) {
            if (app.app_params && app.app_params.level && this.subscriptionsValuesService.installIncentives[app.app_id][app.app_params.level]) {
                appIdLevel = `${app.app_id}.${app.app_params.level}`;
            } else {
                appIdLevel = app.app_id;
            }
        }
        return appIdLevel;
    }

    private getAppDevices(app, bundle) {
        const knownDevices = [];
        const unknownDevices = [];
        const accounts = bundle?.accounts ?? [];

        if (!accounts) {
            return [];
        }
        for (const account of accounts) {
            if (this.excludeDeviceFromBundleDeviceInterface(account)) {
                continue;
            }
            const usages = account?.usage_summary ?? [];
            for (const usage of usages) {
                if (!this.valuesService.appsWithoutActiveDevices.has(app.app_id) && app.app_id === usage.app_id) {
                    const device = this.devicesService.retrieveDeviceById(usage.slot_id);
                    let deviceWithApp = {};
                    let options = {};
                    if (device) {
                        deviceWithApp = device;
                        options = {
                            change: this.subscriptionsValuesService.deviceAllocationOptions.change,
                            remove: this.subscriptionsValuesService.deviceAllocationOptions.remove
                        };
                        this.devicesService.processingMethods.addSubscriptionInfo(deviceWithApp, bundle, options);
                        knownDevices.push(device);
                    } else {
                        deviceWithApp = {
                            device_os: 'unknown',
                            device_icon: 'other',
                            display_name: this.translateService.instant('subscriptions.deviceallocation.removeddevice'),
                            removed: true,
                            device_id: usage.slot_id
                        };
                        options = {
                            remove: this.subscriptionsValuesService.deviceAllocationOptions.remove
                        };
                        this.devicesService.processingMethods.addSubscriptionInfo(deviceWithApp, bundle, options);
                        unknownDevices.push(deviceWithApp);
                    }
                }
            }
        }
        return knownDevices.concat(unknownDevices);
    }

    private getDeviceNameFromService(app, bundle): Promise<any> {
        return new Promise((resolve, reject) => {
            bundle.accounts.forEach(account => {
                account.usage_summary.forEach(usage => {
                    if (usage.app_id === app.app_id && usage.slot_id) {
                        this.connectMgmtService.getDeviceInfo(usage.slot_id, 1).subscribe(resp => {
                            resolve(resp['display_name']);
                        });
                    }
                });
            });
        });
    }

    /**
     *
     * Get device name for slot_id from devices
     * @private
     * @param {*} application
     * @param {*} bundle
     * @param {*} devices
     * @return {*}  {string}
     * @memberof ISubscriptionsService
     */
    private getDeviceNameFromDevices(application, bundle, devices): string {
        for (const account of bundle.accounts) {
            for (const usage of account.usage_summary) {
                if (usage.app_id === application.app_id) {
                    for (const device of devices) {
                        if (device.device_id === usage.slot_id) {
                            return device.display_name;
                        }
                    }
                }
            }
        }
        return '';
    }

}
