// External
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import { ConfigService, LogoutType } from '../../../config/config.service';
import { UsefulService } from '../useful/useful.service';
import { AdobeDataLayerService } from '../../core/adobe.datalayer.service';
import { ValuesService } from '../../../../common/values/values.service';

interface ExtractedQueryParamsFromRedirectUrl {
    redirectUrl: string;
    extractedQueryParams: Object;
}

@Injectable({
    providedIn: 'root'
})

/**
 * This is a service that processes the login/logout/create accoutn links, generally called "login links"
 * It ensures that the proper parameters are put inside those links and ensures that navigation is done
 * taking into consideration the parameters inside those links
 */

export class LoginLinksService {
    constructor(
        private readonly usefulService: UsefulService,
        private readonly router: Router,
        private readonly configService: ConfigService,
        private readonly location: Location,
        private readonly translate: TranslateService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly cookiesService: CookieService,
        private readonly valuesService: ValuesService
    ) { }

    /**
     * This returns account language, is duplicated code needed to avoid circular dependencies, should only be used inside this file
     * @returns Account language
     */
     private getLanguage() {
        const lang = this.translate.currentLang;
        const firstPart = lang.split('_')[0];
        const secondPart = lang.split('_')[1].toUpperCase();
        return `${firstPart}_${secondPart}`;
    }

    /**
     * Computes the final url the user needs to land on
     * Takes the value of 'redirect_url' as parameter, checks if it has 'final_url' as parameter
     * If it does, takes the rest of the query params, except 'final_url', and all the query params from 'final_url' and unites them
     *
     * If user_token param exists, it is removed from redirect url due to the fact that user_token should be available for direct login.
     * If user_token is provided, token generation is executed in auth.guard.ts and user_token is cleaned up.
     *
     * Parameters from 'redirect_url' have a higher priority (appera to be written by connect and they overwrite any pre-existent parameter with the same key)
     * @param url An url like this: /dashboard?param1=<code>
     * @returns True if path does not need change (redirect) or an url tree with the new url if it does need redirect
     */
    public fromRedirectUrlToUrl(url) {
        const loginUserToken = this.configService.config.loginUserToken || 'user_token';
        const finalUrlKey = 'final_url';
        const objectParamsRedirectUrl = this.usefulService.computeQueryParams(url);
        let finalStringParams = "";

        // daca nu avem finalUrl in link, nu avem de extras niciun url, evental stergem userToken
        if (!objectParamsRedirectUrl[finalUrlKey]) {
            // daca avem userToken, il stergem
            if (objectParamsRedirectUrl[loginUserToken]) {
                delete objectParamsRedirectUrl[loginUserToken];
                finalStringParams = this.usefulService.jsonToQueryString(objectParamsRedirectUrl);
                const finalPath = url.split("?")[0].concat("?", finalStringParams);
                return this.router.parseUrl(finalPath);
            }
            // altfel, nu facem nimic pt ca nu avem nimic de extras din url
            // pt ca finalUrl nu exista
            return true;
        }

        // daca avem finalUrl in link
        // stergem finalUrl si stergem si userToken
        let finalUrl = decodeURIComponent(objectParamsRedirectUrl[finalUrlKey]);
        delete objectParamsRedirectUrl[finalUrlKey];
        delete objectParamsRedirectUrl[loginUserToken];
        finalStringParams = this.usefulService.jsonToQueryString(objectParamsRedirectUrl);
        finalUrl = finalStringParams ? finalUrl.concat("?", finalStringParams) : finalUrl;

        return this.router.parseUrl(finalUrl);
    }

    /**
     * Function that deletes 'final_url" search parameter if it already exists and puts a new one
     * If the parameter does not exist, it computes it
     * If 'final_url' has query parameters, then it appends them to the redirect_url, so after login those parameters are appended to the first login path
     * This path will be later changed maybe, if 'final_url' exists but the query parameters will stick (another function will carry them on)
     * @param redirectUrl The redirect url
     * @returns The redirect url with a new 'final_url' param value
     */
    private computeRedirectUrl(redirectUrl) {
        let format = this.configService.config.loginRedirectUrlType.split(":");

        if (!redirectUrl || redirectUrl === this.configService.config.mainPage) { 
            if (format.length === 1 || format.indexOf('path') === -1) {
                return "";
            } else {
                return this.configService.config.mainPage;
            }
        }

        const queryParamsRedirectUrl = this.usefulService.computeQueryParams(redirectUrl);
        // sterg 'final_url' daca e deja ca parametru
        if (queryParamsRedirectUrl["final_url"]) {
            delete queryParamsRedirectUrl["final_url"];
        }

        //recompun calea
        const redirectPath = this.usefulService.computePath(redirectUrl);
        const redirectParams = this.usefulService.jsonToQueryString(queryParamsRedirectUrl);

        // dau calea rezultata ca valoare pt 'final_url'
        // redirectUrl = redirectParams ? redirectPath.concat("?", redirectParams) : redirectPath;
        redirectUrl = redirectParams
                    ? this.configService.config.mainPage.concat("?", redirectParams, "&final_url=".concat(redirectPath)) 
                    : this.configService.config.mainPage.concat("?final_url=".concat(redirectPath));

        if (format.length === 1) {
            return "";
        } else if (format.length === 2 && format.indexOf("path") !== -1) {
            return redirectUrl.split("?")[0];
        } else if (format.length === 2 && format.indexOf("query") !== -1) {
            return "?".concat(redirectUrl.split("?")[1]);
        } else {
            return redirectUrl;
        }
    }

    /**
     * Function that search in redirect url for some query params and if they exist, it extracts them and returns them. The will be added to the final url
     *
     * @private
     * @param {string} redirectUrl The redirect url
     * @return {Object} {redirectUrl: string, extractedQueryParams: Object} New redirect url and extracted query params
     * @memberof LoginLinksService
     */
    private extractQueryParamsFromRedirectUrl(redirectUrl): ExtractedQueryParamsFromRedirectUrl {
        const movableQueryParams = new Set([this.valuesService.queryParams.cid, this.valuesService.queryParams.icid, this.valuesService.queryParams.product]);
        let extractedQueryParams = {};
        const queryParamsRedirectUrl = this.usefulService.computeQueryParams(redirectUrl);
        for (const key in queryParamsRedirectUrl) {
            if (movableQueryParams.has(key)) {
                extractedQueryParams[key] = queryParamsRedirectUrl[key];
                delete queryParamsRedirectUrl[key];
            }
        }
        return {
            redirectUrl: redirectUrl.split('?')[0].concat('?', this.usefulService.jsonToQueryString(queryParamsRedirectUrl)),
            extractedQueryParams
        }
    }

    /**
     * Function takes a key value object @param urlParams and generates params object that is required for login redirect url.
     * There are 4 special values: $redirect_url, $lang, $ref, $client_id. If one of the value is added, the actual value is
     * computed in this function.
     *
     * If $redirect_url is specified, redirect url is computed based on location and redirect url
     * If $lang is specified, current language is added
     * If $ref is specified, previous location param is added
     * If $client_id is added, the corresponding client id from @param clientIds is added to the link
     * If none of the custom values is used, the string is simply added without additional computation
     *
     * Eg of urlParams:
     * { redirect_url: '$redirect_url', lang: '$lang', state: 'auth' }
     *
     * Method returns:
     * { redirect_url: 'https://v2.central.bitdefender.com/dashboard', lang: 'en_US', state: 'auth }
     *
     * @param urlParams object that contains login url params that needs computation
     * @param redirectUrl URL from central; the user is redirected there after login
     * @param userAccessedCentral flag used to see if user saw any central page
     * @param useClientIds flag that should be true if client id list is used/required in order to login
     * @param clientIds list of client ids
     * @returns object that contains computed login url params
     */
    private computeUrlParams(urlParams, redirectUrl, userAccessedCentral, useClientIds?, clientIds?) {
        const jsonObjectParams = {};

        for (const key in urlParams) {
            if (urlParams[key] === '$redirect_url') {
                if (redirectUrl) {
                    // if redirect url contains specific query params, we need to extract them and add them to the final redirect url
                    const response: ExtractedQueryParamsFromRedirectUrl = this.extractQueryParamsFromRedirectUrl(redirectUrl);
                    redirectUrl = response.redirectUrl;
                    for (const queryParam in response.extractedQueryParams) {
                        jsonObjectParams[queryParam] = response.extractedQueryParams[queryParam];
                    }
                }

                const computedRedirectUrl = this.computeRedirectUrl(redirectUrl);
                const encodedUrl = `${location.protocol}//${location.host}${computedRedirectUrl}`;
                jsonObjectParams[key] = encodedUrl;
                continue;
            }

            if (urlParams[key] === '$lang') {
                jsonObjectParams[key] = this.getLanguage();
                continue;
            }

            if (urlParams[key] === '$ref') {
                const previousRef = userAccessedCentral ? this.usefulService.cleanUpLocationHrefForRef() : document.referrer.split('?')[0];
                jsonObjectParams[key] = previousRef;
                continue;
            }

            if (urlParams[key] === '$client_id' && useClientIds && !!clientIds[location.host]) {
                jsonObjectParams[key] = clientIds[location.host];
                continue;
            }

            if (urlParams[key] === '$partner_id') {
                jsonObjectParams[key] = this.configService.config.partner_id;
                continue;
            }

            if (urlParams[key] === '$email') {
                jsonObjectParams[key] = encodeURIComponent(this.cookiesService.get(this.valuesService.cookieEmail));
                continue;
            }

            jsonObjectParams[key] = urlParams[key];
        }

        return jsonObjectParams;
    }

    /**
     *
     * @param logoutType the type of redirect to logout page
     * If @var logoutParams are not specified in config service, @var loginParams params are returned instead. If
     * @var loginParams are also missing, an empty object is returned by default
     * @returns auth object params or empty object if login and logout are not specified in the config service
     */
    private getConfigParams(logoutType: LogoutType) {
        const { loginParams, logoutParams, reloginParams } = this.configService.config;

        if (logoutType === LogoutType.REDIRECT_TO_LOGOUT_PAGE && logoutParams) {
            return logoutParams;
        }

        if (logoutType === LogoutType.REDIRECT_TO_RELOGIN_PAGE && reloginParams) {
            return reloginParams;
        }

        if (loginParams !== undefined) {
            return loginParams;
        }

        return {};
    }

    /**
     * Method returns auth redirect path from config file.
     * If @var logoutPath is missing, @var loginPath is returned instead. If @var loginPath is also
     * missing, an empty string is returned by default
     *
     * @param logoutType the type of redirect to logout page
     * @returns login or logout path or empty string if properties are not specified in config service
     */
    private getConfigRedirectPath(logoutType: LogoutType) {
        const { loginPath, autolookupPath, logoutPath } = this.configService.config;

        if (logoutType === LogoutType.REDIRECT_TO_LOGOUT_PAGE && logoutPath !== undefined) {
            return logoutPath;
        }
        if (logoutType === LogoutType.REDIRECT_TO_RELOGIN_PAGE && autolookupPath !== undefined) {
            return autolookupPath;
        }

        if (loginPath !== undefined) {
            return loginPath;
        }

        return '';
    }

    private getConfigRedirectServer(logoutType: LogoutType) {
        const { loginServer, logoutServer } = this.configService.config;
        if (logoutType === LogoutType.REDIRECT_TO_LOGOUT_PAGE && logoutServer !== undefined) {
            return logoutServer;
        }

        if (loginServer !== undefined) {
            return loginServer;
        }

        return '';
    }

     /**
     * Redirects to login url, taking into consideration the redirect URL from central that the user will land on
     * The url the user will land on is saved inside the 'final_url' prameter
     * 'Redirect_url' will always be '/dashboard' to avoid 'invalid redirect url' errors
     * @param resetHistory boolean if true user cannot go back from back button after redirect to login
     * @param userAccessedCentral Flag used to see if user saw any central page
     * @param redirectUrl URL from central; the user is redirected there after login
     * @param logoutType the type of redirect to logout page
     */
    public loginPage(resetHistory, userAccessedCentral, logoutType: LogoutType, redirectUrl?) {
        const { useClientIds, clientIds } = this.configService.config;

        // compute url params
        const urlParams = this.getConfigParams(logoutType);
        const computedLoginUrlParams = this.computeUrlParams(urlParams, redirectUrl, userAccessedCentral, useClientIds, clientIds);
        const searchParams = (new URLSearchParams(computedLoginUrlParams)).toString();

        const redirectPath = this.getConfigRedirectPath(logoutType);
        const redirectServer = this.getConfigRedirectServer(logoutType);

        if (resetHistory) {
            this.location.replaceState('/'); // clears browser history so they can't navigate with back button
        }
        return searchParams
                ? this.adobeDataLayerService.addOmnitureVisitor(`${redirectServer}${redirectPath}?${searchParams}`)
                : this.adobeDataLayerService.addOmnitureVisitor(`${redirectServer}${redirectPath}`);
    }

    /**
     * Redirects to create account page, taking into consideration the redirect URL from central that the user will land on
     * @param userAccessedCentral Flag used to see if user saw any central page
     * @param redirectUrl URL from central; the user is redirected there after account creation
     */
    public createAccountPage(userAccessedCentral, redirectUrl?) {
        const { registerServer, registerPath, registerParams } = this.configService.config;

        const server = registerServer || '';
        const urlParams = registerParams || {};
        const redirectPath = registerPath || '';

        const computedUrlParams = this.computeUrlParams(urlParams, redirectUrl, userAccessedCentral);
        const searchParams = (new URLSearchParams(computedUrlParams)).toString();

        return searchParams
                ? this.adobeDataLayerService.addOmnitureVisitor(`${server}${redirectPath}?${searchParams}`)
                : this.adobeDataLayerService.addOmnitureVisitor(`${server}${redirectPath}`);
    }

}
