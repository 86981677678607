import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, map, catchError, throwError } from 'rxjs';

import { ConfigService } from '../../../config/config.service';
import { ValuesService } from '../../../values/values.service';
import { RequestsService } from '../../global/request-service/requests.service';
import { AtoIdentityStatusModel } from '../../../models/ato/AtoIdentity.model';
import { AtoErrorCodes, AtoProviders, AtoServiceModel } from '../../../models/ato/AtoService.model';
import { LiveReportDataModel, SetupStep } from '../../../models/ato/AtoGeneral.model';
import { AtoEventModel, EventStatus, EventTypes } from '../../../models/ato/AtoEvents.model';

@Injectable({
    providedIn: 'root'
})
export class SmtpAtoService {
    public readonly DefaultAtoError = {
        code: AtoErrorCodes.DEFAULT,
    };

    constructor(
        private readonly configService: ConfigService,
        private readonly cookieService: CookieService,
        private readonly requestsService: RequestsService,
        private readonly valuesService: ValuesService
    ) { }

    //#region helper function

    /**
     * Function that calls the Nimbus server with the given method and respectiv payload based on given extra params
     * and the app id to overwrite the connect source app id with
     * @param {string} method The method to call the nimbus service with
     * @param {any} extraParams The extra params to add to the payload
     * @returns {Observable<any>}
     */
    make(method: string, extraParams?: any): Observable<any> {
        const _json = {
            id: 0,
            jsonrpc: this.valuesService.jsonrpc,
            method: '',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.appATO
                }
            }
        };

        _json.params.connect_source.user_token = this.cookieService.get(this.valuesService.cookieToken);
        _json.id = parseInt((Math.random() * 1000).toString(), 10);
        _json.method = method;

        if (extraParams) {
            Object.assign(_json.params, extraParams);
        }

        return this.requestsService.make(
            this.configService.config.atoServer,
            this.valuesService.atoService,
            _json,
            'POST'
        ).pipe(
            map(resp => {
                if (resp?.result) {
                    return resp.result;
                }
                throw resp?.internal_data || resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

    //#endregion

    /**
     * Method that creates a new identity
     * @returns {Observable<boolean>}
     */
    public createIdentity(): Observable<boolean> {
        return this.make('create_identity').pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that verifies if a identity is already created
     * @returns {Observable<boolean>}
     */
    public checkIdentity(): Observable<AtoIdentityStatusModel> {
        return this.make('check_identity');
    }

    /**
     * Method that initializes authorization for specified provider
     * @param {AtoProviders} provider the provider that gets authorized
     * @param {string} serviceId the id of the service to be reconnected
     * @returns {Observable<string>} the redirect url for authorization
     */
    public serviceAuthorizeInit(provider: AtoProviders, serviceId?: string): Observable<string> {
        if (!provider) {
            return throwError(() => this.DefaultAtoError);
        }

        const extraParams = {
            provider: !serviceId ? provider : undefined,
            service_id: serviceId
        };

        return this.make('service_authorize_init', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.url;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that checks if authorization was ok or not based on sid and code
     * @param {string} sid the sid from the authorization link
     * @param {string} code the code from the authorization link
     * @returns {Observable<boolean>} the authorization status
     */
    public serviceAuthorizeLink(sid: string, code: string): Observable<boolean> {
        if (!sid || !code) {
            return throwError(() => this.DefaultAtoError);
        }

        const extraParams = {
            sid,
            code
        };

        return this.make('service_authorize_link', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that gets services for the current account
     * @returns {Observable<AtoServiceModel[]>} the list of services found
     */
    public getServices(): Observable<AtoServiceModel[]> {
        return this.make('get_services').pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.services;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that saves onboarding current step
     * @returns {Observable<boolean>} true if save was ok
     */
    public saveOnboardingStep(step: SetupStep): Observable<boolean> {
        const extraParams = {
            step
        };

        return this.make('save_onboarding_step', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that revokes access given by the user to scan social media account
     * @returns {Observable<boolean>} true if access succesfully revoked
     */
    public revokeAccess(serviceId: string): Observable<boolean> {
        const extraParams = {
            service_id: serviceId
        };

        return this.make('revoke_service', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that gets important (pinned) events
     * @returns {Observable<AtoServiceModel[]>} the list of services found
     */
    public getPinnedEvents(): Observable<AtoEventModel[]> {
        return this.make('get_pinned_events').pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.events;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }
    /**
     * Method that gets events
     * @param {string} serviceId id of the current service
     * @param {number} offset pagination offset
     * @param {number} limit pagination limit
     * @returns {Observable<any>} the object containing the list of events found and the total number of events
     */
    public getEvents(serviceId?: string, offset = 0, limit = 10): Observable<any> {
        const extraParams = {
            event_target_id: serviceId,
            page: offset,
            limit
        };

        return this.make('get_events', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return {
                        total: resp.metadata.total,
                        events: resp.events
                    };
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that marks event as useful.
     * On the first call, the event will be updated with a useful=true field.
     * On the second call, event will be updated with useful=false. Works likle a toggle
     * @param {string} eventId Id of the event
     * @param {EventTypes} eventType Type of the event.
     * @returns {Observable<any>} true if access succesfully marked as useful
     */
    public markEventUseful(eventId: string, eventType: EventTypes): Observable<any> {
        const extraParams = {
            event_id: eventId,
            event_type: eventType
        };

        return this.make('mark_event_useful', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that marks event as viewed (only for account_takeover event)
     * On the first call, the event will be updated with a viewed=true field.
     * On the second call, event will be updated with viewed=false. Works likle a toggle
     * @param {string} eventId Id of the event
     * @param {EventTypes} eventType Type of the event.
     * @returns {Observable<any>} true if access succesfully marked as useful
     */
    public markEventViewed(eventId: string, eventType: EventTypes): Observable<any> {
        const extraParams = {
            event_id: eventId,
            event_type: eventType
        };

        return this.make('mark_event_viewed', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that sets event status
     * @param {string} eventId Id of the event
     * @param {EventTypes} eventType Type of the event.
     * @param {EventStatus} status
     * @returns {Observable<any>} true if status succesfully set
     */
    public setEventStatus(eventId: string, eventType: EventTypes, status: EventStatus): Observable<any> {
        const extraParams = {
            event_id: eventId,
            event_type: eventType,
            status
        };

        return this.make('set_event_status', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that gets live report data
     * @returns {Observable<LiveReportDataModel>} the live report data
     */
    public getLiveReport(): Observable<LiveReportDataModel> {
        return this.make('get_report').pipe(
            map(resp => {
                return resp;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * Method that undo's an event
     * @param {string} eventId the id of the event
     * @returns {Observable<any>} true if undo was successfull
     */
    public undoEvent(eventId: string): Observable<boolean> {
        const extraParams = {
            event_id: eventId
        };

        return this.make('undo_event', extraParams).pipe(
            map(resp => {
                if (resp?.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return true;
                } else {
                    throw new Error(resp);
                }
            })
        );
    }

    /**
     * Method that gets inboxes for the current account
     * @returns {Observable<AtoServiceModel[]>} the list of services found
     */
    public getInboxes(): Observable<any> {
        const _json = {
            id: parseInt((Math.random() * 1000).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_inboxes',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: 'test', //this.valuesService.connectDeviceId,
                    app_id: 'local' //this.valuesService.appATO
                }
            }
        };

        return this.requestsService.make(this.configService.config.atoServer, this.valuesService.atoService, _json, 'POST')
        .pipe(
            map(resp => {
                if (resp?.result && resp?.result.status === this.valuesService.requestStatuses.SUCCESS_STATUS) {
                    return resp.result;
                }
                throw resp?.internal_data || resp;
            }),
            catchError(error => {
                throw error;
            })
        );
    }

}
