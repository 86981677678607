// External
import { Injectable } from '@angular/core';

// Internal
import { ProfilesService } from '../../process/profiles/profiles.service';
import { ContextTypeUI } from '../../../values/business.values.service';
import { GroupTypes } from '../../../models/subscriptions/Groups.model';
import { GroupManagementService } from '../../process/subscriptions/group-management.service';

export interface ContextInterface {
    contextId: string;
    showGroupLabel: boolean;
    contextLabel: string;
    groupLabel: string;
    roleLabel: string;
    current?: boolean;
}

@Injectable({
    providedIn: 'root'
})

export class IContextService {

    private contextsInterface = [] as ContextInterface[];
    private currentContextInterface = null as ContextInterface;


    constructor(
        private readonly profilesService: ProfilesService,
        private readonly groupManagementService: GroupManagementService
    ) { }

    /**
     * Create the context interface
     */
    public createContextsInterface(): void {
        const contextsInterface = [] as ContextInterface[];
        const groups = this.profilesService.getGroups();
        if (!groups || groups.length === 0) {
            return;
        }
        const primaryContextId = this.profilesService.getPrimaryContextId();
        const currentContextId = this.profilesService.getCurrentContextId();
        const currentontextIsPrimary = primaryContextId === currentContextId;
        const primaryContext = {
            contextId: primaryContextId,
            contextLabel: ContextTypeUI[GroupTypes.STANDALONE].label,
            groupLabel: '',
            roleLabel: '',
            current: currentontextIsPrimary
        } as ContextInterface;
        contextsInterface.push(primaryContext);
        let currentContex = currentontextIsPrimary ? primaryContext : null as ContextInterface;

        for (const group of groups) {
            if (!group?.context_id) {
                continue;
            }

            const context = {
                contextId: group.context_id,
                contextLabel: ContextTypeUI[group.group_type].label,
                showGroupLabel: group.group_type !== GroupTypes.ATO,
                groupLabel: group.group_label,
                roleLabel: this.groupManagementService.getGroupRoleLabel(group.role, group?.member_label)
            } as ContextInterface;
            contextsInterface.push(context);
            if (group.context_id === currentContextId) {
                context.current = true;
                currentContex = context;
            }
        }
        if (contextsInterface.length === 1) {
            return;
        }
        this.contextsInterface = contextsInterface;
        this.currentContextInterface = currentContex;
    }

    /**
     * Get the array of contexts interface
     * @returns {ContextInterface[]} The array of contexts interface
     */
    public getContextsInterface(): ContextInterface[] {
        return this.contextsInterface;
    }

    /**
     * Get the current context interface
     * @returns {ContextInterface} The current context interface
     */
    public getCurrentContextInterface(): ContextInterface {
        return this.currentContextInterface;
    }

}
