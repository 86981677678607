import { UiIcon } from "../ui-icon/ui-icon.models";

export namespace UiButton {
    export interface Component {
        /**
         *
         * Options: solid / discrete / outline / link
         * @type {UiButton.Model}
         * @memberof Component
         */
        model: UiButton.Model;

        /**
         * Ex: primary, danger
         * No default color is set, any button with no color will be clear in wich case color is inherited from parent
         * @type {string}
         * @memberof Component
         */
        color: string;

        /**
         *
         * Size options: sm, md, lg, xl
         * @type {UiButton.Size}
         * @memberof Component
         */
        size: UiButton.Size;

        /**
         *
         * Add ellipsis to text
         * @type {boolean}
         * @memberof Component
         */
        ellipsis: boolean;

        /**
         *
         * Place an icon on the left
         * @type {UiIcon.Icon}
         * @memberof Component
         */
        iconLeft: UiIcon.Svg;

        /**
         *
         * Place an icon on the right
         * @type {UiIcon.Icon}
         * @memberof Component
         */
        iconRight: UiIcon.Svg;

        /**
         * The button will show only this icon
         * If you need to combine with text use iconLeft/iconRight
         * @type {UiIcon.Icon}
         * @memberof Component
         */
        iconMid: UiIcon.Svg;
    }

    export enum Model {
        SOLID = 'solid',
        DISCRET = 'discrete',
        OUTLINE = 'outline',
        LINK = 'link',
        CLEAR = 'clear'
    }

    export enum Size {
        SM = 'sm',
        MD = 'md',
        LG = 'lg',
        XL = 'xl'
    }

    export enum Alignment {
        START = 'justify-content-start',
        CENTER = 'justify-content-center',
        END = 'justify-content-end',
        SPACE_BETWEEN = 'justify-space-between'
    }
}