import { ButtonDescription, ImageDescription, TextDescription } from "../../values/values.service";

export enum SetupStep {
    OnboardedStarted = 'onboarding_started',
    ServiceConnectStart = 'service_connect_start',
    ServiceConnectEnd = 'service_connect_end',
    ProtectDeviceStart = 'protect_device_start',
    ProtectDeviceEnd = 'protect_device_end',
    OnboardingFinished = 'onboarding_finished'
}

export interface LiveReportDataModel {
    emails_monitored: number;
    email_scans: number;
    emails_scanned: number;
    dangerous_emails_marked: number;
    devices_protected: number;
    team_members_protected: number;
    recommendations_resolved: number;
    threats_blocked: number;
    malicious_url_blocked: number;
    services_report: {string: ServiceReportModel};
}

export interface ServiceReportModel {
    video_count: number;
    playlist_count: number;
    scan_count: number;
}

export interface IconInterface {
    type: string;
    value: string;
    color?: string;
    bg?: string;
}

export interface LiveReportFieldInterface {
    icon: IconInterface;
    label: string;
    value: number | string;
}

export enum LiveReportField {
    EMAILS_MONITORED = 'emailsMonitored',
    THREATS_BLOCKED = 'threatsBlocked',
    VIDEOS_MONITORED = 'videosMonitored',
    EMAILS_SCANNED = 'emailsSCanned',
    DEVICES_PROTECTED = 'devicesProtected',
    TEAMS_PROTECTED = 'teamsProtected',
    SCANS = 'scans',
    DETECTING_MALICIOUS_URLS = 'detectingMaliciuosUrls',
    MARKING_DANGEROUS_EMAIL = 'markingDangerousEmails',
    FEATURE_PLAYLISTS = 'featurePlaylists',
    RECOMENDATIONS = 'recomendations'
}

export type LiveReportInterface = {
    [key in LiveReportField]?: LiveReportFieldInterface;
}

export interface AtoReconnectFlowInterface {
    img: ImageDescription;
    title: TextDescription;
    desc: TextDescription;
    actionButton: ButtonDescription;
}

export type AtoReconnectInterface = {
    [key in ReconnectFlow]: AtoReconnectFlowInterface
}

export enum ReconnectFlow {
    CONNECT_YT_INFO = 'connectYoutubeInfo',
    CONNECT_YT_ERROR = 'connectYoutubeError',
    CONNECT_YT_OK = 'connectYoutubeOk',
    SETUP_ERROR = 'setupError'
}
