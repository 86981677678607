//  External
import { Injectable } from '@angular/core';

//  Internal
import { ConfigService } from '../../../common/config/config.service';
import { ValuesService } from '../../../common/values/values.service';


@Injectable({
    providedIn: 'root'
})

export class ValuesSupportService {

    constructor(
        private readonly configService: ConfigService,
        private readonly valuesService: ValuesService
    ) { }

    omnitureEvents = {
        popupPhone: 'popup-phone',
        formSubmitSupport: 'formSubmitSupport'
    };

    securityNewsCache = 'securityNewsCache';

    placeholders = {
        manufacturerName: "{{manufacturerName}}",
        siteName: "{{siteName}}",
        linkHref: "{{linkHref}}",
        linkPlaceholder: "{{linkPlaceholder}}"
    }

    interval = {
        nineToFive: '9:00 - 17:00',
        nineToSix: '9:00 - 18:00',
        nineToSeven: '09:00 - 19:00',
        universalNonstop: '24/7'
    };

    languagesTranslated = {
        english: 'main.languages.engleza',
        german: 'main.languages.germana',
        french: 'main.languages.franceza',
        spanish: 'main.languages.spaniola',
        italian: 'main.languages.italiana',
        dutch: 'main.languages.olandeza',
        portuguese: 'main.languages.portugheza',
        romanian: 'main.languages.romana',
        swedish: 'main.languages.suedeza'
    }


    countriesSupport = {
        'de_DE': {
            language: this.languagesTranslated.german,
            flag: this.configService.config.pathImages.concat('flags-svg/de_flag.svg'),
            label: 'Deutschland ',
            numbers: ['(+49) 2304 9993005'],
            type: 'default',
            interval: this.interval.nineToSeven
        },
        'en_AU': {
            language: this.languagesTranslated.english,
            flag: this.configService.config.pathImages.concat('flags-svg/au_flag.svg'),
            label: 'Australia',
            numbers: ['(+61) 2801 48283', '(+61) 1300 954 574'],
            type: 'default',
            interval: ''
        },
        'en_GB': {
            language: this.languagesTranslated.english,
            flag: this.configService.config.pathImages.concat('flags-svg/gb_flag.svg'),
            label: 'United Kingdom',
            numbers: ['(+44) 208 819 2649', '(+44) 203 514 8734'],
            type: 'default',
            interval: ''
        },
        'en_US': {
            language: this.languagesTranslated.english,
            flag: this.configService.config.pathImages.concat('flags-svg/us_flag.svg'),
            label: 'United States',
            numbers: ['(+1) 954 414 9655', '(+1) 954 281 4024'],
            type: 'default',
            interval: ''
        },
        'es_ES': {
            language: this.languagesTranslated.spanish,
            flag: this.configService.config.pathImages.concat('flags-svg/es_flag.svg'),
            label: 'Espa\xf1a',
            numbers: ['(+34) 932 202 375'],
            type: 'default',
            interval: this.interval.nineToSix
        },
        'fr_CA': {
            language: this.languagesTranslated.french,
            flag: this.configService.config.pathImages.concat('flags-svg/ca_flag.svg'),
            label: 'Canada',
            numbers: ['(+1) 647 955 1197', '(+1) 647 847 3405'],
            type: 'default',
            interval: this.interval.nineToFive
        },
        'fr_FR': {
            language: this.languagesTranslated.french,
            flag: this.configService.config.pathImages.concat('flags-svg/fr_flag.svg'),
            label: 'France',
            numbers: ['+33 (0) 800 961 161', '+33 (9) 70 01 97 15'],
            type: 'default',
            interval: this.interval.nineToFive
        },
        'it_IT': {
            language: this.languagesTranslated.italian,
            flag: this.configService.config.pathImages.concat('flags-svg/it_flag.svg'),
            label: 'Italia',
            numbers: ['(+39) 0699 268 342'],
            type: 'default',
            interval: this.interval.nineToSix
        },
        'ja_JP': {
            language: 'main.languages.japoneza',
            flag: this.configService.config.pathImages.concat('flags-svg/jp_flag.svg'),
            label: '日本',
            numbers: ['(+81) 368908580'],
            type: 'default',
            interval: this.interval.nineToFive
        },
        'nl_NL': {
            language: this.languagesTranslated.dutch,
            flag: this.configService.config.pathImages.concat('flags-svg/nl_flag.svg'),
            label: 'Netherlands',
            numbers: ['(+31) 208084508', '(+31) 203690744'],
            type: 'default',
            interval: this.interval.universalNonstop
        },
        'pt_BR': {
            language: this.languagesTranslated.portuguese,
            flag: this.configService.config.pathImages.concat('flags-svg/br_flag.svg'),
            label: 'Brasil',
            numbers: ['(+55) 11 395 88035'],
            type: 'default',
            interval: this.interval.nineToFive
        },
        'ro_RO': {
            language: this.languagesTranslated.romanian,
            flag: this.configService.config.pathImages.concat('flags-svg/ro_flag.svg'),
            label: 'Rom\xe2nia',
            numbers: ['(+40) 21 300 1226', '(+40) 374 303 027'],
            type: 'default',
            interval: ''
        },
        'sv_SE': {
            language: this.languagesTranslated.swedish,
            flag: this.configService.config.pathImages.concat('flags-svg/se_flag.svg'),
            label: 'Sverige',
            numbers: ['(+46) 108848232'],
            type: 'default',
            interval: this.interval.universalNonstop
        },
        // Worldwide
        'worldwide': {
            language: '',
            label: 'Worldwide',
            numbers: ['(+40) 21 264 1794', '(+40) 374 303 094'],
            type: 'default',
            interval: 'support.phone.description'
        },
        // Soho si Bitdefender Premium Security
        'soho': {
            label: '',
            flag: '',
            language: '',
            numbers: ['1 (800) 839-6823'],
            type: 'custom',
            interval: ''
        },
        'bps': {
            label: '',
            flag: '',
            language: '',
            numbers: ['1 (954) 858-5254'],
            type: 'custom',
            interval: ''
        }
    };

    // TODO searchArticlesLanguage should replace languages_for_sections_display in future refactor
    searchArticlesLanguages = {
        'en_US': 'en_US',
        'en_GB': 'en_US',
        'en_AU': 'en_US',
        'ar_SA': 'en_US',
        'fr_FR': 'fr_FR',
        'fr_CA': 'fr_FR',
        'de_DE': 'de_DE',
        'ro_RO': 'ro_RO',
        'it_IT': 'it_IT',
        'es_ES': 'es_ES'
    }

    emailNotSupportedLanguages = new Set([
            this.valuesService.languages.cs_CZ.id,
            this.valuesService.languages.el_GR.id,
            this.valuesService.languages.es_ES.id,
            this.valuesService.languages.de_DE.id,
            this.valuesService.languages.fr_CA.id,
            this.valuesService.languages.fr_FR.id,
            this.valuesService.languages.hu_HU.id,
            this.valuesService.languages.it_IT.id,
            this.valuesService.languages.ja_JP.id,
            this.valuesService.languages.ko_KR.id,
            this.valuesService.languages.pl_PL.id,
            this.valuesService.languages.pt_BR.id,
            this.valuesService.languages.pt_PT.id,
            this.valuesService.languages.ru_RU.id,
            this.valuesService.languages.th_TH.id,
            this.valuesService.languages.tr_TR.id,
            this.valuesService.languages.vi_VN.id
        ]
    );

    displayedVideoNo = 4;

    videoTutorials = [
        {
            title: 'support.video.activate.subscription',
            omnitureName: 'how-to-activate-subscription',
            'fr_FR': {
                url: `https://www.youtube.com/embed/H-OPlitx6D8?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-ACTIVATION.png',
                alt: ''
            },
            'fr_CA': {
                url: `https://www.youtube.com/embed/H-OPlitx6D8?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-ACTIVATION.png'
            },
            'de_DE': {
                url: `https://www.youtube.com/embed/gaWCNW_YCtw?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/DE-ACTIVATION.png',
                alt: ''
            },
            'default': {
                url: `https://www.youtube.com/embed/Zp9TtWz-Q8c?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/activating-subscriptions.png',
                alt: ''
            },
            hideForMspOrXsp: true
        },
        {
            title: 'support.video.install.windows',
            omnitureName: 'how-to-install-on-windows',
            'fr_FR': {
                url: `https://www.youtube.com/embed/w2ZQO_ZsLQY?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-WINDOWS.png',
                alt: ''
            },
            'fr_CA': {
                url: `https://www.youtube.com/embed/w2ZQO_ZsLQY?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-WINDOWS.png',
                alt: ''
            },
            'de_DE': {
                url: `https://www.youtube.com/embed/s4ARY38l3qs?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/DE-WINDOWS.png',
                alt: ''
            },
            'ro_RO': {
                url: `https://www.youtube.com/embed/a1ilfLHyijY?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/RO-WINDOWS.png',
                alt: ''
            },
            'default': {
                url: `https://www.youtube.com/embed/mCGS_pHMxbk?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/installing-on-windows.png',
                alt: ''
            },
            hideForMspOrXsp: false
        },
        {
            title: 'support.video.install.mac',
            omnitureName: 'how-to-install-on-mac',
            'fr_FR': {
                url: `https://www.youtube.com/embed/B56cfnNN8nE?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-MAC.png',
                alt: ''
            },
            'fr_CA': {
                url: `https://www.youtube.com/embed/B56cfnNN8nE?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-MAC.png',
                alt: ''
            },
            'de_DE': {
                url: `https://www.youtube.com/embed/CJZbl6GhYvY?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/DE-MAC.png',
                alt: ''
            },
            'ro_RO': {
                url: `https://www.youtube.com/embed/GTVliyaoKBU?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/RO-MAC.png',
                alt: ''
            },
            'default': {
                url: `https://www.youtube.com/embed/hhev0DRapK4?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/installing-on-mac.png',
                alt: ''
            },
            hideForMspOrXsp: false
        },
        {
            title: 'support.video.install.android',
            omnitureName: 'how-to-install-on-android',
            'fr_FR': {
                url: `https://www.youtube.com/embed/EVfM174fxHo?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-ANDROID.png',
                alt: ''
            },
            'fr_CA': {
                url: `https://www.youtube.com/embed/EVfM174fxHo?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/FR-ANDROID.png',
                alt: ''
            },
            'de_DE': {
                url: `https://www.youtube.com/embed/rh5eSVj9jpQ?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/DE-ANDROID.png',
                alt: ''
            },
            'ro_RO': {
                url: `https://www.youtube.com/embed/ZkrvAMGUr7s?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/RO-ANDROID.png',
                alt: ''
            },
            'default': {
                url: `https://www.youtube.com/embed/0POIfgUvF10?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`,
                img: '/ux/images/support/installing-on-android.png',
                alt: ''
            },
            hideForMspOrXsp: false
        },
        {
            title: 'support.video.install.ios',
            omnitureName: 'how-to-install-on-ios',
            'ro_RO': {},
            'default': {},
            hideForMspOrXsp: false
        },
    ]


    supportArticles = [
        {
            title: 'support.main.get.started.title',
            description: 'support.main.get.started.description',
            icon: '/ux/images/support/illustration_start.svg',
            link: '/support/category/start'
        },
        {
            title: 'support.main.using.title',
            description: 'support.main.using.description',
            icon: '/ux/images/support/illustration_using.svg',
            link: '/support/category/use'
        },
        {
            title: 'support.main.account.billing.title',
            description: 'support.main.account.billing.description',
            icon: '/ux/images/support/illustration_billing.svg',
            link: '/support/category/account-billing'
        },
        {
            title: 'support.main.threat.remediation.title',
            description: 'support.main.threat.remediation.description',
            icon: '/ux/images/support/illustration_threat.svg',
            link: '/support/category/threat'
        }
    ];

    errorsForm = {
        upload_bdsys: 'support.form.upload.bdsys.error.msg',
        product: 'support.form.product.error.msg',
        operatingSystem: 'support.form.os.error.msg',
        category: 'support.form.category.error.msg',
        refundReason: 'support.form.reason_refund.error.msg',
        message: {
            valid: 'support.form.comment.error.msg',
            minLength: 'support.form.comment.error.msg.length.min',
            maxLength: 'support.form.comment.error.msg.length.max',
            noCharacterLimit: 'support.form.comment.error.msg.length.noCharacterLimit'
        }
    };

    languagesCodes = {'it': 2, 'fr': 5, 'nl': 1, 'br': 7, 'pt': 7, 'es': 3, 'ro': 8, 'de': 4, 'uk': 1, 'au': 1, 'en': 1};

    countriesRelations ={
        "af": 110,
        "ax": 111,
        "al": 112,
        "dz": 113,
        "as": 114,
        "ad": 115,
        "ao": 116,
        "ai": 117,
        "aq": 118,
        "ag": 119,
        "ar": 120,
        "am": 121,
        "aw": 122,
        "ap": 123,
        "au": 124,
        "at": 125,
        "az": 126,
        "bs": 127,
        "bh": 128,
        "bd": 129,
        "bb": 130,
        "by": 131,
        "be": 132,
        "bz": 133,
        "bj": 134,
        "bm": 135,
        "bt": 136,
        "bo": 137,
        "bq": "",
        "ba": 138,
        "bw": 139,
        "bv": 140,
        "br": 141,
        "io": 142,
        "bn": 143,
        "bg": 144,
        "bf": 145,
        "bi": 146,
        "kh": 147,
        "cm": 148,
        "ca": 149,
        "ic": "",
        "cv": 150,
        "ky": 151,
        "cf": 152,
        "td": 153,
        "cl": 154,
        "cn": 155,
        "cx": 156,
        "cc": 371,
        "co": 158,
        "km": 159,
        "cg": 160,
        "cd": 161,
        "ck": 162,
        "cr": 163,
        "ci": 164,
        "hr": 165,
        "cu": 166,
        "cw": "",
        "cy": 167,
        "cz": 168,
        "dk": 169,
        "dj": 170,
        "dm": 171,
        "do": 172,
        "ec": 173,
        "eg": 174,
        "sv": 175,
        "gq": 176,
        "er": 177,
        "ee": 178,
        "et": 179,
        "eu": "",
        "fk": 180,
        "fo": 181,
        "fj": 182,
        "fi": 183,
        "fr": 184,
        "gf": 185,
        "pf": 186,
        "tf": 187,
        "ga": 188,
        "gm": 189,
        "ge": 190,
        "de": 191,
        "gh": 192,
        "gi": 193,
        "gr": 194,
        "gl": 195,
        "gd": 196,
        "gp": 197,
        "gu": 198,
        "gt": 199,
        "gg": 200,
        "gn": 201,
        "gw": 202,
        "gy": 203,
        "ht": 204,
        "hm": 372,
        "va": 205,
        "hn": 206,
        "hk": 207,
        "hu": 208,
        "is": 209,
        "in": 210,
        "id": 211,
        "ir": 212,
        "iq": 213,
        "ie": 214,
        "im": 215,
        "il": 216,
        "it": 217,
        "jm": 218,
        "jp": 219,
        "je": 220,
        "jo": 221,
        "kz": 222,
        "ke": 223,
        "ki": 224,
        "kp": 373,
        "kr": 374,
        "kw": 226,
        "kg": 227,
        "la": 375,
        "lv": 228,
        "lb": 229,
        "ls": 230,
        "lr": 231,
        "ly": 376,
        "li": 232,
        "lt": 233,
        "lu": 234,
        "mo": 235,
        "mk": 236,
        "mg": 237,
        "mw": 238,
        "my": 239,
        "mv": 240,
        "ml": 241,
        "mt": 242,
        "mh": 243,
        "mq": 244,
        "mr": 245,
        "mu": 246,
        "yt": 247,
        "mx": 248,
        "fm": 249,
        "md": 250,
        "mc": 251,
        "mn": 252,
        "me": 253,
        "ms": 254,
        "ma": 255,
        "mz": 256,
        "mm": 257,
        "na": 258,
        "nr": 259,
        "np": 260,
        "nl": 261,
        "an": 263,
        "nc": 262,
        "nz": 264,
        "ni": 265,
        "ne": 266,
        "ng": 267,
        "nu": 268,
        "nf": 269,
        "mp": 270,
        "no": 271,
        "om": 272,
        "pk": 274,
        "pw": 275,
        "ps": 276,
        "pa": 277,
        "pg": 278,
        "py": 279,
        "pe": 280,
        "ph": 281,
        "pn": 377,
        "pl": 282,
        "pt": 283,
        "pr": 284,
        "qa": 285,
        "re": 286,
        "ro": 287,
        "ru": 288,
        "rw": 289,
        "sh": 290,
        "kn": 378,
        "lc": 379,
        "pm": 380,
        "vc": 381,
        "ws": 291,
        "sm": 292,
        "st": 382,
        "sa": 293,
        "sn": 294,
        "rs": 295,
        "sc": 296,
        "sl": 297,
        "sg": 298,
        "mf": "",
        "sk": 299,
        "si": 300,
        "sb": 301,
        "so": 302,
        "za": 303,
        "gs": 383,
        "es": 304,
        "lk": 305,
        "sd": 306,
        "sr": 307,
        "sj": 384,
        "sz": 308,
        "se": 309,
        "ch": 310,
        "sy": 311,
        "tw": 312,
        "tj": 313,
        "tz": 314,
        "th": 315,
        "tl": 385,
        "tg": 316,
        "tk": 317,
        "to": 318,
        "tt": 319,
        "tn": 320,
        "tr": 321,
        "tm": 322,
        "tc": 386,
        "tv": 387,
        "ug": 323,
        "ua": 324,
        "ae": 325,
        "gb": 326,
        "us": 327,
        "um": 328,
        "uy": 329,
        "uz": 330,
        "vu": 331,
        "ve": 332,
        "vn": 333,
        "vg": 334,
        "vi": 335,
        "wf": 336,
        "eh": 337,
        "ye": 338,
        "zm": 339,
        "zw": 340,
        "o1": 273
     };

    extensionsFile = ['zip', 'jpeg', 'png', 'bmp', 'jpg' ];

    groupedFAQ = {
        english: "https://www.bitdefender.com/consumer/support/answer/34171/"
    };

    public readonly linksFAQ = {
        0: {
            ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/21896/',
            pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/28269/',
            fr_FR: 'https://community.tango.lu/?lang=fr',
            fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/11144/',
            de_DE: 'https://community.tango.lu/?lang=de',
            es_ES: 'https://www.bitdefender.es/consumer/support/answer/14440/',
            nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/14731/',
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/22303/',
            pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/27876/',
            sv_SE: 'https://www.bitdefender.se/consumer/support/answer/37371/',
            default: 'https://community.tango.lu/?lang=en'
        },
        1: {
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/22055/',
            pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/28000/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/11137/',
            fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/11137/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/12336/',
            ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/19087/',
            es_ES: 'https://www.bitdefender.es/consumer/support/answer/14195/',
            nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/14487/',
            pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/27632',
            sv_SE: 'https://www.bitdefender.se/consumer/support/answer/17304/',
            default: 'https://www.bitdefender.com/consumer/support/answer/1715/'
        },
        3: {
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/31474/',
            default: 'https://www.bitdefender.com/consumer/support/answer/31474/'
        },
        4: {
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/30797/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/31920/',
            fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/31920/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/31649/',
            default: 'https://www.bitdefender.com/consumer/support/answer/29362/'
        },
        4.1: {
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/30171/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/31504/',
            fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/31504/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/31469/',
            default: 'https://www.bitdefender.com/consumer/support/answer/29214/'
        },
        7: {
            nl_NL: 'https://www.bitdefender.nl/consumer/support/answer/37303/',
            it_IT: 'https://www.bitdefender.it/consumer/support/answer/38058/',
            pt_BR: 'https://www.bitdefender.com.br/consumer/support/answer/38057/',
            pt_PT: 'https://www.bitdefender.pt/consumer/support/answer/38056/',
            fr_FR: 'https://www.bitdefender.fr/consumer/support/answer/10917/',
            fr_CA: 'https://www.bitdefender.fr/consumer/support/answer/10917/',
            de_DE: 'https://www.bitdefender.de/consumer/support/answer/21413/',
            ro_RO: 'https://www.bitdefender.ro/consumer/support/answer/38055/',
            es_ES: 'https://www.bitdefender.es/consumer/support/answer/22900/',
            sv_SE: 'https://www.bitdefender.se/consumer/support/answer/38054/',
            en_US: this.groupedFAQ.english,
            en_GB: this.groupedFAQ.english,
            en_AU: this.groupedFAQ.english,
            ar_SA: this.groupedFAQ.english
        }
    };

    myAccountLink = "https://secure.2co.com/myaccount";
    findOrderDELink = "https://shop.bitdefender.com/FindOrderDE";

    bdSyslink = "https://download.bitdefender.com/supporttools/bdsyslog/BDSysLog_i.exe?adobe_mc=" +
                "MCMID%3D08620580944495050470786171494304106434%7CMCAID%3D2E3B495A053142CB-6000010D40000122%7CMCORGID%3D0E920C0F53DA9E9B0A490D45%2540AdobeOrg%7CTS%3D1567524075";

    bdSysLog = 'BDSysLog';
    paymentPlatform = '2checkout';

    // Used in end-service-modal:
    categoriesIndex = {
        refund: 4
    };

    // Used in end-service-modal:
    refundReasons = {
       'dontneedtheproduct': 5,
       'purchasedifferentproduct': 6,
       'notsatisfiedwithproduct': 7
    };

    allProducts = {
        box2:               this.valuesService.bundleBOX2,
        totalSecurity:      this.valuesService.bundleTS,
        internetSecurity:   this.valuesService.bundleIS,
        antivirusFree:      this.valuesService.bundleAVLITE,
        antivirusPlus:      this.valuesService.bundleAV,
        avForMac:           this.valuesService.bundleAVFM,
        familyPack:         this.valuesService.bundleFP,
        premiumSecurity:    this.valuesService.bundlePSec,
        premiumSecurityPlus:this.valuesService.bundlePSecPlus,
        soho:               this.valuesService.bundleSOHO,
        bms:                this.valuesService.bundleBMS,
        iosProtection:      this.valuesService.bundleBIP,
        vpn:                this.valuesService.bundleVPN,
        passManager:        this.valuesService.bundlePM,
        dataPrivacy:        this.valuesService.bundleDPI,
        startPlan:          this.valuesService.bundleStartPlan,
        familyPlan:         this.valuesService.bundleFamilyPlan,
        ultimatePlan:       this.valuesService.bundleUltimatePlan,
        personalPlan:       this.valuesService.bundlePersonalPlan,
        secureIdentity:     this.valuesService.bundleSecIdentity
    };

    osMapping = {
        none: '0',
        windows: '918',
        ios: '370',
        osx: '90' ,
        android: '89'
    };

    productMapping = {
        [this.allProducts.box2]: '117',
        [this.allProducts.totalSecurity]: '170',
        [this.allProducts.internetSecurity]: '169',
        [this.allProducts.antivirusFree]: '193',
        [this.allProducts.antivirusPlus]: '168',
        [this.allProducts.avForMac]: '57',
        [this.allProducts.familyPack]: '29',
        [this.allProducts.premiumSecurity]: '149',
        [this.allProducts.soho]: '124',
        [this.allProducts.bms]: '6',
        [this.allProducts.iosProtection]: '101',
        [this.allProducts.vpn]: '166',
        [this.allProducts.passManager]: '190',
        [this.allProducts.dataPrivacy]: '163',
        [this.allProducts.startPlan]: '172',
        [this.allProducts.familyPlan]: '173',
        [this.allProducts.ultimatePlan]: '174',
        [this.allProducts.personalPlan]: '175',
        "other": '60'
    };

    defaultFormSubject = "Ticket";
    supportFormProductsTextsKeys = {
        general: 'support.form.opt.product.general',
        security: 'support.form.opt.product.security'
    };

    issuesMapping = {
        activation: "2640",
        installation: "2639",
        autoRenewal: "2646",
        refund: "2647",
        salesInquires: "2637",
        productIssues: "2642",
        productConfiguration: "2641",
        threatRemediaion: "2644",
        none: '0'
    };

    refundMapping = {
        none:               { value: '0',    secondValue: '0',    secondField: '0'},
        renewal:            { value: '3200', secondValue: '3517', secondField: '3515'},
        alreadyPurchased:   { value: '3708', secondValue: '3716', secondField: '3709'},
        competitorPurchase: { value: '3708', secondValue: '3710', secondField: '3709'},
        price:              { value: '3708', secondValue: '3719', secondField: '3709'},
        noUse:              { value: '3708', secondValue: '3715', secondField: '3709'},
        wrongPurchase:      { value: '3708', secondValue: '3720', secondField: '3709'},
        notSatisfied:       { value: '3708', secondValue: '3713', secondField: '3709'},
        technicalIssues:    { value: '3708', secondValue: '3718', secondField: '3709'},
        cantInstall:        { value: '3201', secondValue: '3531', secondField: '3528'},
    };

    catMapping = {
        [this.valuesService.languages.de_DE.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 127,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 127,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 127,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 127,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 127,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 127,
                // Other
                [this.productMapping.other]: 127,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 127,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 132,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 127,
                [this.productMapping[this.allProducts.iosProtection]]: 127,
                [this.productMapping[this.allProducts.vpn]]: 127,
                [this.productMapping[this.allProducts.passManager]]: 127,
                [this.productMapping[this.allProducts.startPlan]]: 127,
                [this.productMapping[this.allProducts.familyPlan]]: 127,
                [this.productMapping[this.allProducts.ultimatePlan]]: 127,
                [this.productMapping[this.allProducts.personalPlan]]: 127,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 126,
                [this.productMapping[this.allProducts.internetSecurity]]: 126,
                [this.productMapping[this.allProducts.antivirusFree]]: 126,
                [this.productMapping[this.allProducts.antivirusPlus]]: 126,
                [this.productMapping[this.allProducts.avForMac]]: 126,
                [this.productMapping[this.allProducts.bms]]: 126,
                [this.productMapping.other]: 126,
                [this.productMapping[this.allProducts.box2]]: 126,
                [this.productMapping[this.allProducts.soho]]: 132,
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 126,
                [this.productMapping[this.allProducts.iosProtection]]: 126,
                [this.productMapping[this.allProducts.vpn]]: 126,
                [this.productMapping[this.allProducts.passManager]]: 126,
                [this.productMapping[this.allProducts.startPlan]]: 126,
                [this.productMapping[this.allProducts.familyPlan]]: 126,
                [this.productMapping[this.allProducts.ultimatePlan]]: 126,
                [this.productMapping[this.allProducts.personalPlan]]: 126,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 127,
                [this.productMapping[this.allProducts.internetSecurity]]: 127,
                [this.productMapping[this.allProducts.antivirusFree]]: 127,
                [this.productMapping[this.allProducts.antivirusPlus]]: 127,
                [this.productMapping[this.allProducts.avForMac]]: 127,
                [this.productMapping[this.allProducts.bms]]: 127,
                [this.productMapping.other]: 127,
                [this.productMapping[this.allProducts.box2]]: 127,
                [this.productMapping[this.allProducts.soho]]: 132,
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 127,
                [this.productMapping[this.allProducts.iosProtection]]: 127,
                [this.productMapping[this.allProducts.vpn]]: 127,
                [this.productMapping[this.allProducts.passManager]]: 127,
                [this.productMapping[this.allProducts.startPlan]]: 127,
                [this.productMapping[this.allProducts.familyPlan]]: 127,
                [this.productMapping[this.allProducts.ultimatePlan]]: 127,
                [this.productMapping[this.allProducts.personalPlan]]: 127,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 132,
                [this.refundMapping.alreadyPurchased.secondValue]: 132,
                [this.refundMapping.competitorPurchase.secondValue]: 127,
                [this.refundMapping.price.secondValue]: 132,
                [this.refundMapping.noUse.secondValue]: 132,
                [this.refundMapping.wrongPurchase.secondValue]: 132,
                [this.refundMapping.notSatisfied.secondValue]: 132,
                [this.refundMapping.technicalIssues.secondValue]: 132,
                [this.refundMapping.cantInstall.secondValue]: 132,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 127,
                [this.productMapping[this.allProducts.internetSecurity]]: 127,
                [this.productMapping[this.allProducts.antivirusFree]]: 127,
                [this.productMapping[this.allProducts.antivirusPlus]]: 127,
                [this.productMapping[this.allProducts.avForMac]]: 127,
                [this.productMapping[this.allProducts.bms]]: 127,
                [this.productMapping.other]: 127,
                [this.productMapping[this.allProducts.box2]]: 127,
                [this.productMapping[this.allProducts.soho]]: 132,
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 127,
                [this.productMapping[this.allProducts.iosProtection]]: 127,
                [this.productMapping[this.allProducts.vpn]]: 127,
                [this.productMapping[this.allProducts.passManager]]: 127,
                [this.productMapping[this.allProducts.startPlan]]: 127,
                [this.productMapping[this.allProducts.familyPlan]]: 127,
                [this.productMapping[this.allProducts.ultimatePlan]]: 127,
                [this.productMapping[this.allProducts.personalPlan]]: 127,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 126,
                [this.productMapping[this.allProducts.antivirusFree]]: 126,
                [this.productMapping[this.allProducts.antivirusPlus]]: 126,
                [this.productMapping[this.allProducts.avForMac]]: 126,
                [this.productMapping[this.allProducts.bms]]: 126,
                [this.productMapping[this.allProducts.internetSecurity]]: 126,
                [this.productMapping.other]: 126,
                [this.productMapping[this.allProducts.box2]]: 126,
                [this.productMapping[this.allProducts.soho]]: 132,
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 126,
                [this.productMapping[this.allProducts.iosProtection]]: 126,
                [this.productMapping[this.allProducts.vpn]]: 126,
                [this.productMapping[this.allProducts.passManager]]: 126,
                [this.productMapping[this.allProducts.startPlan]]: 126,
                [this.productMapping[this.allProducts.familyPlan]]: 126,
                [this.productMapping[this.allProducts.ultimatePlan]]: 126,
                [this.productMapping[this.allProducts.personalPlan]]: 126,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 126,
                [this.productMapping[this.allProducts.antivirusFree]]: 126,
                [this.productMapping[this.allProducts.antivirusPlus]]: 126,
                [this.productMapping[this.allProducts.avForMac]]: 126,
                [this.productMapping[this.allProducts.bms]]: 126,
                [this.productMapping[this.allProducts.internetSecurity]]: 126,
                [this.productMapping.other]: 126,
                [this.productMapping[this.allProducts.box2]]: 126,
                [this.productMapping[this.allProducts.soho]]: 132,
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 126,
                [this.productMapping[this.allProducts.iosProtection]]: 126,
                [this.productMapping[this.allProducts.vpn]]: 126,
                [this.productMapping[this.allProducts.passManager]]: 126,
                [this.productMapping[this.allProducts.startPlan]]: 126,
                [this.productMapping[this.allProducts.familyPlan]]: 126,
                [this.productMapping[this.allProducts.ultimatePlan]]: 126,
                [this.productMapping[this.allProducts.personalPlan]]: 126,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 125,
                [this.productMapping[this.allProducts.antivirusFree]]: 126,
                [this.productMapping[this.allProducts.antivirusPlus]]: 125,
                [this.productMapping[this.allProducts.avForMac]]: 125,
                [this.productMapping[this.allProducts.bms]]: 125,
                [this.productMapping[this.allProducts.internetSecurity]]: 125,
                [this.productMapping.other]: 125,
                [this.productMapping[this.allProducts.box2]]: 125,
                [this.productMapping[this.allProducts.soho]]: 132,
                [this.productMapping[this.allProducts.premiumSecurity]]: 126,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 125,
                [this.productMapping[this.allProducts.iosProtection]]: 125,
                [this.productMapping[this.allProducts.vpn]]: 125,
                [this.productMapping[this.allProducts.passManager]]: 125,
                [this.productMapping[this.allProducts.startPlan]]: 125,
                [this.productMapping[this.allProducts.familyPlan]]: 125,
                [this.productMapping[this.allProducts.ultimatePlan]]: 125,
                [this.productMapping[this.allProducts.personalPlan]]: 125,
            }
        },
        [this.valuesService.languages.en_US.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 62,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 62,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 62,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 62,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 62,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 62,
                // Other
                [this.productMapping.other]: 62,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 62,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 214,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 62,
                [this.productMapping[this.allProducts.iosProtection]]: 62,
                [this.productMapping[this.allProducts.vpn]]: 62,
                [this.productMapping[this.allProducts.passManager]]: 62,
                [this.productMapping[this.allProducts.startPlan]]: 62,
                [this.productMapping[this.allProducts.familyPlan]]: 62,
                [this.productMapping[this.allProducts.ultimatePlan]]: 62,
                [this.productMapping[this.allProducts.personalPlan]]: 62,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 63,
                [this.productMapping[this.allProducts.antivirusFree]]: 63,
                [this.productMapping[this.allProducts.antivirusPlus]]: 63,
                [this.productMapping[this.allProducts.avForMac]]: 63,
                [this.productMapping[this.allProducts.bms]]: 63,
                [this.productMapping[this.allProducts.internetSecurity]]: 63,
                [this.productMapping.other]: 63,
                [this.productMapping[this.allProducts.box2]]: 63,
                [this.productMapping[this.allProducts.soho]]: 214,
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 63,
                [this.productMapping[this.allProducts.iosProtection]]: 63,
                [this.productMapping[this.allProducts.vpn]]: 63,
                [this.productMapping[this.allProducts.passManager]]: 63,
                [this.productMapping[this.allProducts.startPlan]]: 63,
                [this.productMapping[this.allProducts.familyPlan]]: 63,
                [this.productMapping[this.allProducts.ultimatePlan]]: 63,
                [this.productMapping[this.allProducts.personalPlan]]: 63,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 62,
                [this.productMapping[this.allProducts.antivirusFree]]: 62,
                [this.productMapping[this.allProducts.antivirusPlus]]: 62,
                [this.productMapping[this.allProducts.avForMac]]: 62,
                [this.productMapping[this.allProducts.bms]]: 62,
                [this.productMapping[this.allProducts.internetSecurity]]: 62,
                [this.productMapping.other]: 62,
                [this.productMapping[this.allProducts.box2]]: 62,
                [this.productMapping[this.allProducts.soho]]: 214,
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 62,
                [this.productMapping[this.allProducts.iosProtection]]: 62,
                [this.productMapping[this.allProducts.vpn]]: 62,
                [this.productMapping[this.allProducts.passManager]]: 62,
                [this.productMapping[this.allProducts.startPlan]]: 62,
                [this.productMapping[this.allProducts.familyPlan]]: 62,
                [this.productMapping[this.allProducts.ultimatePlan]]: 62,
                [this.productMapping[this.allProducts.personalPlan]]: 62,
            },
            // Refund
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 68,
                [this.refundMapping.alreadyPurchased.secondValue]: 68,
                [this.refundMapping.competitorPurchase.secondValue]: 62,
                [this.refundMapping.price.secondValue]: 68,
                [this.refundMapping.noUse.secondValue]: 68,
                [this.refundMapping.wrongPurchase.secondValue]: 68,
                [this.refundMapping.notSatisfied.secondValue]: 68,
                [this.refundMapping.technicalIssues.secondValue]: 68,
                [this.refundMapping.cantInstall.secondValue]: 68,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 62,
                [this.productMapping[this.allProducts.antivirusFree]]: 62,
                [this.productMapping[this.allProducts.antivirusPlus]]: 62,
                [this.productMapping[this.allProducts.avForMac]]: 62,
                [this.productMapping[this.allProducts.bms]]: 62,
                [this.productMapping[this.allProducts.internetSecurity]]: 62,
                [this.productMapping.other]: 62,
                [this.productMapping[this.allProducts.box2]]: 62,
                [this.productMapping[this.allProducts.soho]]: 214,
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 62,
                [this.productMapping[this.allProducts.iosProtection]]: 62,
                [this.productMapping[this.allProducts.vpn]]: 62,
                [this.productMapping[this.allProducts.passManager]]: 62,
                [this.productMapping[this.allProducts.startPlan]]: 62,
                [this.productMapping[this.allProducts.familyPlan]]: 62,
                [this.productMapping[this.allProducts.ultimatePlan]]: 62,
                [this.productMapping[this.allProducts.personalPlan]]: 62,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 63,
                [this.productMapping[this.allProducts.antivirusFree]]: 63,
                [this.productMapping[this.allProducts.antivirusPlus]]: 63,
                [this.productMapping[this.allProducts.avForMac]]: 63,
                [this.productMapping[this.allProducts.bms]]: 63,
                [this.productMapping[this.allProducts.internetSecurity]]: 63,
                [this.productMapping.other]: 63,
                [this.productMapping[this.allProducts.box2]]: 63,
                [this.productMapping[this.allProducts.soho]]: 214,
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 63,
                [this.productMapping[this.allProducts.iosProtection]]: 63,
                [this.productMapping[this.allProducts.vpn]]: 63,
                [this.productMapping[this.allProducts.passManager]]: 63,
                [this.productMapping[this.allProducts.startPlan]]: 63,
                [this.productMapping[this.allProducts.familyPlan]]: 63,
                [this.productMapping[this.allProducts.ultimatePlan]]: 63,
                [this.productMapping[this.allProducts.personalPlan]]: 63,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 63,
                [this.productMapping[this.allProducts.antivirusFree]]: 63,
                [this.productMapping[this.allProducts.antivirusPlus]]: 63,
                [this.productMapping[this.allProducts.avForMac]]: 63,
                [this.productMapping[this.allProducts.bms]]: 63,
                [this.productMapping[this.allProducts.internetSecurity]]: 63,
                [this.productMapping.other]: 63,
                [this.productMapping[this.allProducts.box2]]: 63,
                [this.productMapping[this.allProducts.soho]]: 214,
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 63,
                [this.productMapping[this.allProducts.iosProtection]]: 63,
                [this.productMapping[this.allProducts.vpn]]: 63,
                [this.productMapping[this.allProducts.passManager]]: 63,
                [this.productMapping[this.allProducts.startPlan]]: 63,
                [this.productMapping[this.allProducts.familyPlan]]: 63,
                [this.productMapping[this.allProducts.ultimatePlan]]: 63,
                [this.productMapping[this.allProducts.personalPlan]]: 63,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 64,
                [this.productMapping[this.allProducts.antivirusFree]]: 64,
                [this.productMapping[this.allProducts.antivirusPlus]]: 64,
                [this.productMapping[this.allProducts.avForMac]]: 64,
                [this.productMapping[this.allProducts.bms]]: 64,
                [this.productMapping[this.allProducts.internetSecurity]]: 64,
                [this.productMapping.other]: 64,
                [this.productMapping[this.allProducts.box2]]: 64,
                [this.productMapping[this.allProducts.soho]]: 214,
                [this.productMapping[this.allProducts.premiumSecurity]]: 214,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 64,
                [this.productMapping[this.allProducts.iosProtection]]: 64,
                [this.productMapping[this.allProducts.vpn]]: 64,
                [this.productMapping[this.allProducts.passManager]]: 64,
                [this.productMapping[this.allProducts.startPlan]]: 64,
                [this.productMapping[this.allProducts.familyPlan]]: 64,
                [this.productMapping[this.allProducts.ultimatePlan]]: 64,
                [this.productMapping[this.allProducts.personalPlan]]: 64,
            }
        },
        [this.valuesService.languages.es_ES.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 114,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 114,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusFree]]: 114,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 114,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 114,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 114,
                // Other
                [this.productMapping.other]: 114,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 114,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 113,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 114,
                [this.productMapping[this.allProducts.iosProtection]]: 114,
                [this.productMapping[this.allProducts.vpn]]: 114,
                [this.productMapping[this.allProducts.passManager]]: 114,
                [this.productMapping[this.allProducts.startPlan]]: 114,
                [this.productMapping[this.allProducts.familyPlan]]: 114,
                [this.productMapping[this.allProducts.ultimatePlan]]: 114,
                [this.productMapping[this.allProducts.personalPlan]]: 114,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 113,
                [this.productMapping[this.allProducts.antivirusFree]]: 113,
                [this.productMapping[this.allProducts.antivirusPlus]]: 113,
                [this.productMapping[this.allProducts.avForMac]]: 113,
                [this.productMapping[this.allProducts.bms]]: 113,
                [this.productMapping[this.allProducts.internetSecurity]]: 113,
                [this.productMapping.other]: 113,
                [this.productMapping[this.allProducts.box2]]: 113,
                [this.productMapping[this.allProducts.soho]]: 113,
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 113,
                [this.productMapping[this.allProducts.iosProtection]]: 113,
                [this.productMapping[this.allProducts.vpn]]: 113,
                [this.productMapping[this.allProducts.passManager]]: 113,
                [this.productMapping[this.allProducts.startPlan]]: 113,
                [this.productMapping[this.allProducts.familyPlan]]: 113,
                [this.productMapping[this.allProducts.ultimatePlan]]: 113,
                [this.productMapping[this.allProducts.personalPlan]]: 113,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 114,
                [this.productMapping[this.allProducts.antivirusFree]]: 114,
                [this.productMapping[this.allProducts.antivirusPlus]]: 114,
                [this.productMapping[this.allProducts.avForMac]]: 114,
                [this.productMapping[this.allProducts.bms]]: 114,
                [this.productMapping[this.allProducts.internetSecurity]]: 114,
                [this.productMapping.other]: 114,
                [this.productMapping[this.allProducts.box2]]: 114,
                [this.productMapping[this.allProducts.soho]]: 113,
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 114,
                [this.productMapping[this.allProducts.iosProtection]]: 114,
                [this.productMapping[this.allProducts.vpn]]: 114,
                [this.productMapping[this.allProducts.passManager]]: 114,
                [this.productMapping[this.allProducts.startPlan]]: 114,
                [this.productMapping[this.allProducts.familyPlan]]: 114,
                [this.productMapping[this.allProducts.ultimatePlan]]: 114,
                [this.productMapping[this.allProducts.personalPlan]]: 114,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 114,
                [this.refundMapping.alreadyPurchased.secondValue]: 114,
                [this.refundMapping.competitorPurchase.secondValue]: 114,
                [this.refundMapping.price.secondValue]: 114,
                [this.refundMapping.noUse.secondValue]: 114,
                [this.refundMapping.wrongPurchase.secondValue]: 114,
                [this.refundMapping.notSatisfied.secondValue]: 114,
                [this.refundMapping.technicalIssues.secondValue]: 114,
                [this.refundMapping.cantInstall.secondValue]: 114,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 114,
                [this.productMapping[this.allProducts.antivirusFree]]: 114,
                [this.productMapping[this.allProducts.antivirusPlus]]: 114,
                [this.productMapping[this.allProducts.avForMac]]: 114,
                [this.productMapping[this.allProducts.bms]]: 114,
                [this.productMapping[this.allProducts.internetSecurity]]: 114,
                [this.productMapping.other]: 114,
                [this.productMapping[this.allProducts.box2]]: 114,
                [this.productMapping[this.allProducts.soho]]: 113,
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 114,
                [this.productMapping[this.allProducts.iosProtection]]: 114,
                [this.productMapping[this.allProducts.vpn]]: 114,
                [this.productMapping[this.allProducts.passManager]]: 114,
                [this.productMapping[this.allProducts.startPlan]]: 114,
                [this.productMapping[this.allProducts.familyPlan]]: 114,
                [this.productMapping[this.allProducts.ultimatePlan]]: 114,
                [this.productMapping[this.allProducts.personalPlan]]: 114,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 113,
                [this.productMapping[this.allProducts.antivirusFree]]: 113,
                [this.productMapping[this.allProducts.antivirusPlus]]: 113,
                [this.productMapping[this.allProducts.avForMac]]: 113,
                [this.productMapping[this.allProducts.bms]]: 113,
                [this.productMapping[this.allProducts.internetSecurity]]: 113,
                [this.productMapping.other]: 113,
                [this.productMapping[this.allProducts.box2]]: 113,
                [this.productMapping[this.allProducts.soho]]: 113,
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 113,
                [this.productMapping[this.allProducts.iosProtection]]: 113,
                [this.productMapping[this.allProducts.vpn]]: 113,
                [this.productMapping[this.allProducts.passManager]]: 113,
                [this.productMapping[this.allProducts.startPlan]]: 113,
                [this.productMapping[this.allProducts.familyPlan]]: 113,
                [this.productMapping[this.allProducts.ultimatePlan]]: 113,
                [this.productMapping[this.allProducts.personalPlan]]: 113,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 113,
                [this.productMapping[this.allProducts.antivirusFree]]: 113,
                [this.productMapping[this.allProducts.antivirusPlus]]: 113,
                [this.productMapping[this.allProducts.avForMac]]: 113,
                [this.productMapping[this.allProducts.bms]]: 113,
                [this.productMapping[this.allProducts.internetSecurity]]: 113,
                [this.productMapping.other]: 113,
                [this.productMapping[this.allProducts.box2]]: 113,
                [this.productMapping[this.allProducts.soho]]: 113,
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 113,
                [this.productMapping[this.allProducts.iosProtection]]: 113,
                [this.productMapping[this.allProducts.vpn]]: 113,
                [this.productMapping[this.allProducts.passManager]]: 113,
                [this.productMapping[this.allProducts.startPlan]]: 113,
                [this.productMapping[this.allProducts.familyPlan]]: 113,
                [this.productMapping[this.allProducts.ultimatePlan]]: 113,
                [this.productMapping[this.allProducts.personalPlan]]: 113,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 112,
                [this.productMapping[this.allProducts.antivirusFree]]: 112,
                [this.productMapping[this.allProducts.antivirusPlus]]: 112,
                [this.productMapping[this.allProducts.avForMac]]: 112,
                [this.productMapping[this.allProducts.bms]]: 112,
                [this.productMapping[this.allProducts.internetSecurity]]: 112,
                [this.productMapping.other]: 112,
                [this.productMapping[this.allProducts.box2]]: 112,
                [this.productMapping[this.allProducts.soho]]: 113,
                [this.productMapping[this.allProducts.premiumSecurity]]: 113,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 112,
                [this.productMapping[this.allProducts.iosProtection]]: 112,
                [this.productMapping[this.allProducts.vpn]]: 112,
                [this.productMapping[this.allProducts.passManager]]: 112,
                [this.productMapping[this.allProducts.startPlan]]: 112,
                [this.productMapping[this.allProducts.familyPlan]]: 112,
                [this.productMapping[this.allProducts.ultimatePlan]]: 112,
                [this.productMapping[this.allProducts.personalPlan]]: 112,
            }
        },
        [this.valuesService.languages.fr_CA.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 49,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 49,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 49,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 49,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 49,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 49,
                // Other
                [this.productMapping.other]: 49,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 49,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 55,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 49,
                [this.productMapping[this.allProducts.iosProtection]]: 49,
                [this.productMapping[this.allProducts.vpn]]: 49,
                [this.productMapping[this.allProducts.passManager]]: 49,
                [this.productMapping[this.allProducts.startPlan]]: 49,
                [this.productMapping[this.allProducts.familyPlan]]: 49,
                [this.productMapping[this.allProducts.ultimatePlan]]: 49,
                [this.productMapping[this.allProducts.personalPlan]]: 49,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 50,
                [this.productMapping[this.allProducts.antivirusFree]]: 50,
                [this.productMapping[this.allProducts.antivirusPlus]]: 50,
                [this.productMapping[this.allProducts.avForMac]]: 50,
                [this.productMapping[this.allProducts.bms]]: 50,
                [this.productMapping[this.allProducts.internetSecurity]]: 50,
                [this.productMapping.other]: 50,
                [this.productMapping[this.allProducts.box2]]: 50,
                [this.productMapping[this.allProducts.soho]]: 55,
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 50,
                [this.productMapping[this.allProducts.iosProtection]]: 50,
                [this.productMapping[this.allProducts.vpn]]: 50,
                [this.productMapping[this.allProducts.passManager]]: 50,
                [this.productMapping[this.allProducts.startPlan]]: 50,
                [this.productMapping[this.allProducts.familyPlan]]: 50,
                [this.productMapping[this.allProducts.ultimatePlan]]: 50,
                [this.productMapping[this.allProducts.personalPlan]]: 50,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 49,
                [this.productMapping[this.allProducts.antivirusFree]]: 49,
                [this.productMapping[this.allProducts.antivirusPlus]]: 49,
                [this.productMapping[this.allProducts.avForMac]]: 49,
                [this.productMapping[this.allProducts.bms]]: 49,
                [this.productMapping[this.allProducts.internetSecurity]]: 49,
                [this.productMapping.other]: 49,
                [this.productMapping[this.allProducts.box2]]: 49,
                [this.productMapping[this.allProducts.soho]]: 55,
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 49,
                [this.productMapping[this.allProducts.iosProtection]]: 49,
                [this.productMapping[this.allProducts.vpn]]: 49,
                [this.productMapping[this.allProducts.passManager]]: 49,
                [this.productMapping[this.allProducts.startPlan]]: 49,
                [this.productMapping[this.allProducts.familyPlan]]: 49,
                [this.productMapping[this.allProducts.ultimatePlan]]: 49,
                [this.productMapping[this.allProducts.personalPlan]]: 49,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 42,
                [this.refundMapping.alreadyPurchased.secondValue]: 42,
                [this.refundMapping.competitorPurchase.secondValue]: 49,
                [this.refundMapping.price.secondValue]: 42,
                [this.refundMapping.noUse.secondValue]: 42,
                [this.refundMapping.wrongPurchase.secondValue]: 42,
                [this.refundMapping.notSatisfied.secondValue]: 42,
                [this.refundMapping.technicalIssues.secondValue]: 42,
                [this.refundMapping.cantInstall.secondValue]: 42,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 49,
                [this.productMapping[this.allProducts.antivirusFree]]: 49,
                [this.productMapping[this.allProducts.antivirusPlus]]: 49,
                [this.productMapping[this.allProducts.avForMac]]: 49,
                [this.productMapping[this.allProducts.bms]]: 49,
                [this.productMapping[this.allProducts.internetSecurity]]: 49,
                [this.productMapping.other]: 49,
                [this.productMapping[this.allProducts.box2]]: 49,
                [this.productMapping[this.allProducts.soho]]: 55,
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 49,
                [this.productMapping[this.allProducts.iosProtection]]: 49,
                [this.productMapping[this.allProducts.vpn]]: 49,
                [this.productMapping[this.allProducts.passManager]]: 49,
                [this.productMapping[this.allProducts.startPlan]]: 49,
                [this.productMapping[this.allProducts.familyPlan]]: 49,
                [this.productMapping[this.allProducts.ultimatePlan]]: 49,
                [this.productMapping[this.allProducts.personalPlan]]: 49,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 50,
                [this.productMapping[this.allProducts.antivirusFree]]: 50,
                [this.productMapping[this.allProducts.antivirusPlus]]: 50,
                [this.productMapping[this.allProducts.avForMac]]: 50,
                [this.productMapping[this.allProducts.bms]]: 50,
                [this.productMapping[this.allProducts.internetSecurity]]: 50,
                [this.productMapping.other]: 50,
                [this.productMapping[this.allProducts.box2]]: 50,
                [this.productMapping[this.allProducts.soho]]: 55,
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 50,
                [this.productMapping[this.allProducts.iosProtection]]: 50,
                [this.productMapping[this.allProducts.vpn]]: 50,
                [this.productMapping[this.allProducts.passManager]]: 50,
                [this.productMapping[this.allProducts.startPlan]]: 50,
                [this.productMapping[this.allProducts.familyPlan]]: 50,
                [this.productMapping[this.allProducts.ultimatePlan]]: 50,
                [this.productMapping[this.allProducts.personalPlan]]: 50,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 50,
                [this.productMapping[this.allProducts.antivirusFree]]: 50,
                [this.productMapping[this.allProducts.antivirusPlus]]: 50,
                [this.productMapping[this.allProducts.avForMac]]: 50,
                [this.productMapping[this.allProducts.bms]]: 50,
                [this.productMapping[this.allProducts.internetSecurity]]: 50,
                [this.productMapping.other]: 50,
                [this.productMapping[this.allProducts.box2]]: 50,
                [this.productMapping[this.allProducts.soho]]: 55,
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 50,
                [this.productMapping[this.allProducts.iosProtection]]: 50,
                [this.productMapping[this.allProducts.vpn]]: 50,
                [this.productMapping[this.allProducts.passManager]]: 50,
                [this.productMapping[this.allProducts.startPlan]]: 50,
                [this.productMapping[this.allProducts.familyPlan]]: 50,
                [this.productMapping[this.allProducts.ultimatePlan]]: 50,
                [this.productMapping[this.allProducts.personalPlan]]: 50,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 51,
                [this.productMapping[this.allProducts.antivirusFree]]: 51,
                [this.productMapping[this.allProducts.antivirusPlus]]: 51,
                [this.productMapping[this.allProducts.avForMac]]: 51,
                [this.productMapping[this.allProducts.bms]]: 51,
                [this.productMapping[this.allProducts.internetSecurity]]: 51,
                [this.productMapping.other]: 51,
                [this.productMapping[this.allProducts.box2]]: 51,
                [this.productMapping[this.allProducts.soho]]: 55,
                [this.productMapping[this.allProducts.premiumSecurity]]: 50,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 51,
                [this.productMapping[this.allProducts.iosProtection]]: 51,
                [this.productMapping[this.allProducts.vpn]]: 51,
                [this.productMapping[this.allProducts.passManager]]: 51,
                [this.productMapping[this.allProducts.startPlan]]: 51,
                [this.productMapping[this.allProducts.familyPlan]]: 51,
                [this.productMapping[this.allProducts.ultimatePlan]]: 51,
                [this.productMapping[this.allProducts.personalPlan]]: 51,
            }
        },
        [this.valuesService.languages.fr_FR.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 218,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 218,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 218,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 218,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 218,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 218,
                // Other
                [this.productMapping.other]: 218,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 218,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 226,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 218,
                [this.productMapping[this.allProducts.iosProtection]]: 218,
                [this.productMapping[this.allProducts.vpn]]: 218,
                [this.productMapping[this.allProducts.passManager]]: 218,
                [this.productMapping[this.allProducts.startPlan]]: 218,
                [this.productMapping[this.allProducts.familyPlan]]: 218,
                [this.productMapping[this.allProducts.ultimatePlan]]: 218,
                [this.productMapping[this.allProducts.personalPlan]]: 218,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 216,
                [this.productMapping[this.allProducts.antivirusFree]]: 216,
                [this.productMapping[this.allProducts.antivirusPlus]]: 216,
                [this.productMapping[this.allProducts.avForMac]]: 216,
                [this.productMapping[this.allProducts.bms]]: 216,
                [this.productMapping[this.allProducts.internetSecurity]]: 216,
                [this.productMapping.other]: 216,
                [this.productMapping[this.allProducts.box2]]: 216,
                [this.productMapping[this.allProducts.soho]]: 226,
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 216,
                [this.productMapping[this.allProducts.iosProtection]]: 216,
                [this.productMapping[this.allProducts.vpn]]: 216,
                [this.productMapping[this.allProducts.passManager]]: 216,
                [this.productMapping[this.allProducts.startPlan]]: 216,
                [this.productMapping[this.allProducts.familyPlan]]: 216,
                [this.productMapping[this.allProducts.ultimatePlan]]: 216,
                [this.productMapping[this.allProducts.personalPlan]]: 216,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 218,
                [this.productMapping[this.allProducts.antivirusFree]]: 218,
                [this.productMapping[this.allProducts.antivirusPlus]]: 218,
                [this.productMapping[this.allProducts.avForMac]]: 218,
                [this.productMapping[this.allProducts.bms]]: 218,
                [this.productMapping[this.allProducts.internetSecurity]]: 218,
                [this.productMapping.other]: 218,
                [this.productMapping[this.allProducts.box2]]: 218,
                [this.productMapping[this.allProducts.soho]]: 226,
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 218,
                [this.productMapping[this.allProducts.iosProtection]]: 218,
                [this.productMapping[this.allProducts.vpn]]: 218,
                [this.productMapping[this.allProducts.passManager]]: 218,
                [this.productMapping[this.allProducts.startPlan]]: 218,
                [this.productMapping[this.allProducts.familyPlan]]: 218,
                [this.productMapping[this.allProducts.ultimatePlan]]: 218,
                [this.productMapping[this.allProducts.personalPlan]]: 218,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 222,
                [this.refundMapping.alreadyPurchased.secondValue]: 222,
                [this.refundMapping.competitorPurchase.secondValue]: 218,
                [this.refundMapping.price.secondValue]: 222,
                [this.refundMapping.noUse.secondValue]: 222,
                [this.refundMapping.wrongPurchase.secondValue]: 222,
                [this.refundMapping.notSatisfied.secondValue]: 222,
                [this.refundMapping.technicalIssues.secondValue]: 222,
                [this.refundMapping.cantInstall.secondValue]: 222,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 218,
                [this.productMapping[this.allProducts.antivirusFree]]: 218,
                [this.productMapping[this.allProducts.antivirusPlus]]: 218,
                [this.productMapping[this.allProducts.avForMac]]: 218,
                [this.productMapping[this.allProducts.bms]]: 218,
                [this.productMapping[this.allProducts.internetSecurity]]: 218,
                [this.productMapping.other]: 218,
                [this.productMapping[this.allProducts.box2]]: 218,
                [this.productMapping[this.allProducts.soho]]: 226,
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 218,
                [this.productMapping[this.allProducts.iosProtection]]: 218,
                [this.productMapping[this.allProducts.vpn]]: 218,
                [this.productMapping[this.allProducts.passManager]]: 218,
                [this.productMapping[this.allProducts.startPlan]]: 218,
                [this.productMapping[this.allProducts.familyPlan]]: 218,
                [this.productMapping[this.allProducts.ultimatePlan]]: 218,
                [this.productMapping[this.allProducts.personalPlan]]: 218,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 216,
                [this.productMapping[this.allProducts.antivirusFree]]: 216,
                [this.productMapping[this.allProducts.antivirusPlus]]: 216,
                [this.productMapping[this.allProducts.avForMac]]: 216,
                [this.productMapping[this.allProducts.bms]]: 216,
                [this.productMapping[this.allProducts.internetSecurity]]: 216,
                [this.productMapping.other]: 216,
                [this.productMapping[this.allProducts.box2]]: 216,
                [this.productMapping[this.allProducts.soho]]: 226,
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 216,
                [this.productMapping[this.allProducts.iosProtection]]: 216,
                [this.productMapping[this.allProducts.vpn]]: 216,
                [this.productMapping[this.allProducts.passManager]]: 216,
                [this.productMapping[this.allProducts.startPlan]]: 216,
                [this.productMapping[this.allProducts.familyPlan]]: 216,
                [this.productMapping[this.allProducts.ultimatePlan]]: 216,
                [this.productMapping[this.allProducts.personalPlan]]: 216,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 216,
                [this.productMapping[this.allProducts.antivirusFree]]: 216,
                [this.productMapping[this.allProducts.antivirusPlus]]: 216,
                [this.productMapping[this.allProducts.avForMac]]: 216,
                [this.productMapping[this.allProducts.bms]]: 216,
                [this.productMapping[this.allProducts.internetSecurity]]: 216,
                [this.productMapping.other]: 216,
                [this.productMapping[this.allProducts.box2]]: 216,
                [this.productMapping[this.allProducts.soho]]: 226,
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 216,
                [this.productMapping[this.allProducts.iosProtection]]: 216,
                [this.productMapping[this.allProducts.vpn]]: 216,
                [this.productMapping[this.allProducts.passManager]]: 216,
                [this.productMapping[this.allProducts.startPlan]]: 216,
                [this.productMapping[this.allProducts.familyPlan]]: 216,
                [this.productMapping[this.allProducts.ultimatePlan]]: 216,
                [this.productMapping[this.allProducts.personalPlan]]: 216,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 217,
                [this.productMapping[this.allProducts.antivirusFree]]: 217,
                [this.productMapping[this.allProducts.antivirusPlus]]: 217,
                [this.productMapping[this.allProducts.avForMac]]: 217,
                [this.productMapping[this.allProducts.bms]]: 217,
                [this.productMapping[this.allProducts.internetSecurity]]: 217,
                [this.productMapping.other]: 217,
                [this.productMapping[this.allProducts.box2]]: 217,
                [this.productMapping[this.allProducts.soho]]: 226,
                [this.productMapping[this.allProducts.premiumSecurity]]: 216,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 217,
                [this.productMapping[this.allProducts.iosProtection]]: 217,
                [this.productMapping[this.allProducts.vpn]]: 217,
                [this.productMapping[this.allProducts.passManager]]: 217,
                [this.productMapping[this.allProducts.startPlan]]: 217,
                [this.productMapping[this.allProducts.familyPlan]]: 217,
                [this.productMapping[this.allProducts.ultimatePlan]]: 217,
                [this.productMapping[this.allProducts.personalPlan]]: 217,
            }
        },
        [this.valuesService.languages.it_IT.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 39,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 39,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 39,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 39,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 39,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 39,
                // Other
                [this.productMapping.other]: 39,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 39,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 38,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 39,
                [this.productMapping[this.allProducts.iosProtection]]: 39,
                [this.productMapping[this.allProducts.vpn]]: 39,
                [this.productMapping[this.allProducts.passManager]]: 39,
                [this.productMapping[this.allProducts.startPlan]]: 39,
                [this.productMapping[this.allProducts.familyPlan]]: 39,
                [this.productMapping[this.allProducts.ultimatePlan]]: 39,
                [this.productMapping[this.allProducts.personalPlan]]: 39,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 38,
                [this.productMapping[this.allProducts.antivirusFree]]: 38,
                [this.productMapping[this.allProducts.antivirusPlus]]: 38,
                [this.productMapping[this.allProducts.avForMac]]: 38,
                [this.productMapping[this.allProducts.bms]]: 38,
                [this.productMapping[this.allProducts.internetSecurity]]: 38,
                [this.productMapping.other]: 38,
                [this.productMapping[this.allProducts.box2]]: 38,
                [this.productMapping[this.allProducts.soho]]: 38,
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 38,
                [this.productMapping[this.allProducts.iosProtection]]: 38,
                [this.productMapping[this.allProducts.vpn]]: 38,
                [this.productMapping[this.allProducts.passManager]]: 38,
                [this.productMapping[this.allProducts.startPlan]]: 38,
                [this.productMapping[this.allProducts.familyPlan]]: 38,
                [this.productMapping[this.allProducts.ultimatePlan]]: 38,
                [this.productMapping[this.allProducts.personalPlan]]: 38,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 39,
                [this.productMapping[this.allProducts.antivirusFree]]: 39,
                [this.productMapping[this.allProducts.antivirusPlus]]: 39,
                [this.productMapping[this.allProducts.avForMac]]: 39,
                [this.productMapping[this.allProducts.bms]]: 39,
                [this.productMapping[this.allProducts.internetSecurity]]: 39,
                [this.productMapping.other]: 39,
                [this.productMapping[this.allProducts.box2]]: 39,
                [this.productMapping[this.allProducts.soho]]: 38,
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 39,
                [this.productMapping[this.allProducts.iosProtection]]: 39,
                [this.productMapping[this.allProducts.vpn]]: 39,
                [this.productMapping[this.allProducts.passManager]]: 39,
                [this.productMapping[this.allProducts.startPlan]]: 39,
                [this.productMapping[this.allProducts.familyPlan]]: 39,
                [this.productMapping[this.allProducts.ultimatePlan]]: 39,
                [this.productMapping[this.allProducts.personalPlan]]: 39,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 39,
                [this.refundMapping.alreadyPurchased.secondValue]: 39,
                [this.refundMapping.competitorPurchase.secondValue]: 39,
                [this.refundMapping.price.secondValue]: 39,
                [this.refundMapping.noUse.secondValue]: 39,
                [this.refundMapping.wrongPurchase.secondValue]: 39,
                [this.refundMapping.notSatisfied.secondValue]: 39,
                [this.refundMapping.technicalIssues.secondValue]: 39,
                [this.refundMapping.cantInstall.secondValue]: 39,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 39,
                [this.productMapping[this.allProducts.antivirusFree]]: 39,
                [this.productMapping[this.allProducts.antivirusPlus]]: 39,
                [this.productMapping[this.allProducts.avForMac]]: 39,
                [this.productMapping[this.allProducts.bms]]: 39,
                [this.productMapping[this.allProducts.internetSecurity]]: 39,
                [this.productMapping.other]: 39,
                [this.productMapping[this.allProducts.box2]]: 39,
                [this.productMapping[this.allProducts.soho]]: 38,
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 39,
                [this.productMapping[this.allProducts.iosProtection]]: 39,
                [this.productMapping[this.allProducts.vpn]]: 39,
                [this.productMapping[this.allProducts.passManager]]: 39,
                [this.productMapping[this.allProducts.startPlan]]: 39,
                [this.productMapping[this.allProducts.familyPlan]]: 39,
                [this.productMapping[this.allProducts.ultimatePlan]]: 39,
                [this.productMapping[this.allProducts.personalPlan]]: 39,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 38,
                [this.productMapping[this.allProducts.antivirusFree]]: 38,
                [this.productMapping[this.allProducts.antivirusPlus]]: 38,
                [this.productMapping[this.allProducts.avForMac]]: 38,
                [this.productMapping[this.allProducts.bms]]: 38,
                [this.productMapping[this.allProducts.internetSecurity]]: 38,
                [this.productMapping.other]: 38,
                [this.productMapping[this.allProducts.box2]]: 38,
                [this.productMapping[this.allProducts.soho]]: 38,
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 38,
                [this.productMapping[this.allProducts.iosProtection]]: 38,
                [this.productMapping[this.allProducts.vpn]]: 38,
                [this.productMapping[this.allProducts.passManager]]: 38,
                [this.productMapping[this.allProducts.startPlan]]: 38,
                [this.productMapping[this.allProducts.familyPlan]]: 38,
                [this.productMapping[this.allProducts.ultimatePlan]]: 38,
                [this.productMapping[this.allProducts.personalPlan]]: 38,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 38,
                [this.productMapping[this.allProducts.antivirusFree]]: 38,
                [this.productMapping[this.allProducts.antivirusPlus]]: 38,
                [this.productMapping[this.allProducts.avForMac]]: 38,
                [this.productMapping[this.allProducts.bms]]: 38,
                [this.productMapping[this.allProducts.internetSecurity]]: 38,
                [this.productMapping.other]: 38,
                [this.productMapping[this.allProducts.box2]]: 38,
                [this.productMapping[this.allProducts.soho]]: 38,
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 38,
                [this.productMapping[this.allProducts.iosProtection]]: 38,
                [this.productMapping[this.allProducts.vpn]]: 38,
                [this.productMapping[this.allProducts.passManager]]: 38,
                [this.productMapping[this.allProducts.startPlan]]: 38,
                [this.productMapping[this.allProducts.familyPlan]]: 38,
                [this.productMapping[this.allProducts.ultimatePlan]]: 38,
                [this.productMapping[this.allProducts.personalPlan]]: 38,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 37,
                [this.productMapping[this.allProducts.antivirusFree]]: 37,
                [this.productMapping[this.allProducts.antivirusPlus]]: 37,
                [this.productMapping[this.allProducts.avForMac]]: 37,
                [this.productMapping[this.allProducts.bms]]: 37,
                [this.productMapping[this.allProducts.internetSecurity]]: 37,
                [this.productMapping.other]: 37,
                [this.productMapping[this.allProducts.box2]]: 37,
                [this.productMapping[this.allProducts.soho]]: 38,
                [this.productMapping[this.allProducts.premiumSecurity]]: 38,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 37,
                [this.productMapping[this.allProducts.iosProtection]]: 37,
                [this.productMapping[this.allProducts.vpn]]: 37,
                [this.productMapping[this.allProducts.passManager]]: 37,
                [this.productMapping[this.allProducts.startPlan]]: 37,
                [this.productMapping[this.allProducts.familyPlan]]: 37,
                [this.productMapping[this.allProducts.ultimatePlan]]: 37,
                [this.productMapping[this.allProducts.personalPlan]]: 37,
            }
        },
        [this.valuesService.languages.nl_NL.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 191,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 191,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 191,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 191,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 191,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 191,
                // Other
                [this.productMapping.other]: 191,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 191,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 192,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 191,
                [this.productMapping[this.allProducts.iosProtection]]: 191,
                [this.productMapping[this.allProducts.vpn]]: 191,
                [this.productMapping[this.allProducts.passManager]]: 191,
                [this.productMapping[this.allProducts.startPlan]]: 191,
                [this.productMapping[this.allProducts.familyPlan]]: 191,
                [this.productMapping[this.allProducts.ultimatePlan]]: 191,
                [this.productMapping[this.allProducts.personalPlan]]: 191,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 192,
                [this.productMapping[this.allProducts.antivirusFree]]: 192,
                [this.productMapping[this.allProducts.antivirusPlus]]: 192,
                [this.productMapping[this.allProducts.avForMac]]: 192,
                [this.productMapping[this.allProducts.bms]]: 192,
                [this.productMapping[this.allProducts.internetSecurity]]: 192,
                [this.productMapping.other]: 192,
                [this.productMapping[this.allProducts.box2]]: 192,
                [this.productMapping[this.allProducts.soho]]: 192,
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 192,
                [this.productMapping[this.allProducts.iosProtection]]: 192,
                [this.productMapping[this.allProducts.vpn]]: 192,
                [this.productMapping[this.allProducts.passManager]]: 192,
                [this.productMapping[this.allProducts.startPlan]]: 192,
                [this.productMapping[this.allProducts.familyPlan]]: 192,
                [this.productMapping[this.allProducts.ultimatePlan]]: 192,
                [this.productMapping[this.allProducts.personalPlan]]: 192,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 191,
                [this.productMapping[this.allProducts.antivirusFree]]: 191,
                [this.productMapping[this.allProducts.antivirusPlus]]: 191,
                [this.productMapping[this.allProducts.avForMac]]: 191,
                [this.productMapping[this.allProducts.bms]]: 191,
                [this.productMapping[this.allProducts.internetSecurity]]: 191,
                [this.productMapping.other]: 191,
                [this.productMapping[this.allProducts.box2]]: 191,
                [this.productMapping[this.allProducts.soho]]: 192,
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 191,
                [this.productMapping[this.allProducts.iosProtection]]: 191,
                [this.productMapping[this.allProducts.vpn]]: 191,
                [this.productMapping[this.allProducts.passManager]]: 191,
                [this.productMapping[this.allProducts.startPlan]]: 191,
                [this.productMapping[this.allProducts.familyPlan]]: 191,
                [this.productMapping[this.allProducts.ultimatePlan]]: 191,
                [this.productMapping[this.allProducts.personalPlan]]: 191,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 191,
                [this.refundMapping.alreadyPurchased.secondValue]: 191,
                [this.refundMapping.competitorPurchase.secondValue]: 191,
                [this.refundMapping.price.secondValue]: 191,
                [this.refundMapping.noUse.secondValue]: 191,
                [this.refundMapping.wrongPurchase.secondValue]: 191,
                [this.refundMapping.notSatisfied.secondValue]: 191,
                [this.refundMapping.technicalIssues.secondValue]: 191,
                [this.refundMapping.cantInstall.secondValue]: 191,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 191,
                [this.productMapping[this.allProducts.antivirusFree]]: 191,
                [this.productMapping[this.allProducts.antivirusPlus]]: 191,
                [this.productMapping[this.allProducts.avForMac]]: 191,
                [this.productMapping[this.allProducts.bms]]: 191,
                [this.productMapping[this.allProducts.internetSecurity]]: 191,
                [this.productMapping.other]: 191,
                [this.productMapping[this.allProducts.box2]]: 191,
                [this.productMapping[this.allProducts.soho]]: 192,
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 191,
                [this.productMapping[this.allProducts.iosProtection]]: 191,
                [this.productMapping[this.allProducts.vpn]]: 191,
                [this.productMapping[this.allProducts.passManager]]: 191,
                [this.productMapping[this.allProducts.startPlan]]: 191,
                [this.productMapping[this.allProducts.familyPlan]]: 191,
                [this.productMapping[this.allProducts.ultimatePlan]]: 191,
                [this.productMapping[this.allProducts.personalPlan]]: 191,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 192,
                [this.productMapping[this.allProducts.antivirusFree]]: 192,
                [this.productMapping[this.allProducts.antivirusPlus]]: 192,
                [this.productMapping[this.allProducts.avForMac]]: 192,
                [this.productMapping[this.allProducts.bms]]: 192,
                [this.productMapping[this.allProducts.internetSecurity]]: 192,
                [this.productMapping.other]: 192,
                [this.productMapping[this.allProducts.box2]]: 192,
                [this.productMapping[this.allProducts.soho]]: 192,
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 192,
                [this.productMapping[this.allProducts.iosProtection]]: 192,
                [this.productMapping[this.allProducts.vpn]]: 192,
                [this.productMapping[this.allProducts.passManager]]: 192,
                [this.productMapping[this.allProducts.startPlan]]: 192,
                [this.productMapping[this.allProducts.familyPlan]]: 192,
                [this.productMapping[this.allProducts.ultimatePlan]]: 192,
                [this.productMapping[this.allProducts.personalPlan]]: 192,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 192,
                [this.productMapping[this.allProducts.antivirusFree]]: 192,
                [this.productMapping[this.allProducts.antivirusPlus]]: 192,
                [this.productMapping[this.allProducts.avForMac]]: 192,
                [this.productMapping[this.allProducts.bms]]: 192,
                [this.productMapping[this.allProducts.internetSecurity]]: 192,
                [this.productMapping.other]: 192,
                [this.productMapping[this.allProducts.box2]]: 192,
                [this.productMapping[this.allProducts.soho]]: 192,
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 192,
                [this.productMapping[this.allProducts.iosProtection]]: 192,
                [this.productMapping[this.allProducts.vpn]]: 192,
                [this.productMapping[this.allProducts.passManager]]: 192,
                [this.productMapping[this.allProducts.startPlan]]: 192,
                [this.productMapping[this.allProducts.familyPlan]]: 192,
                [this.productMapping[this.allProducts.ultimatePlan]]: 192,
                [this.productMapping[this.allProducts.personalPlan]]: 192,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 193,
                [this.productMapping[this.allProducts.antivirusFree]]: 193,
                [this.productMapping[this.allProducts.antivirusPlus]]: 193,
                [this.productMapping[this.allProducts.avForMac]]: 193,
                [this.productMapping[this.allProducts.bms]]: 193,
                [this.productMapping[this.allProducts.internetSecurity]]: 193,
                [this.productMapping.other]: 193,
                [this.productMapping[this.allProducts.box2]]: 193,
                [this.productMapping[this.allProducts.soho]]: 192,
                [this.productMapping[this.allProducts.premiumSecurity]]: 192,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 193,
                [this.productMapping[this.allProducts.iosProtection]]: 193,
                [this.productMapping[this.allProducts.vpn]]: 193,
                [this.productMapping[this.allProducts.passManager]]: 193,
                [this.productMapping[this.allProducts.startPlan]]: 193,
                [this.productMapping[this.allProducts.familyPlan]]: 193,
                [this.productMapping[this.allProducts.ultimatePlan]]: 193,
                [this.productMapping[this.allProducts.personalPlan]]: 193,
            }
        },
        [this.valuesService.languages.pt_BR.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 101,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 101,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 101,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 101,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 101,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 101,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 103,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 101,
                // Other
                [this.productMapping.other]: 101,

                [this.productMapping[this.allProducts.familyPack]]: 101,
                [this.productMapping[this.allProducts.iosProtection]]: 101,
                [this.productMapping[this.allProducts.vpn]]: 101,
                [this.productMapping[this.allProducts.passManager]]: 101,
                [this.productMapping[this.allProducts.startPlan]]: 101,
                [this.productMapping[this.allProducts.familyPlan]]: 101,
                [this.productMapping[this.allProducts.ultimatePlan]]: 101,
                [this.productMapping[this.allProducts.personalPlan]]: 101,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 103,
                [this.productMapping[this.allProducts.antivirusFree]]: 103,
                [this.productMapping[this.allProducts.antivirusPlus]]: 103,
                [this.productMapping[this.allProducts.avForMac]]: 103,
                [this.productMapping[this.allProducts.bms]]: 103,
                [this.productMapping[this.allProducts.internetSecurity]]: 103,
                [this.productMapping.other]: 103,
                [this.productMapping[this.allProducts.box2]]: 103,
                [this.productMapping[this.allProducts.soho]]: 103,
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 103,
                [this.productMapping[this.allProducts.iosProtection]]: 103,
                [this.productMapping[this.allProducts.vpn]]: 103,
                [this.productMapping[this.allProducts.passManager]]: 103,
                [this.productMapping[this.allProducts.startPlan]]: 103,
                [this.productMapping[this.allProducts.familyPlan]]: 103,
                [this.productMapping[this.allProducts.ultimatePlan]]: 103,
                [this.productMapping[this.allProducts.personalPlan]]: 103,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 101,
                [this.productMapping[this.allProducts.antivirusFree]]: 101,
                [this.productMapping[this.allProducts.antivirusPlus]]: 101,
                [this.productMapping[this.allProducts.avForMac]]: 101,
                [this.productMapping[this.allProducts.bms]]: 101,
                [this.productMapping[this.allProducts.internetSecurity]]: 101,
                [this.productMapping.other]: 101,
                [this.productMapping[this.allProducts.box2]]: 101,
                [this.productMapping[this.allProducts.soho]]: 103,
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 101,
                [this.productMapping[this.allProducts.iosProtection]]: 101,
                [this.productMapping[this.allProducts.vpn]]: 101,
                [this.productMapping[this.allProducts.passManager]]: 101,
                [this.productMapping[this.allProducts.startPlan]]: 101,
                [this.productMapping[this.allProducts.familyPlan]]: 101,
                [this.productMapping[this.allProducts.ultimatePlan]]: 101,
                [this.productMapping[this.allProducts.personalPlan]]: 101,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 101,
                [this.refundMapping.alreadyPurchased.secondValue]: 101,
                [this.refundMapping.competitorPurchase.secondValue]: 101,
                [this.refundMapping.price.secondValue]: 101,
                [this.refundMapping.noUse.secondValue]: 101,
                [this.refundMapping.wrongPurchase.secondValue]: 101,
                [this.refundMapping.notSatisfied.secondValue]: 101,
                [this.refundMapping.technicalIssues.secondValue]: 101,
                [this.refundMapping.cantInstall.secondValue]: 101,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 101,
                [this.productMapping[this.allProducts.antivirusFree]]: 101,
                [this.productMapping[this.allProducts.antivirusPlus]]: 101,
                [this.productMapping[this.allProducts.avForMac]]: 101,
                [this.productMapping[this.allProducts.bms]]: 101,
                [this.productMapping[this.allProducts.internetSecurity]]: 101,
                [this.productMapping.other]: 101,
                [this.productMapping[this.allProducts.box2]]: 101,
                [this.productMapping[this.allProducts.soho]]: 103,
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 101,
                [this.productMapping[this.allProducts.iosProtection]]: 101,
                [this.productMapping[this.allProducts.vpn]]: 101,
                [this.productMapping[this.allProducts.passManager]]: 101,
                [this.productMapping[this.allProducts.startPlan]]: 101,
                [this.productMapping[this.allProducts.familyPlan]]: 101,
                [this.productMapping[this.allProducts.ultimatePlan]]: 101,
                [this.productMapping[this.allProducts.personalPlan]]: 101,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 103,
                [this.productMapping[this.allProducts.antivirusFree]]: 103,
                [this.productMapping[this.allProducts.antivirusPlus]]: 103,
                [this.productMapping[this.allProducts.avForMac]]: 103,
                [this.productMapping[this.allProducts.bms]]: 103,
                [this.productMapping[this.allProducts.internetSecurity]]: 103,
                [this.productMapping.other]: 103,
                [this.productMapping[this.allProducts.box2]]: 103,
                [this.productMapping[this.allProducts.soho]]: 103,
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 103,
                [this.productMapping[this.allProducts.iosProtection]]: 103,
                [this.productMapping[this.allProducts.vpn]]: 103,
                [this.productMapping[this.allProducts.passManager]]: 103,
                [this.productMapping[this.allProducts.startPlan]]: 103,
                [this.productMapping[this.allProducts.familyPlan]]: 103,
                [this.productMapping[this.allProducts.ultimatePlan]]: 103,
                [this.productMapping[this.allProducts.personalPlan]]: 103,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 103,
                [this.productMapping[this.allProducts.antivirusFree]]: 103,
                [this.productMapping[this.allProducts.antivirusPlus]]: 103,
                [this.productMapping[this.allProducts.avForMac]]: 103,
                [this.productMapping[this.allProducts.bms]]: 103,
                [this.productMapping[this.allProducts.internetSecurity]]: 103,
                [this.productMapping.other]: 103,
                [this.productMapping[this.allProducts.box2]]: 103,
                [this.productMapping[this.allProducts.soho]]: 103,
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 103,
                [this.productMapping[this.allProducts.iosProtection]]: 103,
                [this.productMapping[this.allProducts.vpn]]: 103,
                [this.productMapping[this.allProducts.passManager]]: 103,
                [this.productMapping[this.allProducts.startPlan]]: 103,
                [this.productMapping[this.allProducts.familyPlan]]: 103,
                [this.productMapping[this.allProducts.ultimatePlan]]: 103,
                [this.productMapping[this.allProducts.personalPlan]]: 103,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 102,
                [this.productMapping[this.allProducts.antivirusFree]]: 102,
                [this.productMapping[this.allProducts.antivirusPlus]]: 102,
                [this.productMapping[this.allProducts.avForMac]]: 102,
                [this.productMapping[this.allProducts.bms]]: 102,
                [this.productMapping[this.allProducts.internetSecurity]]: 102,
                [this.productMapping.other]: 102,
                [this.productMapping[this.allProducts.box2]]: 102,
                [this.productMapping[this.allProducts.soho]]: 103,
                [this.productMapping[this.allProducts.premiumSecurity]]: 103,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 102,
                [this.productMapping[this.allProducts.iosProtection]]: 102,
                [this.productMapping[this.allProducts.vpn]]: 102,
                [this.productMapping[this.allProducts.passManager]]: 102,
                [this.productMapping[this.allProducts.startPlan]]: 102,
                [this.productMapping[this.allProducts.familyPlan]]: 102,
                [this.productMapping[this.allProducts.ultimatePlan]]: 102,
                [this.productMapping[this.allProducts.personalPlan]]: 102,
            }
        },
        [this.valuesService.languages.ro_RO.id]: {
            // Activation
            [this.issuesMapping.activation]:{
                // Total Security
                [this.productMapping[this.allProducts.totalSecurity]]: 89,
                // Internet Security
                [this.productMapping[this.allProducts.internetSecurity]]: 89,
                // Antivirus Free
                [this.productMapping[this.allProducts.antivirusFree]]: 89,
                // Antivirus Plus
                [this.productMapping[this.allProducts.antivirusPlus]]: 89,
                // Antivirus for Mac
                [this.productMapping[this.allProducts.avForMac]]: 89,
                // BMS
                [this.productMapping[this.allProducts.bms]]: 89,
                // Other
                [this.productMapping.other]: 89,
                // Nimeni nu stie
                [this.productMapping[this.allProducts.box2]]: 89,
                // SOHO
                [this.productMapping[this.allProducts.soho]]: 90,
                // Premium Security
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 89,
                [this.productMapping[this.allProducts.iosProtection]]: 89,
                [this.productMapping[this.allProducts.vpn]]: 89,
                [this.productMapping[this.allProducts.passManager]]: 89,
                [this.productMapping[this.allProducts.startPlan]]: 89,
                [this.productMapping[this.allProducts.familyPlan]]: 89,
                [this.productMapping[this.allProducts.ultimatePlan]]: 89,
                [this.productMapping[this.allProducts.personalPlan]]: 89,
            },
            // Installation
            [this.issuesMapping.installation]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 90,
                [this.productMapping[this.allProducts.antivirusFree]]: 90,
                [this.productMapping[this.allProducts.antivirusPlus]]: 90,
                [this.productMapping[this.allProducts.avForMac]]: 90,
                [this.productMapping[this.allProducts.bms]]: 90,
                [this.productMapping[this.allProducts.internetSecurity]]: 90,
                [this.productMapping.other]: 90,
                [this.productMapping[this.allProducts.box2]]: 90,
                [this.productMapping[this.allProducts.soho]]: 90,
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 90,
                [this.productMapping[this.allProducts.iosProtection]]: 90,
                [this.productMapping[this.allProducts.vpn]]: 90,
                [this.productMapping[this.allProducts.passManager]]: 90,
                [this.productMapping[this.allProducts.startPlan]]: 90,
                [this.productMapping[this.allProducts.familyPlan]]: 90,
                [this.productMapping[this.allProducts.ultimatePlan]]: 90,
                [this.productMapping[this.allProducts.personalPlan]]: 90,
            },
            // Manage auto-renewal
            [this.issuesMapping.autoRenewal]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 89,
                [this.productMapping[this.allProducts.antivirusFree]]: 89,
                [this.productMapping[this.allProducts.antivirusPlus]]: 89,
                [this.productMapping[this.allProducts.avForMac]]: 89,
                [this.productMapping[this.allProducts.bms]]: 89,
                [this.productMapping[this.allProducts.internetSecurity]]: 89,
                [this.productMapping.other]: 89,
                [this.productMapping[this.allProducts.box2]]: 89,
                [this.productMapping[this.allProducts.soho]]: 90,
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 89,
                [this.productMapping[this.allProducts.iosProtection]]: 89,
                [this.productMapping[this.allProducts.vpn]]: 89,
                [this.productMapping[this.allProducts.passManager]]: 89,
                [this.productMapping[this.allProducts.startPlan]]: 89,
                [this.productMapping[this.allProducts.familyPlan]]: 89,
                [this.productMapping[this.allProducts.ultimatePlan]]: 89,
                [this.productMapping[this.allProducts.personalPlan]]: 89,
            },
            //! Refund (another level)
            [this.issuesMapping.refund]:{
                [this.refundMapping.renewal.secondValue]: 94,
                [this.refundMapping.alreadyPurchased.secondValue]: 94,
                [this.refundMapping.competitorPurchase.secondValue]: 89,
                [this.refundMapping.price.secondValue]: 94,
                [this.refundMapping.noUse.secondValue]: 94,
                [this.refundMapping.wrongPurchase.secondValue]: 94,
                [this.refundMapping.notSatisfied.secondValue]: 94,
                [this.refundMapping.technicalIssues.secondValue]: 94,
                [this.refundMapping.cantInstall.secondValue]: 94,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67
            },
            // Sales Inquires
            [this.issuesMapping.salesInquires]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 89,
                [this.productMapping[this.allProducts.antivirusFree]]: 89,
                [this.productMapping[this.allProducts.antivirusPlus]]: 89,
                [this.productMapping[this.allProducts.avForMac]]: 89,
                [this.productMapping[this.allProducts.bms]]: 89,
                [this.productMapping[this.allProducts.internetSecurity]]: 89,
                [this.productMapping.other]: 89,
                [this.productMapping[this.allProducts.box2]]: 89,
                [this.productMapping[this.allProducts.soho]]: 90,
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 89,
                [this.productMapping[this.allProducts.iosProtection]]: 89,
                [this.productMapping[this.allProducts.vpn]]: 89,
                [this.productMapping[this.allProducts.passManager]]: 89,
                [this.productMapping[this.allProducts.startPlan]]: 89,
                [this.productMapping[this.allProducts.familyPlan]]: 89,
                [this.productMapping[this.allProducts.ultimatePlan]]: 89,
                [this.productMapping[this.allProducts.personalPlan]]: 89,
            },
            // Product Issues
            [this.issuesMapping.productIssues]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 90,
                [this.productMapping[this.allProducts.antivirusFree]]: 90,
                [this.productMapping[this.allProducts.antivirusPlus]]: 90,
                [this.productMapping[this.allProducts.avForMac]]: 90,
                [this.productMapping[this.allProducts.bms]]: 90,
                [this.productMapping[this.allProducts.internetSecurity]]: 90,
                [this.productMapping.other]: 90,
                [this.productMapping[this.allProducts.box2]]: 90,
                [this.productMapping[this.allProducts.soho]]: 90,
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 90,
                [this.productMapping[this.allProducts.iosProtection]]: 90,
                [this.productMapping[this.allProducts.vpn]]: 90,
                [this.productMapping[this.allProducts.passManager]]: 90,
                [this.productMapping[this.allProducts.startPlan]]: 90,
                [this.productMapping[this.allProducts.familyPlan]]: 90,
                [this.productMapping[this.allProducts.ultimatePlan]]: 90,
                [this.productMapping[this.allProducts.personalPlan]]: 90,
            },
            // Product configuration
            [this.issuesMapping.productConfiguration]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 90,
                [this.productMapping[this.allProducts.antivirusFree]]: 90,
                [this.productMapping[this.allProducts.antivirusPlus]]: 90,
                [this.productMapping[this.allProducts.avForMac]]: 90,
                [this.productMapping[this.allProducts.bms]]: 90,
                [this.productMapping[this.allProducts.internetSecurity]]: 90,
                [this.productMapping.other]: 90,
                [this.productMapping[this.allProducts.box2]]: 90,
                [this.productMapping[this.allProducts.soho]]: 90,
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 90,
                [this.productMapping[this.allProducts.iosProtection]]: 90,
                [this.productMapping[this.allProducts.vpn]]: 90,
                [this.productMapping[this.allProducts.passManager]]: 90,
                [this.productMapping[this.allProducts.startPlan]]: 90,
                [this.productMapping[this.allProducts.familyPlan]]: 90,
                [this.productMapping[this.allProducts.ultimatePlan]]: 90,
                [this.productMapping[this.allProducts.personalPlan]]: 90,
            },
            // Threat remediation
            [this.issuesMapping.threatRemediaion]:{
                [this.productMapping[this.allProducts.totalSecurity]]: 91,
                [this.productMapping[this.allProducts.antivirusFree]]: 91,
                [this.productMapping[this.allProducts.antivirusPlus]]: 91,
                [this.productMapping[this.allProducts.avForMac]]: 91,
                [this.productMapping[this.allProducts.bms]]: 91,
                [this.productMapping[this.allProducts.internetSecurity]]: 91,
                [this.productMapping.other]: 91,
                [this.productMapping[this.allProducts.box2]]: 91,
                [this.productMapping[this.allProducts.soho]]: 90,
                [this.productMapping[this.allProducts.premiumSecurity]]: 90,
                [this.productMapping[this.allProducts.dataPrivacy]]: 67,

                [this.productMapping[this.allProducts.familyPack]]: 91,
                [this.productMapping[this.allProducts.iosProtection]]: 91,
                [this.productMapping[this.allProducts.vpn]]: 91,
                [this.productMapping[this.allProducts.passManager]]: 91,
                [this.productMapping[this.allProducts.startPlan]]: 91,
                [this.productMapping[this.allProducts.familyPlan]]: 91,
                [this.productMapping[this.allProducts.ultimatePlan]]: 91,
                [this.productMapping[this.allProducts.personalPlan]]: 91,
            }
        }
    };

}
