import { EventItemInterface } from '../../../pages/ato/activity/ato-event/ato-threat-events-flow/AtoThreatEventsFlow.model';
import { OperatingSystems } from '../../values/values.service';

export interface AtoEventModel {
    _id: string;
    event_target_id: string;
    event_type: EventTypes;
    identity_id: string;
    created_at: Date;
    event_metadata: any; //! nu stiu ce e aici
    resolved?: boolean;
    updated_at: Date;
    event_group: EventGroups;
    event_level: EventLevels;
    event_category: EventCategories;
    useful?: boolean;
    status?: EventStatus;
    viewed?: boolean;

    _computedClass: EventClass[];
    _computedLayout: EventInterfaceLayouts;
    _computedDate: string;
    _computedTime: string;
    _computedChannelName: string;
    _computedChannelHandle: string;
    _computedChannelId: string;
    _computedChannelUrl: string;
    _computedEventMediaUrl: string;
    _computedEventMetadataMainValue: string;
    _computedEventMetadataSecondaryValue: string;
    _computedVisibility: boolean;
    _computedThreatCategory?: ThreatEventCategory;
    _computedThreatSubcategory?: ThreatEventSubcategory;
    _computedThreatCategoryInterface?: CategoryInterface;
    _computedThreatType: EventThreatType;
    _computedThreatSubtype: EventThreatSubtype;
    _computedThreatDetails?: any[];
}

export enum EventClass {
    ACCOUNT_HEALT = 'accountHealt',
    DEVICE_PROTECTION = 'deviceProtection',
    SCAM_GUARD = 'scamGuard',
    TEAM_SHIELD = 'teamShield',
    GUIDED_RECOVERY = 'guidedRecovery',
    PREVENTIVE_ACTIONS = 'preventiveActions'
};

export enum EventInterfaceLayouts {
    MONITORED_VIDEOS = 'monitoredVideos',
    CONNECTED_ACCOUNT = 'connectedAccount',
    NORMAL = 'normal',
    MEDIA_PREVIEW = 'mediaPreview',
    RECOMMENDATIONS = 'recommendations',
    INBOX_SCAN = 'inbox_scan',
    THREATS = 'threats'
};

export enum EventGroups {
    PINNED = 'pinned',
    TIMELINE = 'timeline'
}

export enum EventLevels {
    CRITICAL = 'critical',
    THREAT = 'threat',
    RECOMENDATION = 'recommendation',
    STATS = 'stats'
}

export enum EventCategories {
    ACCOUNT_MONITORING = 'account_monitoring',
    DEVICE_PROTECTION = 'device_protection',
    SCAM_PROTECTION = 'scam_protection',
    TEAM_PROTECTION = 'team_protection',
    RECOVERY = 'recovery'
}

export enum EventTargets {
    SERVICE = 'service',
    DEVICE = 'device',
    WEBMAIL = 'webmail'
}

export enum EventStatus {
    DONE = 'done',
    POSTPONE = 'postpone',
    FALSE_POSITIVE = 'false_positive'
}

export enum ThreatEventCategory {
    FILE = 'file',
    APP = 'app',
    URL = 'url',
    BEHAVIOUR = 'behaviour',
    MOBILE = 'mobile',
    HOST = 'host'
}

export enum ThreatEventSubcategory {
    DEFAULT = 'default',
    WITH_RCA = 'withRca'
}

export enum EventTypes {
    SERVICE_ACCOUNT_CONNECTED = 'service_account_connected',
    ACCOUNT_TAKEOVER = 'account_takeover',
    ACTIVATE_WEBMAIL_PROTECTION = 'activate_webmailprotection',
    WEBMAIL_PROTECTION_ACTIVATED = 'webmailprotection_activated',
    WEBMAIL_PROTECTION_DISCONNECTED = 'webmailprotection_disconnected',
    WEBMAIL_PROTECTION_INBOX_SCAN = "webmailprotection_inboxscan",
    FINISH_TS_INSTALLATION = 'finish_ts_installation',
    DISCONNECTED_API = 'disconnected_api',
    ACCOUNT_RESTORED = 'account_restored',
    MONITORING_STARTED = 'monitoring_started',
    NEW_VIDEO = 'new_video',
    MONITORED_VIDEOS_NUM = 'monitored_videos_num',
    NEW_BANNER = 'new_banner',
    NEW_PROFILE_PICTURE = 'new_profile_picture',
    NEW_CHANNEL_DESCRIPTION = 'new_channel_description',
    NEW_PLAYLIST = 'new_playlist',
    VIDEO_DELETED = 'video_deleted',
    PLAYLIST_DELETED = 'playlist_deleted',
    REVIEW_AUTHORIZED_MEMBERS = 'review_authorized_members',
    REVIEW_3RD_PARTY_APPS = 'review_3rd_party_apps',
    REVIEW_RECOVERY_OPTIONS = 'review_recovery_options',
    REGULARLY_CHANGE_PASSWORD = 'regularly_change_password',
    REVIEW_YOUR_DEVICES = 'review_your_devices',
    ADD_SMS_METHOD_OF_CONTACT = 'add_sms_method_of_contact',
    SERVICE_ACCOUNT_UPDATED ='service_account_updated',
    SUCCESSFULLY_INSTALLED_TS_ON_DEVICE = 'successfully_installed_ts_on_device',
    INSTALL_TS_ON_ANOTHER_DEVICE = 'install_ts_on_another_device',
    DEVICE_RISK_SHIELD_OFF = 'device_risk_shield_off',
    DEVICE_RISK_WEB_ATTACK_PREVENTION_OFF = 'device_risk_web_attack_prevention_off',
    DEVICE_RISK_ADT_OFF = 'device_risk_adt_off',
    SECURITY_CENTER_THREAT_EVENT = 'security_center_threat_event',
    MALICIOUS_FILE_DETECTED = 'malicious_file_detected',
    TEAM_INVITE = 'invite_team_members'
}

export interface EventTypeIconInterface {
    type: string;
    icon: string;
    bgColor?: string;
    color?: string;
}

export interface EventTypeInterface {
    icon?: EventTypeIconInterface;
    title?: string;
    desc?: string;
    one?: EventTypeInterface
    actionButton?: {
        text?: string,
        link?: string
    };
}

export type EventsTypeInterface = {
    [key in EventTypes]? : {
        active: EventTypeInterface,
        recomendation?: EventTypeInterface,
        fixed?: EventTypeInterface
    }
}

export interface EventClassInterface {
    type: string;
    icon: string
    label: string;
}

export type EventsClassInterface = {
    [key in EventClass]? : EventClassInterface
}

export type EventInfoModel = {
    [key in EventTypes]? : {
        event_type: EventTypes,
        event_group: EventGroups,
        event_level: EventLevels,
        event_category: EventCategories,
        event_target?: EventTargets,
        skip_insert?: boolean
    }
}

export interface ExplainableThreatInterface {
    event_id: string;
    priority: number;
    group_id: string;
    group_name: string;
    name: string;
    description: string;
}

export enum AccountTakeoverEventSteps {
    VIDEOS_DELETED = 'percent_videos_deleted',
    PLAYLISTS_DELETED = 'percent_playlists_deleted',
    HANDLE_CHANGED = 'handle_changed',
    AVATAR_CHANGED = 'avatar_changed',
    BANNER_CHANNED = 'banner_changed',
    MALICIOUS_AVATAR = 'malicious_avatar',
    MALICIOUS_VIDEO_THUMBNAIL = 'malicious_video_thumbnail',
    MALICIOUS_VIDEO_TITLE = 'malicious_video_title',
    MALICIOUS_VIDEO_DESC = 'malicious_video_description',
    MALICIOUS_CHANNEL_NAME = 'malicious_channel_name',
    MALICIOUS_BANNER = 'malicious_banner',
    DESCRIPTION_REMOVED = 'description_removed',
    DESCRIPTION_CHANGED = 'description_changed',
    NAME_CHANGED = 'name_changed'
}

export enum BlockedState {
    BLOCKED = 1,
    NOT_BLOCKED = 0
}

export enum ObjectType {
    FILE = 'file',
    APP = 'app',
    BEHAVIOUR = 'behaviour',
    URL = 'url',
    HOST = 'host'
}

export enum EventThreatType {
    MALWARE = 'malware',
    FRAUD = 'fraud',
    TROJAN = 'trojan',
    ATTACK = 'attack',
    RANSOMWARE = 'ransomware',
    EXPLOIT = 'exploit',
    VIRUS = 'virus',
    PHISHING = 'phishing',
    UNTRUSTED = 'untrusted',
    SPAM = 'spam',
    MINER = 'miner',
    MINER_SERVER = 'miner-server',
    PUA = 'pua',
    CERTIFICATE = 'certificate',
    PRIVACY = 'privacy',
    BOT = 'bot',
    SAV_CRYPTO = 'sav_crypto',
    MALICIOUS_HOST = 'malicious_host',
    DEFAULT = 'default'
}

export enum EventThreatSubtype {
    CMD = 'cmd',
    PROCESS_MEMORY_SCAN = 'process_memory_scan',
    SCANNING = 'scanning',
    GENERIC = 'generic',
    BRUTEFORCE = 'bruteforce',
    BRUTEFORCE_HTTP = 'bruteforce_http',
    EXPLOIT = 'exploit',
    CERTIFICATE_INVALID_SIGNATURE = 'certificate_invalid_signature',
    CERTIFICATE_REVOKED = 'certificate_revoked',
    CERTIFICATE_EXPIRED = 'certificate_expired',
    CERTIFICATE_WRONG_HOST = 'certificate_wrong_host',
    UNTRUSTED_ROOT = 'untrusted_root',
    DATA = 'data',
    BANKING = 'banking',
    PASSWORD = 'password',
    LOGIN = 'login',
    LFI = 'lfi',
    MALWARE = 'malware',
    FRAUD = 'fraud',
    PHISHING = 'phishing',
    MINER = 'miner',
    PUA = 'pua',
    MALVERTISING = 'malvertising',
    SPAM = 'spam',
    UNTRUSTED = 'untrusted',
    DEFAULT = 'default',
}

export interface TheatModel {
    blocked: BlockedState;
    object: string;
    object_type: ObjectType;
    rca_id: string;
    threat: string;
    threat_subtype: EventThreatSubtype;
    threat_type: EventThreatType;
    timestamp: Date;
}

export interface RcaEventReportModel extends TheatModel {
    detection_module: string;
    report_id: string;
}

export interface RcaEventDataModel {
    name: string;
    signed: boolean;
    signed_by: string;
}

export interface RcaTimelineActionModel {
    child: RcaEventDataModel;
    parent: RcaEventDataModel;
    timestamp: Date;
    type: string;
}

export interface RcaTimelineModel {
    events: RcaEventModel[];
    priority_attack: string;
}

export interface RcaEventModel {
    threat_type: EventThreatType;
    threat: TheatModel;
    rca_id: string;
    display_name: string;
    device_os: OperatingSystems;
    device_type: string;
    rca_event: {
        report: RcaEventReportModel;
        timeline: RcaTimelineActionModel[];
    }
}

export interface CategoryInterface {
    bgColor?: string;
    texts?: {
        [key in EventThreatType]?: {
            [key in EventThreatSubtype]?: {
                firstNodeTitle?: string;
                title: string;
                desc: string;
                avertedOutcome: string;
            }
        }
    }
}

export type ThreatEventCategoryInterface =  {
    [key in ThreatEventCategory]?: CategoryInterface;
}

export type ThreatEventsInterface = {
    [key in ThreatEventCategory]?: {
        default?: {
            firstNode: EventItemInterface,
            itemNode: EventItemInterface,
            lastGoodNode: EventItemInterface,
            lastBadNode: EventItemInterface
        },
        withRca?: {
            firstNode: EventItemInterface,
            itemNode: EventItemInterface,
            lastGoodNode: EventItemInterface,
            lastBadNode: EventItemInterface
        }
    }
}

export enum ExplainableThreatsCodes {
    SPYING_ACTIVITIES = '001',
    DEVICE_CONTROL = '002',
    DEFENSIVE_EVASION = '003',
    HIDING_FROM_USER = '004',
    PHISHING_ATTEMPT = '005',
    MONEY_EXTORTION = '006',
    SPREADING = '007',
    NETWORK_ACTIVITIES = '008',
    BITDEFENDER_VERDICT = '009',
    APPLICATION_DETAILS = '010',
    FINANCIAL_RISK = '011',
    ADWARE_ACTIVITIES = '012'
};

export type IExplainableThreatsDetails = {
    [group_id in ExplainableThreatsCodes]: {
        icon: {type: string; icon: string;}
    }
};
