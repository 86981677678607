import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { ConfigService } from '../../../config/config.service';
import { ValuesService } from '../../../values/values.service';
import { LanguageService } from '../../core/language.service';

@Injectable({
    providedIn: 'root'
})

export class RcaApiDetectionService {
    constructor(
        private readonly http: HttpClient,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly languageService: LanguageService
    ) {}

    /**
     * Explainable detection API. Gets threats description
     * @param {string} threats list of threats
     * @returns {Observable<any>} The response from the server.
     */
    public getExplainableThreats(threats: string[]): Observable<any> {
        const lang = this.languageService.getLang().replace('_', '-');
        const json = {
            detections: threats,
            groups: true,
            device_id: '',
            locale: lang
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post(
            this.configService.prodConfig.nimbusServer.concat(this.valuesService.rcaApiV1Detection),
            json,
            httpOptions
        ).pipe(
            catchError(error => {
                throw(error);
            })
        );
    }
}