// External
import { Injectable } from '@angular/core';

// Intenral
import { PrivacyErrorCodes } from './privacy.values.service';
import { Color } from '../services/interfaces/itheme/itheme.service';
import { ThemeService } from '../services/core/theme.service';
import { EventDescriptors } from '../services/global/events/events.model';
import { ValuesService } from './values.service';
import { schemas } from '../models/schemas';
import {
    AddBusinessAssetsErrorCodeTexts,
    AssetSubtypes,
    AssetTypes,
    AssetScenarios,
    MonitoredAssetsStatus,
    ThreatTypes,
    ThreatsCategories,
    SocialMediaAccountIcons,
    BusinessAssetIcons,
    BusinessAssetHeaders,
    AssetsConfig,
    ActionStatus,
    BreachActionIcons,
    AssetsActions,
    BreachActionsType,
    BreachActionsByType,
    AssetActionCategories,
    AssetActionCategoryTypes,
    AssetActionTitleByCategoryTypeStatus,
    AssetsActionType,
    RecommendedActionsByStatus,
    RecommendedActions,
    RecommendedStatusByType
} from '../models/business/Business.model';
import {
    GroupRoles,
    GroupRolesUIs,
    GroupTypeToDefaultGroupRole,
    GroupTypeToAdminInvites,
    GroupTypes,
    ContextsUI,
    GroupMemberLabel
} from '../models/subscriptions/Groups.model';

export enum PorductsForGroup {
    DATA_PRIVACY = 'dip',
    PASSWORD_MANAGER = 'password_manager'
}

export enum BusinessEvents {
    GROUP_CREATED = 'group_created',
    GROUP_MEMBERS_INVITED = 'group_members_invited',
    GROUP_MEMBER_INVITED = 'group_member_invited',
    GROUP_MEMBER_REMOVED = 'group_member_removed',
    GROUP_MEMBER_UPDATED = 'group_member_updated',
    GROUP_MEMBER_JOINED = 'group_member_joined',
    GROUP_MEMBER_PROTECTED ='group_member_protected',
    GROUP_MEMBER_PASSWORDMANAGER_CONFIGURED = 'group_member_passwordmanager_configured',
    GROUP_MEMBER_PASSWORDMANAGER_REMOVED = 'group_member_passwordmanager_removed',
    GROUP_MEMBER_WEBMAIL_PROTECTED = 'group_member_webmail_protected',
    GROUP_MEMBER_WEBMAIL_UNPROTECTED = 'group_member_webmail_unprotected',
    GROUP_MEMBER_DATAPRIVACY_CONFIGURED = 'group_member_dataprivacy_configured',
    GROUP_BUSINESSASSETS_CONFIGURED = 'group_businessassets_configured',
    GROUP_BUSINESSASSETS_REMOVED = 'group_businessassets_removed',
    GROUP_MEMBER_DEVICE_PROTECTED = 'group_member_device_protected',
    GROUP_MEMBER_DEVICE_UNPROTECTED = 'group_member_device_unprotected',
    GROUP_MEMBER_DATAPRIVACY_REMOVED = 'group_member_dataprivacy_removed',
    GROUP_STARTING_POINT = 'group_starting_point'
}

export enum BusinessEventsPlaceholder {
    MEMEBR_NAME = 'member_name',
    BUSINESS_NAME = 'business_name',
    NO_INVITES = 'no_invites',
    MEMBER_ROLE = 'member_role',
    PRODUCT_NAME_PASSWORD_MANAGER = 'productNamePasswordManager',
    DEVICE_TYPE = 'device_type'
}

export enum BusinessEventDeviceTypes {
    COMPUTER = 'computer',
    PHONE = 'phone',
    SERVER = 'server',
    MACOS = 'macos'
}

interface BusinessEventText {
    text: string;
    placeholders: BusinessEventsPlaceholder[];
}

type BusinessEventDeviceTypeText = {
    [key in BusinessEventDeviceTypes]: BusinessEventText;
}

type BusinessEventMemberRoleText = {
    [key in GroupRoles]?: BusinessEventText;
};

type BusinessEventsTexts = {
    [key in BusinessEvents]: BusinessEventText | BusinessEventDeviceTypeText | BusinessEventMemberRoleText;
};

export const defaultRoleByGroup: GroupTypeToDefaultGroupRole = {
    [GroupTypes.STANDALONE]: GroupRoles.ADULT,
    [GroupTypes.VSB]: GroupRoles.VSB_EMPLOYEE,
    [GroupTypes.FAMILY]: GroupRoles.ADULT
};

export const ADMIN_NUMBER_INVITES: GroupTypeToAdminInvites = {
    [GroupTypes.VSB]: 1
};

export const AssetsConfigByType: AssetsConfig = {
    [AssetTypes.EMAIL]: {
        icon: {
            type: 'user-data',
            value: 'profile-email'
        },
        baseColor: 'a3'
    },
    [AssetTypes.CREDIT_CARD]: {
        icon: {
            type: 'user-data',
            value: 'payment-methods'
        },
        baseColor: 'a4'
    },
    [AssetTypes.SOCIAL_ACCOUNTS]: {
        icon: {
            type: 'web',
            value: 'social-network'
        },
        baseColor: 'a2'
    }
};

export const AssetsExposureDetails = {
    [EventDescriptors.TITLE]: {
        [AssetTypes.EMAIL]: {
            [AssetTypes.CREDIT_CARD]: {
                [AssetTypes.SOCIAL_ACCOUNTS]: {
                    [EventDescriptors.TEXT]: 'business.assets.monitored.email.and.credit_card.and.social_account'
                },
                [EventDescriptors.TEXT]: 'business.assets.monitored.email.and.credit_card'
            },
            [AssetTypes.SOCIAL_ACCOUNTS]: {
                [EventDescriptors.TEXT]:'business.assets.monitored.email.and.social_account'
            },
            [EventDescriptors.TEXT]: 'business.assets.monitored.email'
        },
        [AssetTypes.CREDIT_CARD]: {
            [AssetTypes.SOCIAL_ACCOUNTS]: {
                [EventDescriptors.TEXT]: 'business.assets.monitored.credit_card.and.social_account'
            },
            [EventDescriptors.TEXT]: 'business.assets.monitored.credit_card'
        },
        [AssetTypes.SOCIAL_ACCOUNTS]: {
            [EventDescriptors.TEXT]: 'business.assets.monitored.social_account'
        }
    }
};

export const MonitoredAssetsScenarios = {
    [MonitoredAssetsStatus.NONE_MONITORED]: {
        description: 'business.assets.description.exposure.none',
        cta: 'business.assets.cta.goToSetup'
    },
    [MonitoredAssetsStatus.ONE_MONITORED]: {
        description: 'business.assets.description.exposure.one',
        cta: 'business.assets.cta.addOtherAssets'
    },
    [MonitoredAssetsStatus.SOME_MONITORED]: {
        description: 'business.assets.description.exposure.some',
        cta: 'business.assets.cta.addOtherAssets'
    },
    [MonitoredAssetsStatus.ALL_MONITORED]: {
        description: 'business.assets.description.exposure.some',
        cta: 'business.assets.cta.viewDetails'
    }
};

export const ContextTypeUI: ContextsUI = {
    [GroupTypes.STANDALONE]: {
        label: 'context.personal.label'
    },
    [GroupTypes.VSB]: {
        label: 'context.business.label'
    },
    [GroupTypes.ATO]: {
        label: 'context.ato.label'
    }
};

export const GroupRoleUI: GroupRolesUIs = {
    [GroupRoles.ADULT]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.family.plan.category.adult.title'
        }
    },
    [GroupRoles.TEENAGER]: {
        icon: {
            type: 'feature',
            icon: 'avatar-teenager'
        },
        label: {
            default: 'subscriptions.servicedetails.family.plan.category.teenager.title'
        }
    },
    [GroupRoles.CHILD]: {
        icon: {
            type: 'feature',
            icon: 'avatar-child'
        },
        label: {
            default: 'subscriptions.servicedetails.family.plan.category.child.title'
        }
    },
    [GroupRoles.VSB_EMPLOYEE]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.business.plan.category.employee.title'
        }
    },
    [GroupRoles.VSB_PRIMARY_ADMIN]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.business.plan.category.securityAdmin.title',
            [GroupMemberLabel.BUSINESS_OWNER]: 'subscriptions.servicedetails.business.plan.category.businessowner.title'
        }
    },
    [GroupRoles.VSB_SECONDARY_ADMIN]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.business.plan.category.securityAdmin.title',
            [GroupMemberLabel.BUSINESS_OWNER]: 'subscriptions.servicedetails.business.plan.category.businessowner.title'
        }
    },
    [GroupRoles.CCP_EMPLOYEE]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.ato.plan.category.employee.title'
        }
    },
    [GroupRoles.CCP_PRIMARY_ADMIN]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.ato.plan.category.primaryAdmin.title'
        }
    },
    [GroupRoles.CCP_SECONDARY_ADMIN]: {
        icon: {
            type: 'feature',
            icon: 'avatar-adult'
        },
        label: {
            default: 'subscriptions.servicedetails.ato.plan.category.secondaryAdmin.title'
        }
    }
};

export const adminRoles = new Set([GroupRoles.VSB_PRIMARY_ADMIN, GroupRoles.VSB_SECONDARY_ADMIN]);
export const employeeRoles = new Set([GroupRoles.VSB_EMPLOYEE, GroupRoles.VSB_SECONDARY_ADMIN]);

export const GroupNameErrorPlaceholders = {
    maxLength: schemas.schemaGroupName.maxLength,
    minLength: schemas.schemaGroupName.minLength
};

@Injectable({
    providedIn: 'root'
})

export class BusinessValuesService {

    constructor(
        private readonly themeService: ThemeService,
        private readonly valuesService: ValuesService
    ) {}

    public readonly maxSocialAccountsNo = 10;

    public threatsTypes = new Set([
        ThreatTypes.WEBSITES,
        ThreatTypes.FILES,
        ThreatTypes.EMAILS
    ]);

    public threatsCategories: ThreatsCategories = {
        [ThreatTypes.WEBSITES]: {
            label: 'security.incidents.card.dangerous.website.label',
            color: 'bg-a2'
        },
        [ThreatTypes.FILES]: {
            label: 'security.incidents.card.dangerous.files.label',
            color: 'bg-a1'
        },
        [ThreatTypes.EMAILS]: {
            label: 'security.incidents.card.dangerous.emails.label',
            color: 'bg-a7'
        }
    };

    public threatsDoughnutChartColors = {
        [ThreatTypes.WEBSITES]: this.themeService.getColor(Color.a2),
        [ThreatTypes.FILES]: this.themeService.getColor(Color.a1),
        [ThreatTypes.EMAILS]: this.themeService.getColor(Color.a7)
    };

    public defaultChartAnimationDuration = 5000;
    public defaultThreatsDoughnutChartValue = 33.33;

    public defaultThreatsDoughnutChart = {
        data: Array(3).fill(this.defaultThreatsDoughnutChartValue),
        backgroundColor: [
            this.threatsDoughnutChartColors[ThreatTypes.WEBSITES],
            this.threatsDoughnutChartColors[ThreatTypes.FILES],
            this.threatsDoughnutChartColors[ThreatTypes.EMAILS]
        ]
    };

    public threatsDoughnutChartStyle = {
        options: {
            responsive: true,
            aspectRatio: 1,
            cutoutPercentage: 70,
            title: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            hover: {
                mode: null
            },
            legend: {
                display: false
            },
            elements: {
                arc: {
                    borderWidth: 0
                }
            }
        },
        gridLines: {
            display: false,
            drawBotder: false
        }
    };

    public threatsChartStyle = {
        options: {
            responsive: true,
            aspectRatio: 1,
            title: {
                display: false
            },
            legend: {
                display: false
            },
            layout: {
                padding: {
                    top: 30,
                    bottom: 10
                }
            }
        },
        ticks: {
            fontColor: this.themeService.getColor(Color.shade, 0.6),
            padding: 16,
            maxRotation: 0,
            autoSkipPadding: 16,
            beginAtZero: true
        },
        gridLines: {
            color: this.themeService.getColor(Color.shade, 0.1),
            zeroLineColor: this.themeService.getColor(Color.shade, 0.1),
            drawTicks: true,
            drawBorder: false,
            borderDash: [2],
            zeroLineWidth: 1,
            zeroLineBorderDash: [1],
            tickMarkLength: 0
        },
        tooltip: {
            backgroundColor: this.themeService.getColor(Color.primary_5),
            borderColor: this.themeService.getColor(Color.primary),
            titleFontColor: this.themeService.getColor(Color.shade, 0.8),
            footerFontColor: this.themeService.getColor(Color.shade, 0.6),
            borderWidth: 1,
            titleFontStyle: 'bold',
            xPadding: 17,
            yPadding: 17,
            displayColors: false,
            enabled: false
        },
        datasets: {
            borderWidth: 3,
            pointRadius: 4,
            pointHoverBorderWidth: 3,
            pointHoverRadius: 7,
            backgroundColor: this.themeService.getColor(Color.primary, 0.05),
            borderColor: this.themeService.getColor(Color.primary),
            pointBorderColor: this.themeService.getColor(Color.primary),
            pointBackgroundColor: this.themeService.getColor(Color.primary),
            pointHoverBackgroundColor: this.themeService.getColor(Color.light)
        }
    };

    public omniture = {
        modalSectionEvents: {
            addEmail: 'add-email',
            addSocialEmail: 'add-social-email',
            addCreditCard: 'add-credit-card',
            addSocialAccount: 'add-social-account',
            validateCode: 'validate-code',
            assetAdded: 'asset-added'
        },
        clickEvents: {
            viewSecurityIncidentsDetails: 'viewSecurityIncidentsDetails',
            removeBusinessAsset: 'removeBusinessAsset',
            editBusinessAsset: 'editBusinessAsset',
            markAsDoneAction: 'markAsDoneAction',
            undoAction: 'undoAction'
        }
    };

    public readonly socialMediaAccountIcons: SocialMediaAccountIcons = {
        [AssetSubtypes.YOUTUBE]: this.valuesService.centralImages.youtubeIcon,
        [AssetSubtypes.FACEBOOK]: this.valuesService.centralImages.facebookIcon,
        [AssetSubtypes.INSTAGRAM]: this.valuesService.centralImages.instagramIcon,
        [AssetSubtypes.LINKEDIN]: this.valuesService.centralImages.linkedinIcon,
        [AssetSubtypes.TIKTOK]: this.valuesService.centralImages.tikTokIcon,
        [AssetSubtypes.WHATSAPP]: this.valuesService.centralImages.whatsappIcon,
        [AssetSubtypes.PINTEREST]: this.valuesService.centralImages.pinterestIcon,
        [AssetSubtypes.SNAPCHAT]: this.valuesService.centralImages.snapchatIcon,
        [AssetSubtypes.REDDIT]: this.valuesService.centralImages.redditIcon,
        [AssetSubtypes.X]: this.valuesService.centralImages.xIcon,
    };

    public readonly addBusinessAssetsErrorCodeTexts: AddBusinessAssetsErrorCodeTexts = {
        [PrivacyErrorCodes.INVALID_PARAMS]: 'add.business.assets.errors.invalid.params',
        [PrivacyErrorCodes.DATABASE_ERROR]: 'add.business.assets.errors.database',
        [PrivacyErrorCodes.SEND_CODE_ERR]: 'add.business.assets.errors.send.code.error',
        [PrivacyErrorCodes.NO_IDENTITY]: 'add.business.assets.errors.no.identity',
        [PrivacyErrorCodes.IDENTITY_EXISTS]: 'add.business.assets.errors.identity.exists',
        [PrivacyErrorCodes.INFO_NOT_FOUND]: 'add.business.assets.errors.info.not.found',
        [PrivacyErrorCodes.INFO_INVALID]: 'add.business.assets.errors.info.invalid',
        [PrivacyErrorCodes.INFO_EXISTS]: 'add.business.assets.errors.info.exists',
        [PrivacyErrorCodes.INFO_VALIDATED]: 'add.business.assets.errors.info.validated',
        [PrivacyErrorCodes.VALIDATION_CODE_INVALID]: 'add.business.assets.errors.code.invalid',
        [PrivacyErrorCodes.VALIDATION_CODE_EXPIRED]: 'add.business.assets.errors.code.expired',
        [PrivacyErrorCodes.LIMIT_EXCEEDED]: 'add.business.assets.errors.code.limit.exceeded',
        [PrivacyErrorCodes.VALIDATION_ABUSE]: {
            hasWaitingPeriod: 'add.business.assets.errors.validation.abuse.seconds',
            withoutWaitingPeriod: 'add.business.assets.errors.validation.abuse'
        },
        default: 'add.business.assets.errors.default'
    };

    private readonly businessAssetIcons: BusinessAssetIcons = {
        [AssetScenarios.EMAIL]: {
            type: 'user-data',
            icon: 'profile-email',
            color: 'a3',
            bg: 'a3-5'
        },
        [AssetScenarios.CARD]: {
            type: 'user-data',
            icon: 'payment-methods',
            color: 'a4',
            bg: 'a4-5'
        },
        [AssetScenarios.SOCIAL]: {
            type: 'user-data',
            icon: 'social-media-profiles',
            color: 'a2',
            bg: 'a2-5'
        },
        [AssetScenarios.EDIT_SOCIAL]: {
            type: 'user-data',
            icon: 'social-media-profiles',
            color: 'a2',
            bg: 'a2-5'
        }
    };

    public readonly businessAssetHeaders: BusinessAssetHeaders = {
        [AssetScenarios.EMAIL]: {
            title: 'add.business.email.title',
            image: this.businessAssetIcons[AssetScenarios.EMAIL]
        },
        [AssetScenarios.CARD]: {
            title: 'add.business.card.title',
            image: this.businessAssetIcons[AssetScenarios.CARD]
        },
        [AssetScenarios.SOCIAL]: {
            title: 'add.business.social.title',
            image: this.businessAssetIcons[AssetScenarios.SOCIAL]
        },
        [AssetScenarios.EDIT_SOCIAL]: {
            title: 'edit.business.social.title',
            image: this.businessAssetIcons[AssetScenarios.SOCIAL]
        }
    };

    public readonly socialMediaAccounts = new Set([
        AssetSubtypes.FACEBOOK,
        AssetSubtypes.INSTAGRAM,
        AssetSubtypes.LINKEDIN,
        AssetSubtypes.PINTEREST,
        AssetSubtypes.REDDIT,
        AssetSubtypes.SNAPCHAT,
        AssetSubtypes.TIKTOK,
        AssetSubtypes.WHATSAPP,
        AssetSubtypes.X,
        AssetSubtypes.YOUTUBE
    ]);

    public readonly businessEventsTexts: BusinessEventsTexts = {
        [BusinessEvents.GROUP_MEMBER_DEVICE_UNPROTECTED]: {
            [BusinessEventDeviceTypes.COMPUTER]: {
                text: 'business.security.activity.list.text.group_member_device_unprotected.computer',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.PHONE]: {
                text: 'business.security.activity.list.text.group_member_device_unprotected.phone',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.SERVER]: {
                text: 'business.security.activity.list.text.group_member_device_unprotected.server',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.MACOS]: {
                text: 'business.security.activity.list.text.group_member_device_unprotected.macos',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [BusinessEvents.GROUP_STARTING_POINT]: {
            text: 'business.security.activity.list.text.group_event_starting_point',
            placeholders: []
        },
        [BusinessEvents.GROUP_MEMBER_DATAPRIVACY_REMOVED]: {
            text: 'business.security.activity.list.text.group_event_member_dataprivacy_removed',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
        },
        [BusinessEvents.GROUP_MEMBER_DEVICE_PROTECTED]: {
            [BusinessEventDeviceTypes.COMPUTER]: {
                text: 'business.security.activity.list.text.group_event_member_device_protected.computer',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.PHONE]: {
                text: 'business.security.activity.list.text.group_event_member_device_protected.phone',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.SERVER]: {
                text: 'business.security.activity.list.text.group_event_member_device_protected.server',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.MACOS]: {
                text: 'business.security.activity.list.text.group_event_member_device_protected.macos',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [BusinessEvents.GROUP_BUSINESSASSETS_CONFIGURED]: {
            text: 'business.security.activity.list.text.group_event_businessassets_configured',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
        },
        [BusinessEvents.GROUP_BUSINESSASSETS_REMOVED]: {
            text: 'business.security.activity.list.text.group_event_businessassets_removed',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
        },
        [BusinessEvents.GROUP_CREATED]: {
            text: 'business.security.activity.list.text.group_event_created',
            placeholders: [BusinessEventsPlaceholder.BUSINESS_NAME]
        },
        [BusinessEvents.GROUP_MEMBERS_INVITED]: {
            text: 'business.security.activity.list.text.group_event_members_invited',
            placeholders: [BusinessEventsPlaceholder.NO_INVITES, BusinessEventsPlaceholder.BUSINESS_NAME]
        },
        [BusinessEvents.GROUP_MEMBER_INVITED]: {
            text: 'business.security.activity.list.text.group_event_member_invited',
            placeholders: [BusinessEventsPlaceholder.BUSINESS_NAME]
        },
        [BusinessEvents.GROUP_MEMBER_REMOVED]: {
            text: 'business.security.activity.list.text.group_event_member_removed',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME, BusinessEventsPlaceholder.BUSINESS_NAME]
        },
        [BusinessEvents.GROUP_MEMBER_UPDATED]: {
            text: 'business.security.activity.list.text.group_event_member_updated',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME, BusinessEventsPlaceholder.MEMBER_ROLE]
        },
        [BusinessEvents.GROUP_MEMBER_JOINED]: {
            [GroupRoles.VSB_PRIMARY_ADMIN]: {
                text: 'business.security.activity.list.text.group_event_member_joined.business.owner',
                placeholders: [BusinessEventsPlaceholder.BUSINESS_NAME, BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [GroupRoles.VSB_SECONDARY_ADMIN]: {
                text: 'business.security.activity.list.text.group_event_member_joined.security.admin',
                placeholders: [BusinessEventsPlaceholder.BUSINESS_NAME, BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [GroupRoles.VSB_EMPLOYEE]: {
                text: 'business.security.activity.list.text.group_event_member_joined.employee',
                placeholders: [BusinessEventsPlaceholder.BUSINESS_NAME, BusinessEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [BusinessEvents.GROUP_MEMBER_PROTECTED]: {
            [BusinessEventDeviceTypes.COMPUTER]: {
                text: 'business.security.activity.list.text.group_event_member_protected.computer',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.PHONE]: {
                text: 'business.security.activity.list.text.group_event_member_protected.phone',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.SERVER]: {
                text: 'business.security.activity.list.text.group_event_member_protected.server',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            },
            [BusinessEventDeviceTypes.MACOS]: {
                text: 'business.security.activity.list.text.group_event_member_protected.macos',
                placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
            }
        },
        [BusinessEvents.GROUP_MEMBER_PASSWORDMANAGER_CONFIGURED]: {
            text: 'business.security.activity.list.text.group_event_member_passwordmanager',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME, BusinessEventsPlaceholder.PRODUCT_NAME_PASSWORD_MANAGER]
        },
        [BusinessEvents.GROUP_MEMBER_PASSWORDMANAGER_REMOVED]: {
            text: 'business.security.activity.list.text.group_event_member_passwordmanager_removed',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME, BusinessEventsPlaceholder.PRODUCT_NAME_PASSWORD_MANAGER]
        },
        [BusinessEvents.GROUP_MEMBER_WEBMAIL_PROTECTED]: {
            text: 'business.security.activity.list.text.group_event_member_webmailprotection',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
        },
        [BusinessEvents.GROUP_MEMBER_WEBMAIL_UNPROTECTED]: {
            text: 'business.security.activity.list.text.group_event_member_webmail_unprotected',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
        },
        [BusinessEvents.GROUP_MEMBER_DATAPRIVACY_CONFIGURED]: {
            text: 'business.security.activity.list.text.group_event_member_dataprivacy',
            placeholders: [BusinessEventsPlaceholder.MEMEBR_NAME]
        }
    };

    public readonly businessAssetsActions: BreachActionsType = {
        [AssetsActions.REMOVE_ASSET]: {
            name: 'business.assets.breach.actions.remove.asset',
            action: AssetsActions.REMOVE_ASSET
        },
        [AssetsActions.EDIT_ACCOUNT]: {
            name: 'business.assets.breach.actions.edit.account',
            action: AssetsActions.EDIT_ACCOUNT
        }
    };

    public readonly businessAssetsBreachActionsByType: BreachActionsByType = {
        [AssetTypes.EMAIL]: [
            this.businessAssetsActions[AssetsActions.REMOVE_ASSET]
        ],
        [AssetTypes.CREDIT_CARD]: [
            this.businessAssetsActions[AssetsActions.REMOVE_ASSET]
        ],
        [AssetTypes.SOCIAL_ACCOUNTS]: [
            this.businessAssetsActions[AssetsActions.EDIT_ACCOUNT],
            this.businessAssetsActions[AssetsActions.REMOVE_ASSET]
        ]
    };

    public readonly businessRecommendationActions: RecommendedActions = {
        [AssetsActionType.DONE]: {
            name: 'business.assets.breach.actions.mark.done',
            action: AssetsActionType.DONE
        },
        [AssetsActionType.UNDO]: {
            name: 'business.assets.breach.actions.undo',
            action: AssetsActionType.UNDO
        }
    };

    public readonly businessRecommendationStatusByType: RecommendedStatusByType = {
        [AssetsActionType.DONE]: ActionStatus.DONE,
        [AssetsActionType.UNDO]: ActionStatus.NEW
    };

    public readonly businessRecommendationActionsByStatus: RecommendedActionsByStatus = {
        [ActionStatus.NEW]: this.businessRecommendationActions[AssetsActionType.DONE],
        [ActionStatus.DONE]: this.businessRecommendationActions[AssetsActionType.UNDO]
    };

    public readonly assetBreachActionIcons: BreachActionIcons = {
        [ActionStatus.NEW]: {
            value: 'shield-x',
            type: 'feedback'
        },
        [ActionStatus.DONE]: {
            value: 'shield-check',
            type: 'feedback'
        }
    };

    public readonly businessActionTitleByCategoryTypeStatus: AssetActionTitleByCategoryTypeStatus = {
        [AssetActionCategories.BUSINESS_EMAIL_OR_DOMAIN]: {
            [AssetActionCategoryTypes.USE_DIP]: {
                [ActionStatus.NEW]: 'business.assets.breach.email.monitor.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.email.monitor.action.done'
            },
            [AssetActionCategoryTypes.USE_PASSWORD_MANAGER]: {
                [ActionStatus.NEW]: 'business.assets.breach.email.change.password.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.email.change.password.action.done'
            },
            [AssetActionCategoryTypes.USE_SECURITY_SOLUTIONS]: {
                [ActionStatus.NEW]: 'business.assets.breach.email.ensure.security.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.email.ensure.security.action.done'
            },
            [AssetActionCategoryTypes.USE_SCAM_COPILOT]: {
                [ActionStatus.NEW]: 'business.assets.breach.email.scam.copilot.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.email.scam.copilot.action.done'
            }
        },
        [AssetActionCategories.BUSINESS_CREDIT_CARD]: {
            [AssetActionCategoryTypes.MONITOR_TRANSACTIONS]: {
                [ActionStatus.NEW]: 'business.assets.breach.card.monitor.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.card.monitor.action.done'
            },
            [AssetActionCategoryTypes.USE_SECURITY_SOLUTIONS]: {
                [ActionStatus.NEW]: 'business.assets.breach.card.ensure.security.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.card.ensure.security.action.done'
            }
        },
        [AssetActionCategories.SOCIAL_MEDIA_ACCOUNTS_EMAILS]: {
            [AssetActionCategoryTypes.ENABLE_2FA]: {
                [ActionStatus.NEW]: 'business.assets.breach.social.monitor.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.social.monitor.action.done'
            },
            [AssetActionCategoryTypes.USE_PASSWORD_MANAGER]: {
                [ActionStatus.NEW]: 'business.assets.breach.social.change.password.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.social.change.password.action.done'
            },
            [AssetActionCategoryTypes.USE_SECURITY_SOLUTIONS]: {
                [ActionStatus.NEW]: 'business.assets.breach.card.ensure.security.action.new',
                [ActionStatus.DONE]: 'business.assets.breach.card.ensure.security.action.done'
            }
        }
    };

}
