// External
import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';
import moment from 'moment';

// Internal
import { SubscriptionMgmtService } from '../../requests/connect-subscription-service/connect-subscription.service';
import { ValuesService, IName } from '../../../../common/values/values.service';
import { LanguageService } from '../../core/language.service';
import { ProfilesService } from '../profiles/profiles.service';
import { AppLevels, SubscriptionsV3Prefix, SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';
import { UsefulService } from '../../global/useful/useful.service';
import { ProductsConfigService } from '../../../../common/config/products.config.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { WebmailProtectionSlots } from '../../../models/security/WebmailProtection.model';
import { MessageService } from '../../core/message.service';
import { GroupMemberLabel, GroupTypes } from '../../../../common/models/subscriptions/Groups.model';
import { GroupRoleUI, adminRoles } from '../../../values/business.values.service';
import {
    DeviceAllocationMgmtResponseModel,
    BundleModel,
    AppConfigurationStatus,
    SharedSubscriptionSharedInfo,
    SharedSubscriptionInvite
} from '../../../../common/models/Services.model';

@Injectable({
    providedIn: 'root'
})

export class SubscriptionsService {

    tempSubscriptions: any = {};
    originalSubscriptionsResponse = [];
    subscriptions: any = {
        hasExtraProtectionBesidesATO: false,
        hasATO: false,
        hasATOExpired: false
    };
    private activeVSBService = null as BundleModel;

    allServices = [];
    convertedServiceIds = new Set();

    private readonly onlistSubscriptions$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdate_subscriptions = true;

    constructor(
        private readonly subscriptionMgmtService: SubscriptionMgmtService,
        private readonly valuesService: ValuesService,
        private readonly languageService: LanguageService,
        private readonly profilesService: ProfilesService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly usefulService: UsefulService,
        private readonly productsConfigService: ProductsConfigService,
        private readonly utileCommonService: UtilsCommonService,
        private readonly messageService: MessageService
    ) {
        /* Used for updating the request in order to write them into th db by the service worker */
        this.messageService.getDynamicSubject(this.valuesService.events.resetRequestsForServiceWorker)
        .subscribe({
            next: () => {
                this.updateSubscriptions();
            }
        });
    }

    addCovertedServiceId(serviceId) {
        this.convertedServiceIds.add(serviceId);
    }

    serviceIdWasConverted(serviceId) {
        return this.convertedServiceIds.has(serviceId);
    }

    setHasPaidSubscription(bundle: BundleModel) {
        this.subscriptions.hasPaidSubscription = this.subscriptions.hasPaidSubscription
                                                || (bundle.type === this.subscriptionsValuesService.type.end_user
                                                    && bundle.status === this.subscriptionsValuesService.status.active);
    }

    setHasBIP(bundle: BundleModel) {
        const applications = bundle?.applications ?? [];
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of applications) {
            this.subscriptions.hasBIP = this.subscriptions.hasBIP || app.app_id === this.valuesService.appBIP;
        }
    }

    /**
     * Sets the flag 'hasWinserver' if the bundle has the winserver application
     * @param {BundleModel} bundle The bundle object
     */
    private setHasWinserver(bundle: BundleModel): void {
        const applications = bundle?.applications ?? [];
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of applications) {
            this.subscriptions.hasWinserver = this.subscriptions.hasWinserver || app.app_id === this.valuesService.appWS;
        }
    }

    isTrialBundle(bundle: BundleModel) {
        return bundle.type === this.subscriptionsValuesService.type.trial || bundle.type === this.subscriptionsValuesService.type.nfr;
    }

    isPaidBundle(bundle: BundleModel) {
        return bundle.type === this.subscriptionsValuesService.type.end_user;
    }

    isExpired(bundle: BundleModel) {
        return bundle.status === this.subscriptionsValuesService.status.expired;
    }

    isExpiredForLessThan30Days(bundle: BundleModel) {
        return bundle.status === this.subscriptionsValuesService.status.expired && bundle.server_time - bundle.end_date <= this.valuesService.SECONDS_IN_A_DAY*30;
    }

    /**
     * Check if subscription is managed by another account
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     * @returns {boolean}
     */
    public isManagedByOrganization(bundle: BundleModel): boolean {
        return !!(bundle?.source?.type === this.subscriptionsValuesService.sourceType.msp);
    }

    isProtectionBundle(bundle: BundleModel) {
        const applications = this.usefulService.getNested(bundle, [], 'applications');
        for (const app of applications) {
            if (this.valuesService.protectionApps.has(app.app_id)) {
                return true;
            }
        }
        return false;
    }

    isClBundle(bundle: BundleModel) {
        const applications = this.usefulService.getNested(bundle, [], 'applications');
        for (const app of applications) {
            if (app.app_id === this.valuesService.appCL && bundle.status === this.subscriptionsValuesService.status.active) {
                return true;
            }
        }
        return false;
    }

    isNonprotectionBundle(bundle: BundleModel) {
        return this.valuesService.nonProtectionBundles.has(bundle.bundle_id);
    }

    setHasTrialSubscription(bundle) {
        if (bundle.type === 'trial' || bundle.type === 'nfr') {
            this.subscriptions.hasTrialSubscription = bundle.end_date - bundle.server_time;
        }
    }

    setHasAllBundlesAreExpired(bundle: BundleModel) {
        if (!this.isExpired(bundle) && !this.isPremiumService(bundle)) {
            this.subscriptions.hasAllBundlesAreExpired = false;
        }
    }

    setHasPaidProtectionBundleExpired(bundle: BundleModel) {
        if (this.isProtectionBundle(bundle) && this.isPaidBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasPaidProtectionBundleExpired = true;
        }
    }

    setHasClBundle(bundle: BundleModel) {
        if (this.isClBundle(bundle)) {
            this.subscriptions.hasClSubscription = true;
        }
    }

    setHasFreeProtectionBundleExpired(bundle) {
        if (this.isProtectionBundle(bundle) && this.isTrialBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasFreeProtectionBundleExpired = true;
        }
    }

    setHasPaidNonprotectionBundleExpired(bundle: BundleModel) {
        if (this.isNonprotectionBundle(bundle) && this.isPaidBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasPaidNonprotectionBundleExpired = true;
        }
    }

    setHasFreeNonprotectionBundleExpired(bundle: BundleModel) {
        if (this.isNonprotectionBundle(bundle) && this.isTrialBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasFreeNonprotectionBundleExpired = true;
        }
    }

    /**
     * Sets 'hasNonTrialBundleExpired' flag if there is at least one expired non-trial subscription
     * @private
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     */
    private setHasNonTrialBundleExpired(bundle: BundleModel): void {
        if (!this.isTrialBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasNonTrialBundleExpired = true;
        }
    }

    /**
     * Sets 'hasMspManagedBundle' flag if there is at least one msp managed bundle
     * @private
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     */
    private setHasMspManagedBundle(bundle: BundleModel): void {
        if (this.isManagedByOrganization(bundle) && this.profilesService.hasMspOrXspAnyLevel()) {
            this.subscriptions.hasMspManagedBundle = true;
        }
    }

    hasAllBundlesAreExpired() {
        return this.subscriptions.hasAllBundlesAreExpired;
    }

    hasPaidProtectionBundleExpired() {
        return this.subscriptions.hasPaidProtectionBundleExpired;
    }

    hasFreeProtectionBundleExpired() {
        return this.subscriptions.hasFreeProtectionBundleExpired;
    }

    hasPaidNonprotectionBundleExpired() {
        return this.subscriptions.hasPaidNonprotectionBundleExpired;
    }

    hasFreeNonprotectionBundleExpired() {
        return this.subscriptions.hasFreeNonprotectionBundleExpired;
    }

    public hasNonTrialBundleExpired(): boolean {
        return this.subscriptions.hasNonTrialBundleExpired;
    }

    hasBIP() {
        return this.subscriptions.hasBIP;
    }

    /**
     * Check if the account has winserver application
     * @returns {boolean} true if on the account there is a winserver application
     */
    public hasWinserver(): boolean {
        return !!this.subscriptions?.hasWinserver;
    }

    setHasTSMD(bundle) {
        if (bundle.bundle_id === this.valuesService.bundleTSMD) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasTSMD = true;
            } else {
                this.subscriptions.hasTSMDExpired = true;
            }
        }
    }

    private computeVSBService(bundle: BundleModel) {
        if (bundle.bundle_id === this.valuesService.bundleVSB) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.activeVSBService = bundle;
            } else {
                this.subscriptions.hasVSBExpired = true;
            }
        }
    }

    public hasActiveVSBServiceOnNonVSBContext() {
        const groupType = this.profilesService.getCurrentGroupType();
        return groupType !== GroupTypes.VSB && this.activeVSBService;
    }

    public isActiveVSBServiceOnNonVSBContext(bundle: BundleModel) {
        const groupType = this.profilesService.getCurrentGroupType();
        return groupType !== GroupTypes.VSB && bundle.bundle_id === this.valuesService.bundleVSB;
    }

    public getActiveVSBService() {
        return this.activeVSBService;
    }

    public hasVSBExpired(): boolean {
        return this.subscriptions.hasVSBExpired;
    }

    setHasBox(bundle) {
        if (bundle.bundle_id === this.valuesService.bundleBox || bundle.bundle_id === this.valuesService.bundleBoxCl || bundle.bundle_id === this.valuesService.bundleBOX2) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasBox = true;
            } else {
                this.subscriptions.hasBoxExpired = true;
            }
        }
    }

    setHasBox2(bundle) {
        if (bundle.bundle_id === this.valuesService.bundleBOX2) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasBox2 = true;
            } else {
                this.subscriptions.hasBox2Expired = true;
            }
        }
    }

    setHasVpn(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (this.valuesService.vpnApps.has(app.app_id)) {
                this.subscriptions.hasVpn = true;

                if (app?.app_params?.level === AppLevels.PREMIUM) {
                    this.subscriptions.hasPremiumVpn = true;
                }
                break;
            }
        }
    }

    setHasParental(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (app.app_id === this.valuesService.appPA) {
                this.subscriptions.hasParental = true;
                break;
            }
        }
    }

    setHasParentalNCC(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (app.app_id === this.valuesService.appPANCC) {
                if (app?.app_params?.level === AppLevels.BASIC) {
                    this.subscriptions.hasParentalNCCBasic = true;
                } else {
                    this.subscriptions.hasParentalNCCPremium = true;
                }
                break;
            }
        }
    }

    /**
     * Set if subscription has Permium Security Plus
     * @public
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     */
    public setHasPremiumSecurityPlus(bundle: BundleModel): void {
        if (bundle.bundle_id === this.valuesService.bundlePSecPlus && bundle.status === this.subscriptionsValuesService.status.active) {
            this.subscriptions.hasPremiumSecPlus = true;
            if (!this.subscriptions.servicesIds) {
                this.subscriptions.servicesIds = {};
            }
            this.subscriptions.servicesIds[this.valuesService.bundlePSecPlus] = bundle?.service_id;
        }
    }

    /**
     * Set if subscription has Permium Security
     * @public
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     */
    public setHasPremiumSecurity(bundle: BundleModel): void {
        if (bundle.bundle_id === this.valuesService.bundlePSec && bundle.status === this.subscriptionsValuesService.status.active) {
            this.subscriptions.hasPremiumSec = true;
            if (!this.subscriptions.servicesIds) {
                this.subscriptions.servicesIds = {};
            }
            this.subscriptions.servicesIds[this.valuesService.bundlePSec] = bundle?.service_id;
        }
    }

    /**
     * Set if subscription has Ultiimate Security
     * @public
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     */
    public setHasUltimateSecurity(bundle: BundleModel): void {
        if (bundle.bundle_id === this.valuesService.bundleUSUS && bundle.status === this.subscriptionsValuesService.status.active) {
            this.subscriptions.hasUltimateSec = true;
            if (!this.subscriptions.servicesIds) {
                this.subscriptions.servicesIds = {};
            }
            this.subscriptions.servicesIds[this.valuesService.bundleUSUS] = bundle?.service_id;
        }
    }

    /**
     * Set if subscription has Ultimate Security Plus
     * @public
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     */
    public setHasUltimateSecurityPlus(bundle: BundleModel): void {
        if (bundle.bundle_id === this.valuesService.bundleUSPUS && bundle.status === this.subscriptionsValuesService.status.active) {
            this.subscriptions.hasUltimateSecPlus = true;
            if (!this.subscriptions.servicesIds) {
                this.subscriptions.servicesIds = {};
            }
            this.subscriptions.servicesIds[this.valuesService.bundleUSPUS] = bundle?.service_id;
        }
    }

    /**
     * Set flag if premium services subscription exists
     * @param {object} bundle
     * @returns {nothing}
     */
    setHasPremiumServices(bundle) {
        if (this.valuesService.techassistProductsDetails[bundle.bundle_id] && bundle.status === this.subscriptionsValuesService.status.active) {
            this.subscriptions.hasPremiumServices = true;
        }
    }

    setHasOnlyIdentitySubscriptions(bundle) {
        // could be expired or not
        if (!this.valuesService.identityProducts.has(bundle.bundle_id)) {
            this.subscriptions.hasOnlyIdentitySubscriptions = false;
        }
    }

    setHasOnlySohoSubscriptions(bundle) {
        if (!this.valuesService.sohoBundles.includes(bundle.bundle_id)) {
            this.subscriptions.hasOnlySohoSubscriptions = false;
        }
    }

    setHasSohoSubscription(bundle) {
        if (this.valuesService.sohoBundles.includes(bundle.bundle_id)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasSohoSubscription = true;
            } else {
                this.subscriptions.hasSohoSubscriptionExpired = true;
            }
        }
    }

    setHasIdTheftProtection(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appIdTheft)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasIdTheftProtection = true;
            } else {
                this.subscriptions.hasIdTheftProtectionExpired = true;
            }
            this.subscriptions.expiryIdTheftProtection = bundle.end_date;
        }
    }

    /**
     * Function that sets 'hasWebmailProtection' flag if bundle includes webmailprotection and is active
     *
     * @private
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle representing bundle data
     * @returns {void}
     */
    private setHasWebmailProtection(bundle: BundleModel): void {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (app.app_id === this.valuesService.appWebmailProtection && bundle?.bundle_id !== this.valuesService.bundleATO) {
                this.subscriptions.hasWebmailProtection = true;
            }
        }
    }

    setHasDataPrivacy(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appDIP)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasDataPrivacy = true;
            } else {
                this.subscriptions.hasDataPrivacyExpired = true;
            }
            this.subscriptions.expiryDataPrivacy = bundle.end_date;
        }
    }

    /**
     * Sets the flags for the account regarding the business assets exposure application
     * @param {BundleModel} bundle The bundle object
     */
    private setHasBusinessAssetsExposure(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appBA)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasBusinessAssetsExposure = true;
            } else {
                this.subscriptions.hasBusinessAssetsExposureExpired = true;
            }
            this.subscriptions.expiryBusinessAssestsExposure = bundle.end_date;
        }
    }

    setHasPasswordManager(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appPassManager)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasPasswordManager = true;
            } else {
                if (this.isTrialBundle(bundle)) {
                    this.subscriptions.hasPasswordManagerTrialExpired = true;
                } else {
                    this.subscriptions.hasPasswordManagerExpired = true;
                }
            }
        }
    }

    setHasPasswordManagerCompatible(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        if (this.valuesService.passManagerCompatibleBundles.has(bundle.bundle_id)) {
            this.subscriptions.hasPasswordManagerThreeMonthsCompatible = true;
        } else {
            this.subscriptions.hasPasswordManagerOneMonthCompatible = true;
        }
    }

    setHasAutoRenewalOn() {
        this.subscriptions.hasAutoRenewalOn = true;
    }

    /**
     * Set hasServicesOlderThan7Days[appId] flag if there are any bundles with appId older than 7 days / expired
     * @private
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {nothing}
     */
    private setHasServicesOlderThan7Days(bundle: BundleModel): void {
        if (bundle.status === this.subscriptionsValuesService.status.active
            && bundle.server_time - bundle.created <= this.valuesService.SECONDS_IN_A_WEEK) {
            return;
        }

        if (!this.subscriptions.hasServicesOlderThan7Days) {
            this.subscriptions.hasServicesOlderThan7Days = {};
        }

        for (const app of bundle.applications) {
            for (const appId of this.valuesService.sideMenuServices) {
                if ((appId === this.valuesService.appSecurity && this.valuesService.protectionApps.has(app.app_id))
                    || appId === app.app_id) {
                    this.subscriptions.hasServicesOlderThan7Days[appId] = true;
                }
            }
        }
    }

    /**
     * Sets flags for shared subscriptions
     * @private
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     * @returns {void}
     */
    private setHasSharedSubscription(bundle: BundleModel): void {
        if (this.isEligibleForSharingSubscription(bundle) && this.accountOwnerIsPayerForBundle(bundle)) {
            this.subscriptions.hasSharedSubscription = true;

            if (bundle.bundle_id === this.valuesService.bundleATO) {
                this.subscriptions.hasAtoSharedSubscription = true;
            }
        }
    }

    public hasInviteRights(bundle: BundleModel): boolean {
        return this.isEligibleForSharingSubscription(bundle)
            && (this.accountOwnerIsPayerForBundle(bundle)
                || this.profilesService.isOwnerVSBAdmin());
    }

    /**
     * Set if subscription has ATO
     * @public
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     */
    private setHasATO(bundle: BundleModel): void {
        if (bundle.bundle_id === this.valuesService.bundleATO) {
            if (this.accountOwnerIsPayerForBundle(bundle) || this.isSharedStandalonePrimaryBundle(bundle.bundle_id, bundle.service_id, this.valuesService.appATO)) {
                this.subscriptions.hasATO = true;
                if (bundle.status !== this.subscriptionsValuesService.status.active) {
                    this.subscriptions.hasATOExpired = true;
                }
            }
        } else if (!this.valuesService.nonProtectionBundlesWithTechassist.has(bundle.bundle_id)) {
            this.subscriptions.hasExtraProtectionBesidesATO = true;
        }
    }

    /**
     * Checks if bundle is eligible for sharing and if the user has a primary role
     * @param {string} bundleId The id of the bundle to be checked
     * @param {string} serviceId The id of the service to search for
     * @param {string} appId The id of the application to search for
     * @returns {boolean} Returns true if it is a shared standalone primary bundle, false otherwise
     */
    public isSharedStandalonePrimaryBundle(bundleId: string, serviceId: string, appId: string): boolean {
        if (!this.isSharedSubscriptionStandaloneBundle(bundleId)) {
            return false;
        }

        let currentService = null;
        for (const service of this.subscriptions.allPrimaryServices) {
            if (service.service_id === serviceId) {
                currentService = service;
                break;
            }
        }

        if (!currentService) {
            return false;
        }
    
        for (const app of currentService.applications) {
            if (app.app_id === appId) {
                return true;
            }
        }

        return false;
    }

    getNoBundles() {
        return this.subscriptions.allServices.length;
    }

    /**
     * Gets the vsb subscription for a vsb context
     * @returns {BundleModel|null} The vsb subscription, or null if no vsb subscription was found
     */
    public getVSBSubscription(): BundleModel|null {
        for (const bundle of this.subscriptions.filtered.allActiveServices) {
            if (bundle.bundle_id === this.valuesService.bundleVSB) {
                return bundle;
            }
        }
        return null;
    }

    getExpiredBundlePasswordManager() {
        for (const bundle of this.subscriptions.filtered.allServices) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                continue;
            }

            if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appPassManager) && !this.isTrialBundle(bundle)) {
                return bundle;
            }
        }
        return null;
    }

    getExpiredTrialBundlePasswordManager() {
        for (const bundle of this.subscriptions.filtered.allServices) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                continue;
            }

            if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appPassManager) && this.isTrialBundle(bundle)) {
                return bundle;
            }
        }
        return null;
    }

    /**
     * Get standalone bundle that is eligible for sharing the subscription
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} bundleId The id of the bundle
     * @returns {BundleModel | null} The bundle object, or null if no eligible bundle was found
     */
    public getStandaloneBundleEligibleForSharing(bundleId: string): BundleModel|null {
        const services = this.getAllFilteredServices();
        for (const bundle of services) {
            if (this.isEligibleForSharingSubscription(bundle)
                && bundle.bundle_id === bundleId
                && bundle.status !== this.subscriptionsValuesService.status.expired) {
                return bundle;
            }
        }
        return null;
    }

    /**
     * Get bundle with app id and shared subscription
     * @public
     * @param {string} appId
     * @param {string} accountRole
     * @returns {BundleModel | null} bundle or null if bundle does not exist
     */
    public getSharedSubscriptionWithAppId(appId: string, accountRole?: string): BundleModel|null {
        let services = this.getFilteredActiveBundlesByAccountRole(accountRole);

        for (const bundle of services) {
            if (!this.isEligibleForSharingSubscription(bundle)) {
                continue;
            }

            for (const app of bundle.applications) {
                if (app.app_id === appId) {
                    return bundle;
                }
            }
        }
        return null;
    }

    /**
     * Gets active ATO subscription if exists
     * @public
     * @memberof SubscriptionsService
     * @returns {BundleModel} The ATO subscription
     */
    public getAtoSubscription(): BundleModel {
        for (const bundle of this.getActiveBundles()) {
            if (bundle.bundle_id === this.valuesService.bundleATO) {
                return bundle;
            }
        }
        return null;
    }

    /**
     * Gets the number of total and active slots for webmailprotection apps
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {WebmailProtectionSlots} The object that contains the number of total and active slots
     */
    public getWebmailProtectionSlots(): WebmailProtectionSlots {
        const services = this.getActiveBundles();
        const slots: WebmailProtectionSlots = {
            total: 0,
            active: 0
        };

        for (const bundle of services) {
            for (const app of bundle.applications) {
                if (app.app_id === this.valuesService.appWebmailProtection) {
                    slots.total += app.slots.max;
                }
            }

            for (const account of bundle.accounts) {
                const usageSummary = account.usage_summary;
                for (const summary of usageSummary) {
                    if (summary.app_id === this.valuesService.appWebmailProtection) {
                        slots.active++;
                    }
                }
            }
        }

        return slots;
    }

    setArrInfo(bundle) {
        // Used for shorter conditions
        const check = {
            // auto renew
            autoRenew:      bundle?.metadata?.auto_renew,
            // billing cycle
            billingCycle:   bundle?.metadata?.hasOwnProperty('billing_cycle'),
            // billing date
            billingDate:   bundle?.metadata?.hasOwnProperty('billing_date'),
            // end date
            endDate:        bundle?.hasOwnProperty('end_date'),
            // source type
            isZuora:        this.isZuora(bundle),
            isAvangate:     this.isAvangate(bundle),
            isDigitalRiver: this.isDigitalRiver(bundle),
            isVerifone:     this.isVerifone(bundle),
            isGooglePlay:   this.isGooglePlay(bundle),
            isApple:        this.isApple(bundle),
            isOffline:      this.isOffline(bundle),
            // service type
            isSubscription: bundle.service_type === this.subscriptionsValuesService.serviceType.subscription,
            isLicense:      bundle.service_type === this.subscriptionsValuesService.serviceType.license,
            // life cycle
            isRecurrent:    bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.recurrent,
            isOneTime:      bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.one_time,
            isTrial:        bundle.type === this.subscriptionsValuesService.type.trial
        };

        if (!check.isTrial
            && ((check.isLicense && check.isAvangate && check.endDate && check.autoRenew)
                || (check.isLicense && check.isDigitalRiver && check.isOneTime && check.endDate && check.autoRenew)
                || (check.isSubscription && check.isApple && check.isRecurrent && (check.billingCycle || check.billingDate) && check.autoRenew && !check.endDate)
                || (check.isSubscription && check.isGooglePlay && check.isRecurrent && (check.billingCycle || check.billingDate) && check.autoRenew && !check.endDate)
                || (check.isSubscription && check.isRecurrent && check.billingCycle
                    && (check.isZuora || check.isVerifone)
                    && (check.autoRenew === true || check.autoRenew === undefined)))) {
            bundle.processed.arrON = true;
        }

        if (!bundle?.processed?.arrON
            && !check.isTrial
            && ((check.isLicense && check.isOffline && check.isOneTime && check.endDate)
                || (check.isLicense && check.isAvangate && check.endDate && !check.autoRenew)
                || (check.isLicense && check.isDigitalRiver && check.isOneTime && check.endDate && !check.autoRenew)
                || (check.isSubscription && check.isApple && check.isRecurrent && check.endDate && !check.autoRenew)
                || (check.isSubscription && check.isGooglePlay && check.isRecurrent && check.endDate && !check.autoRenew)
                || (check.isSubscription && check.isRecurrent && check.endDate
                    && (check.isZuora || check.isVerifone)
                    && (check.autoRenew === false || check.autoRenew === undefined)))) {
            bundle.processed.arrOFF = true;
        }
    }

    processSubscriptions() {
        for (const bundle of this.subscriptions.allServices) {

            if (!bundle.processed) {
                bundle.processed = {};
            }

            this.addSharedSubscriptionsInfo(bundle);
            this.setArrInfo(bundle);
            this.setHasBIP(bundle);
            // daca toate sloturile sunt shared, subscriptia actuala e ca si cum nu ar exista pe cont
            if (!bundle.processed.allSlotsAreShared) {
                this.computeVSBService(bundle);
                if (this.isActiveVSBServiceOnNonVSBContext(bundle)) {
                    continue;
                }
                this.setHasPaidSubscription(bundle);
                this.setHasTrialSubscription(bundle);
                this.setHasTSMD(bundle);
                this.setHasBox(bundle);
                this.setHasBox2(bundle);
                this.setHasParental(bundle);
                this.setHasParentalNCC(bundle);
                this.setHasOnlyIdentitySubscriptions(bundle);
                this.setHasOnlySohoSubscriptions(bundle);
                this.setHasDataPrivacy(bundle);
                this.setHasIdTheftProtection(bundle);
                this.setHasATO(bundle);
                this.setHasWebmailProtection(bundle);
                this.setHasSohoSubscription(bundle);
                this.setHasVpn(bundle);
                this.setHasBusinessAssetsExposure(bundle);
                this.setHasPremiumServices(bundle);
                this.setHasWinserver(bundle);
                this.setHasPremiumSecurityPlus(bundle);
                this.setHasUltimateSecurity(bundle);
                this.setHasUltimateSecurityPlus(bundle);
                this.setHasPremiumSecurity(bundle);
                this.setHasPasswordManager(bundle);
                this.setHasPasswordManagerCompatible(bundle);
                this.setHasSharedSubscription(bundle);
                this.processingMethods.first30Days(bundle);
                this.processingMethods.isExtended(bundle);
                this.processingMethods.isMonthlyBundle(bundle);
                this.processingMethods.isSharedBundle(bundle);

                this.setHasAllBundlesAreExpired(bundle);
                this.setHasPaidProtectionBundleExpired(bundle);
                this.setHasFreeProtectionBundleExpired(bundle);
                this.setHasPaidNonprotectionBundleExpired(bundle);
                this.setHasFreeNonprotectionBundleExpired(bundle);
                this.setHasNonTrialBundleExpired(bundle);
                this.setHasMspManagedBundle(bundle);
                this.setHasIndividualPlanWithoutParental(bundle);
                this.setHasClBundle(bundle);
                this.setHasServicesOlderThan7Days(bundle);
            }
        }
    }

    /**
     * Get friendly name of bundle
     * @param app    bundle
     * @returns      friendly name
     */
    getFriendlyName(app) {
        const bundleObj = this.productsConfigService.getBundle(app.bundle_id, app.isSharedSubscription);

        if (bundleObj) {
            const name = bundleObj.name as IName;
            if (name !== null) {
                if (typeof name !== 'object') {
                    return name;
                }
                if (typeof name === 'object' && name.hasOwnProperty('default')) {
                    const lang = this.languageService.getLang();
                    return name.hasOwnProperty(lang) ? name[lang] : name['default'];
                }
                if (typeof name === 'object' && !name.hasOwnProperty('default') && app.app_params.level) {
                    switch (app.app_params.level) {
                        case AppLevels.PREMIUM:
                            return name.premium['default'];
                        case AppLevels.BASIC:
                            return name.basic['default'];
                        default:
                            break;
                    }
                }
            }
        }
    }

    /**
     * Checks if bundle is box
     * @param bundle
     */
    isBox(bundle: BundleModel) {
        return this.valuesService.boxBundles.has(bundle.bundle_id);
    }

    /**
     * Finds one premium app on the bundle and returns true
     * @param bundle
     */
    isPremiumService(bundle: BundleModel) {
        return this.valuesService.techassistProductsDetails[bundle.bundle_id];
    }

    isVPNSubscription(bundle: BundleModel) {
        return this.valuesService.vpnServices.has(bundle.bundle_id);
    }

    isTSSubscription(bundle: BundleModel) {
        return this.valuesService.tsServices.has(bundle.bundle_id);
    }

    isFPSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleFP;
    }

    isAVSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleAV ||  bundle.bundle_id === this.valuesService.bundleAVNEW;
    }

    isISSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleIS;
    }

    isPSSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundlePSec || bundle.bundle_id === this.valuesService.bundlePSecNew;
    }

    isVpnPremium(bundle: BundleModel) {
        const vpnApp = bundle.applications.filter(app => app.app_id === this.valuesService.appVPN)[0];

        if (vpnApp) {
            return vpnApp.app_params.level === AppLevels.PREMIUM;
        } else {
            return false;
        }
    }

    /** Checks if bundle is dpy
     * @param bundle
     */
    isDpy(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleDPI;
    }

    /**
     * Check if bundle has zuora source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isZuora(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.zuora
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.zuora;
    }

    /**
     * Check if bundle has google play source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isGooglePlay(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.gplay
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.gplay;
    }

    /**
     * Check if bundle has apple store source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isApple(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.appstore
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.appstore;
    }

    /**
     * Check if bundle has offline source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isOffline(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.offline
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.offline;
    }

    /**
     * Check if bundle has avangate source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isAvangate(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.avangate
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.avangate;
    }

    /**
     * Check if bundle has digital river source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isDigitalRiver(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.digitalRiver
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.digitalRiver;
    }

    /**
     * Check if bundle has Verifone source type
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     * @returns {boolean} Returns true if the bundle is of type verifone, false otherwise
     */
    public isVerifone(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.verifone
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.verifone;
    }

    /** Checks if bundle is idTheftProtection
     * @param bundle
     */
     isIdTheftProtection(bundle: BundleModel) {
        return this.valuesService.idTheftProtectionServices.has(bundle.bundle_id);
    }

    isUltimateSecurityProtection(bundle: BundleModel) {
        return this.valuesService.ultimateSecurityProtectionServices.has(bundle.bundle_id);
    }

    /** Checks if bundle is password manager
     * @param bundle
     */
    isPasswordManager(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundlePM;
    }

    /** Checks if bundle is secure identity
     * @param bundle
     */
    isSecureIdentity(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleSecIdentity;
    }

    /**
     * Checks if bundle is hva
     * @param bundle
     */
    isHva(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleHVA;
    }

    isSinglePlatformBundle(bundle) {
        return this.valuesService.singlePlatformBundles.has(bundle.bundle_id);
    }

    isMultiPlatformBundle(bundle) {
        return !this.valuesService.singlePlatformBundles.has(bundle.bundle_id);
    }

    setHasSdkLoaded(value) {
        this.subscriptions.hasSdkLoaded = value;
    }

    hasSdkLoaded() {
        return this.subscriptions.hasSdkLoaded;
    }

    /**
     * Account has at least one subscription from Zuora
     */
    setHasZuora() {
        this.subscriptions.hasZuora = !!this.originalSubscriptionsResponse.find(item => this.isZuora(item) && !item?.processed?.sharedInfo?.gainer);
    }

    hasZuora() {
        return this.subscriptions.hasZuora;
    }

    isCountable(appId): number {
        return this.subscriptionsValuesService.uncountableApps.has(appId) ? 0 : 1;
    }

    /**
     * Limits services with unlimited slots to 50 slots
     */
    limitSlots(slots): number {
        return (slots === -1 || slots > this.subscriptionsValuesService.slotsNo.limit) ? this.subscriptionsValuesService.slotsNo.limit : slots;
    }

    filterBundles() {
        const mainPage = {};
        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            mainPage[serviceType] = [];
            for (const bundle of this.subscriptions[serviceType]) {
                if (this.valuesService.knownBundles.has(bundle.bundle_id) && this.showBundleMainPage(bundle)) {
                    mainPage[serviceType].push(bundle);
                }
            }
        }
        this.subscriptions.mainPage = mainPage;
    }

    /**
     * Checks if bundle should be shown in subscriptions main page
     * @param {BundleModel} bundle Bundle object
     * @returns {boolean} True if bundle should be shown, false otherwise
     */
    public showBundleMainPage(bundle: BundleModel): boolean {
        if (bundle.bundle_id === this.valuesService.bundleBIS && this.hasBIP()) {
            return false;
        }
        return true;
    }

    getBundleFromCrc(bundleCrc) {
        if (!bundleCrc) {
            return null;
        }

        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            for (const bundle of this.subscriptions[serviceType]) {
                if (this.valuesService.knownBundles.has(bundle.bundle_id) && bundle?.bundle_crc?.toString() === bundleCrc.toString()) {
                    return bundle;
                }
            }
        }
        return null;
    }

    /**
     * Good god nu inteleg ce vrea functia asta de la viata
     * O sa o lasam asa pt ca mi-e frica
     *
     * Converts bundles from v3 to v4 services
     * @param apps    bundles
     * @return        v4 services
     */
    v3tov4(apps) {
        if (!apps.length || !apps[0].app_id || !apps[0].app_id) {
            return apps;
        }

        const bundles = apps.map( app => app.bundle_id).filter( (item,i,arr)=>arr.indexOf(item) === i);
        const services = [];
        let service:any = {};

        for (let i = 0; i < bundles.length; i++) {
            const tempServices = [];
            let sum_devices = 0;
            for (let j = 0; j < apps.length; j++) {
                if (bundles[i] === apps[j].bundle_id) {
                    if (tempServices.indexOf(apps[j].bundle_id) === -1) {
                        sum_devices += apps[j].active_devices;
                        const friendlyName = this.getFriendlyName(apps[j]);
                        service = {
                            service_id: SubscriptionsV3Prefix?.concat(j?.toString()),
                            service_type: "license",
                            server_time: apps[j].server_time,
                            type: apps[j].type,
                            life_cycle: apps[j].life_cycle,
                            commercial_id: apps[j].commercial_id,
                            commercial_id_info: apps[j].commercial_id_info ? apps[j].commercial_id_info : null,
                            bundle_crc: this.utileCommonService.crc32Convert_old(apps[j].bundle_id),
                            created: apps[j].last_update,
                            end_date: apps[j].expiry,
                            bundle_id: apps[j].bundle_id,
                            bundle_friendly_name: this.productsConfigService.getManufacturerName() + ' ' + friendlyName,
                            friendly_name: friendlyName,
                            slots: this.limitSlots(apps[j].devices),
                            applications: [
                                  {
                                      "countable": this.isCountable(apps[j].app_id),
                                      "slots": {
                                          "min": 1,
                                          "max": apps[j].devices
                                      },
                                      "end_date": apps[j].expiry,
                                      "server_time": apps[j].server_time,
                                      "active_device_ids": apps[j].active_device_ids,
                                      "active_devices" : apps[j].active_devices,
                                      "app_id": apps[j].app_id
                                  }
                            ],
                            status: this.subscriptionsValuesService.status.active,
                            accounts: [
                              {
                                role: "primary",
                                share_id: "V3SHAREID",
                                usage_summary: []
                              }
                            ],
                            active_slots: sum_devices
                          };

                        if (apps[j].hasOwnProperty('ta_quota')) {
                            service.ta_quota = apps[j].ta_quota;
                        }

                        if (apps[j].app_params) {
                            service.applications[0].app_params = apps[j].app_params;
                        }

                    } else {
                        const applications =  {
                            "countable": this.isCountable(apps[j].app_id),
                            "slots": {
                                "min": 1,
                                "max": apps[j].devices
                            },
                            "app_id": apps[j].app_id,
                            "end_date": apps[j].expiry,
                            "server_time": apps[j].server_time,
                            "active_device_ids": apps[j].active_device_ids,
                            "active_devices" : apps[j].active_devices,
                            "app_params":{}
                        };

                        if (apps[j].app_params) {
                            applications.app_params = apps[j].app_params;
                        }


                        service.applications.push(applications);
                    }
                    tempServices.push(apps[j].bundle_id);
                }

                // USAGE SUMMARY:
                if (bundles[i] === apps[j].bundle_id) {
                    for (let k = 0; k < apps[j]?.active_device_ids?.length; k++) {
                        service.accounts[0].usage_summary.push({
                            "app_id": apps[j].app_id,
                            "slot_id": apps[j].active_device_ids[k],
                            "countable": this.isCountable(apps[j].app_id)
                        })
                    }
                }
            }

            if (this.productsConfigService.getBundle(service.bundle_id, service.isSharedSubscription)) {
                services.push(service);
            }
        }
        return services;
    }

    /**
     * Switch device service
     * @param configObject
     */
    switchDeviceService(configObject): Observable<DeviceAllocationMgmtResponseModel[]> {
        return this.subscriptionMgmtService.switchDeviceService(configObject)
        .pipe(map(resp => resp));
    }

    /**
     * Remove device protection
     * @param configObject
     */
    removeDeviceProtection(configObject):Observable<DeviceAllocationMgmtResponseModel[]> {
        return this.subscriptionMgmtService.removeDeviceProtection(configObject)
        .pipe(map(resp => resp));
    }

    sortAllServices(tempSubscriptions) {
        const isMsp = this.profilesService.hasMspOrXspLevelTwo();
        if (isMsp) {
            this.sortMspBundles(tempSubscriptions[this.subscriptionsValuesService.serviceType.subscription]);
            this.sortMspBundles(tempSubscriptions[this.subscriptionsValuesService.serviceType.license]);
            return;
        }
        this.sortSubscriptions(tempSubscriptions);
        this.sortLicenses(tempSubscriptions);
    }
    /**
     * Sort subscriptions ASC by billing date
     */
    sortSubscriptions(tempSubscriptions) {
        tempSubscriptions[this.subscriptionsValuesService.serviceType.subscription]
        .sort((subsciption1, subscription2) => subsciption1.metadata.billing_date - subscription2.metadata.billing_date);
    }

    /**
     * Sort licenses ASC by end date
     */
    sortLicenses(tempSubscriptions) {
       tempSubscriptions[this.subscriptionsValuesService.serviceType.license]
        .sort((subscription1, subscription2) => (subscription1.end_date ? parseInt(subscription1.end_date) : 0) - (subscription2.end_date ? parseInt(subscription2.end_date) : 0))
        .sort((subscription1, subscription2) => {
                if ((subscription1.type === 'end_user' || subscription1.type === 'trial')
                    && (subscription2.type !== 'end_user' && subscription2.type !== 'trial')) {
                    return -1;
                }
                if ((subscription1.type !== 'end_user' && subscription1.type !== 'trial')
                    && (subscription2.type === 'end_user' || subscription2.type === 'trial')) {
                    return 1;
                }
            }
        );
    }

    sortMspBundles(bundles) {
        bundles.sort((bundle1, bundle2) => {
            const b1 = this.isManagedByOrganization(bundle1);
            const b2 = this.isManagedByOrganization(bundle2);

            if (b1 && !b2) {
                return 1;
            } else if (!b1 && b2) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    /**
     * Show expired services first
     */
    showExpiredLicensesFirst(tempSubscriptions) {
        tempSubscriptions[this.subscriptionsValuesService.serviceType.license]
        .sort(
            (subscription1, subscription2) => {
                if ((subscription1.end_date - subscription1.server_time) <= 0
                    && (subscription2.end_date - subscription2.server_time) >= 0) {
                    return -1;
                }
                if ((subscription2.end_date - subscription2.server_time) <= 0
                    && (subscription1.end_date - subscription1.server_time) >= 0) {
                    return 1;
                }
            }
        );
    }

    removeExpired(resp) {
        return resp.filter(item => !(item.status === this.subscriptionsValuesService.status.expired
                                    && resp.some(elem => elem.bundle_id === item.bundle_id && elem.status === this.subscriptionsValuesService.status.active)));
    }

    removeExpiredFree(resp) {
        return resp.filter(item => !(item.type === this.subscriptionsValuesService.type.free
                                    && item.status === this.subscriptionsValuesService.status.expired));
    }

    /**
     * Filter expired trials - only if there is another service with the same service_id that is not a trial
     */
    removeExpiredTrial(resp) {
        return resp.filter(item => !(item.status === this.subscriptionsValuesService.status.expired
                                    && item.type === this.subscriptionsValuesService.type.trial
                                    && resp.some(elem => elem.bundle_id === item.bundle_id && elem.type !== this.subscriptionsValuesService.type.trial)));
    }

    createTempStructures() {
        const tempSubscriptions = {
            [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
            [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
            allServices: [], // licenses + subscriptions
            allActiveServices: [], // only active licenses + subscriptions
            allPrimaryServices: [],
            allSecondaryServices: [],
            filtered: { // filter subscriptions with all slots shared by gainers - so current slots = 0
                [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
                [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
                allServices: [], // filtered licenses + subscriptions
                allActiveServices: [], // only active filtered licenses + subscriptions
                allPrimaryServices: [],
                allSecondaryServices: []
            },
            subscriptionsObject: {}, // object key - value: service_id - service object
            activeBundleIds: new Set(),
            hasZuora: false,
            hasPaidCoreBundleExpired: false,
            hasFreeCoreBundleExpired: false,
            hasPaidNonprotectionBundleExpired: false,
            hasFreeNonprotectionBundleExpired: false,
            hasNonTrialBundleExpired: false,
            hasAllBundlesAreExpired: true
        };
        return tempSubscriptions;
    }

    copyArray(subscriptionsArray, subscriptionsObject) {
        const newSubscriptionsArray = [];
        for (const service of subscriptionsArray) {
            const serviceId = service.service_id;
            const newService = subscriptionsObject[serviceId];
            newSubscriptionsArray.push(newService);
        }
        return newSubscriptionsArray;
    }

    copyFromTempToFinalSubscriptions() {
        const newSubscriptions = {
            [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
            [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
            allServices: [], // licenses + subscriptions
            allActiveServices: [], // only active licenses + subscriptions
            filtered: { // filter subscriptions with all slots shared by gainers - so current slots = 0
                [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
                [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
                allServices: [], // filtered licenses + subscriptions
                allActiveServices: [] // only active filtered licenses + subscriptions
            },
            subscriptionsObject: {}, // object key - value: service_id - service object
            hasZuora: false,
            hasPaidCoreBundleExpired: false,
            hasFreeCoreBundleExpired: false,
            hasPaidNonprotectionBundleExpired: false,
            hasFreeNonprotectionBundleExpired: false,
            hasNonTrialBundleExpired: false,
            hasAllBundlesAreExpired: true
        };

        for (const service of this.tempSubscriptions.allServices) {
            const newService = {...service};
            newSubscriptions.subscriptionsObject[newService.service_id] = newService;
            newSubscriptions.allServices.push(newService);
        }
        this.copyArray(this.tempSubscriptions[this.subscriptionsValuesService.serviceType.license], this.tempSubscriptions);

    }

    retrieveServiceByServiceId(serviceId): BundleModel {
        return this.subscriptions.subscriptionsObject[serviceId];
    }

    resetFilteredTempStrunctures() {
        this.tempSubscriptions.filtered = { // filter subscriptions with all slots shared by gainers - so current slots = 0
            [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
            [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
            allServices: [], // licenses + subscriptions
            allActiveServices: [],
            allPrimaryServices: [],
            allSecondaryServices: []
        };
    }

    /**
     * List bundles (subscriptions & licenses) (v4 response)
     * @return {Observable<any>} bundles
     */
    list(): Observable<any> {
        if (!this.markToUpdate_subscriptions) {
            return of(this.subscriptions);
        }

        if (this.onlistSubscriptions$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSubscriptions$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistSubscriptions$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.subscriptionMgmtService.list()
            .pipe(
                map(
                    resp => {

                        // creez o structura locala pe care o populez si apoi pun tot rezultatul intr-o structura globala
                        // care memoreaza datele si le mentine pt aplicatie
                        const tempSubscriptions = this.createTempStructures();
                        this.originalSubscriptionsResponse = resp;
                        if (resp.length === 0) {
                            this.subscriptions = tempSubscriptions;
                            this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                            this.markToUpdate_subscriptions = false;
                            return of(this.subscriptions);
                        }

                        resp = this.limitAllSlots(resp);
                        resp = this.v3tov4(resp);
                        resp = this.removeExpiredFree(resp);
                        resp = this.removeExpiredTrial(resp);
                        resp = this.removeExpired(resp);

                        this.populatesubscriptionsStructures(resp, tempSubscriptions);
                        this.sortAllServices(tempSubscriptions);
                        this.showExpiredLicensesFirst(tempSubscriptions);

                        this.subscriptions = tempSubscriptions;
                        this.markToUpdate_subscriptions = false;
                        this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                        return of(this.subscriptions);
                    },
                    err => {
                        this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                        return of(err);
                    }
                ),
                catchError( err => {
                    this.markToUpdate_subscriptions = true;
                    this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    updateSubscriptions() {
        if (this.onlistSubscriptions$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_subscriptions = true;
        }
    }

    limitAllSlots(subscriptions) {
        subscriptions.forEach(bundle => {
            bundle.slots = this.limitSlots(bundle.slots);
        });
        return subscriptions;
    }

    addGeneralSharedSubscriptionsInfo(bundle) {
        const accounts = bundle?.accounts;
        if (!accounts) {
            return;
        }

        let sharedSLots = 0;
        for (const account of accounts) {
            if (account.quota && this.profilesService.getOwnerEmail() !== account.email) {
                sharedSLots += account.quota;
            }

            if (account.quota && this.profilesService.getOwnerEmail() === account.email) {
                bundle.slots = account.quota;
                bundle.processed.allSlotsAreShared = false;
                return;
            }
        }

        bundle.slots -= sharedSLots;
        if (bundle.slots === 0 && sharedSLots) {
            bundle.processed.allSlotsAreShared = true;
        }
    }

    /**
     * Compute shared subscription info
     *
     * @private
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {nothing}
     */
    private addSharedEntitiesSubscription(bundle: BundleModel): void {
        const sharedInfo: SharedSubscriptionSharedInfo = {
            isShared: false,
            payer: null,
            gainer : null,
            isPayer: false,
            gainers: [],
            isAdmin: false
        };

        if (bundle.service_type !== this.subscriptionsValuesService.serviceType.subscription && !this.isEligibleForSharingSubscription(bundle)) {
            bundle.processed.sharedInfo = {};
            return;
        }

        const accounts = bundle?.accounts ?? [];
        for (const account of accounts) {
            if (account.role === this.subscriptionsValuesService.accountType.secondary) {
                sharedInfo.isShared = true;
                if (account.email === this.profilesService.getOwnerEmail()) {
                    sharedInfo.gainer = account;
                }

                sharedInfo.gainers.push({
                    email: account.email,
                    slots: account?.quota,
                    shareId: account?.share_id,
                    role: account?.additional_info?.age_group,
                    usage: account?.usage_summary ?? []
                });
            }

            if (account.role === this.subscriptionsValuesService.accountType.primary) {
                if (account.email === this.profilesService.getOwnerEmail()) {
                    sharedInfo.isPayer = true;
                }
                sharedInfo.payer = account;
            }
        }
        bundle.processed.sharedInfo = sharedInfo;
    }

    recomputeActiveSlots(bundle) {
        if (!bundle?.processed?.sharedInfo?.isShared) {
            return;
        }

        const slots = new Set();
        const accounts = bundle?.accounts;

        if (!accounts) {
            bundle.active_slots = 0;
        }

        for (const account of accounts) {
            const usages = account?.usage_summary;
            if (account.email !== this.profilesService.getOwnerEmail() || !usages) {
                continue;
            }

            for (const usage of usages) {
                if (usage?.countable && usage?.slot_id) {
                    slots.add(usage.slot_id);
                }
            }
        }

        bundle.active_slots = Array.from(slots).length;
    }

    addSharedSubscriptionsInfo(bundle) {
        if (!bundle.processed) {
            bundle.processed = {};
        }
        this.addGeneralSharedSubscriptionsInfo(bundle); // this might need adjustments
        this.addSharedEntitiesSubscription(bundle);

        // recalcularea se face pt ca momentan datele din backend nu sunt consistente
        // se poate scoate daca se rezolva problema pt subscriptiile shared
        this.recomputeActiveSlots(bundle);
    }


    populatesubscriptionsStructures(services, tempSubscriptions) {
        tempSubscriptions.allServices = services;
        for (const service of services) {
            if (service.service_type === this.subscriptionsValuesService.serviceType.license) {
                tempSubscriptions[this.subscriptionsValuesService.serviceType.license].push(service);
            }

            if (service.service_type === this.subscriptionsValuesService.serviceType.subscription) {
                tempSubscriptions[this.subscriptionsValuesService.serviceType.subscription].push(service);
            }

            if (service.status !== this.subscriptionsValuesService.status.expired) {
                tempSubscriptions.allActiveServices.push(service);
                tempSubscriptions.activeBundleIds.add(service.bundle_id);
            }

            let serviceAccountRole;
            for (const account of service.accounts) {
                if (account.email === this.profilesService.getOwnerEmail()) {
                    serviceAccountRole = account.role;
                    break;
                }
            }
            if (serviceAccountRole === this.subscriptionsValuesService.accountType.primary) {
                tempSubscriptions.allPrimaryServices.push(service);
            } else if (serviceAccountRole === this.subscriptionsValuesService.accountType.secondary) {
                tempSubscriptions.allSecondaryServices.push(service);
            }

            tempSubscriptions.subscriptionsObject[service.service_id] = service;
        }
    }

    populateFilteredSubscriptionsStructures() {
        this.resetFilteredTempStrunctures();
        for (const service of this.subscriptions.allServices) {
            if (!service.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered.allServices.push(service);
            }
        }

        for (const service of this.subscriptions.allActiveServices) {
            if (!service.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered.allActiveServices.push(service);
            }
        }

        for (const service of this.subscriptions.allPrimaryServices) {
            if (!service.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered.allPrimaryServices.push(service);
            }
        }

        for (const service of this.subscriptions.allSecondaryServices) {
            if (!service.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered.allSecondaryServices.push(service);
            }
        }

        for (const license of this.subscriptions[this.subscriptionsValuesService.serviceType.license]) {
            if (!license.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered[this.subscriptionsValuesService.serviceType.license].push(license);
            }
        }

        for (const subscription of this.subscriptions[this.subscriptionsValuesService.serviceType.subscription]) {
            if (!subscription.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered[this.subscriptionsValuesService.serviceType.subscription].push(subscription);
            }
        }
        this.subscriptions.filtered = this.tempSubscriptions.filtered;
    }

    /**
     * This method is used to get the bundle by serviceId
     * @param {string} serviceId
     * @returns {object} bundle
     */
    getBundleByServiceId(serviceId): BundleModel {
        const services = this.getAllServices();
        return services ? services.filter(bundle => bundle.service_id === serviceId)[0] : null;
    }

    processingMethods = {

        first30Days: (bundle: BundleModel) => {
            let firstMonth;
            const {month, day} = this.subscriptionsValuesService;
            const serverTime        = bundle.server_time;
            const platformStartDate = this.usefulService.getNested(bundle, undefined, 'metadata', 'platform_sub_start_date');
            const billingDate       = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_date');
            const billingCycle      = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_cycle');

            if (platformStartDate) {
                firstMonth = serverTime - platformStartDate <= month;
            } else if (billingDate && billingCycle) {
                firstMonth = serverTime - (billingDate - billingCycle * day) <= month;
            }

            bundle.processed.firstMonth = firstMonth;
        },

        isExtended: (bundle: BundleModel) => {
            const billingDate  = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_date');
            const billingCycle = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_cycle');
            if (billingDate && billingCycle) {
                const createdMoment = moment.unix(bundle.created).format('YYYY-MM-DD');
                const billingMoment = moment.unix(billingDate).format('YYYY-MM-DD');
                const billingCycleDays = moment(billingMoment).diff(moment(createdMoment), 'days');
                bundle.processed.isExtended = billingCycle < billingCycleDays - 1;
            }
        },

        isMonthlyBundle: (bundle: BundleModel) => {
            bundle.processed.isMonthly = bundle?.metadata?.billing_period === this.subscriptionsValuesService.cycles.monthlyBillingPeriod
                                        || bundle?.metadata?.billing_cycle === this.subscriptionsValuesService.monthDays;
        },

        isSharedBundle: (bundle: BundleModel) => {
            bundle.processed.isSharedBundle = this.isEligibleForSharingSubscription(bundle);
        }
    };

    /**
     * This method is used to decide if the invite flow is on for the VSB bundle
     * @param {BundleModel} bundle The bundle tha might have the invite flow
     * @returns {boolean} Returns true if the bundle meets the conditions for the invite flow, false otherwise
     */
    public meetsVSBConditionForInviteFlow(bundle: BundleModel): boolean {
        return bundle.bundle_id === this.valuesService.bundleVSB && this.profilesService.isOwnerVSBAdmin();
    }

    /**
     * Get bundles (subscriptions & licenses) (v4 response)
     */
    get() {
        return this.subscriptions;
    }

    getMainPageBundles() {
        return this.subscriptions.mainPage;
    }

     /**
     * Get bundles (subscriptions & licenses) (v4 response)
     */
      getFiltered() {
        return this.subscriptions.filtered;
    }

    /**
     * Get bundles (v4 response)
     */
    getAllServices() {
        return this.subscriptions.allServices;
    }

    getAllFilteredServices() {
        return this.subscriptions.filtered.allServices;
    }

    getAllActiveServices() {
        return this.subscriptions.allServices.filter(service => service.status === this.subscriptionsValuesService.status.active);
    }

    getAllFilteredActiveServices() {
        return this.subscriptions.filtered.allServices.filter(service => service.status === this.subscriptionsValuesService.status.active);
    }

    getBoxSubscriptionStatus(boxId: string) {
        for (const bundle of this.subscriptions.allServices) {
            if (!this.valuesService.boxBundles.has(bundle.bundle_id) || !bundle?.accounts) {
                continue;
            }

            for (const account of bundle.accounts) {
                if (this.profilesService.getOwnerEmail() !== account.email) {
                    continue;
                }
                if (account?.usage_summary) {
                    for (const usage of account?.usage_summary) {
                        if (this.valuesService.boxApps.has(usage?.app_id) && usage?.slot_id === boxId) {
                            return bundle.status;
                        }
                    }
                }
            }

        }
        return undefined;
    }

    /**
     * Get subscriptions
     */
    getSubscriptions() {
        return this.subscriptions[this.subscriptionsValuesService.serviceType.subscription];
    }

    /**
     * Get licenses
     */
    getLicenses() {
        return this.subscriptions[this.subscriptionsValuesService.serviceType.license];
    }

    getCurrency() {
        let currency;
        const defaultCurrency = this.subscriptionsValuesService.defaultCurrency;
        const subscriptions = this.getSubscriptions();
        if (subscriptions.length) {
            const subsWithCurrency = subscriptions.filter(item => item.metadata && item.metadata.platform_currency);
            currency = subsWithCurrency.length ? subsWithCurrency[0].metadata.platform_currency : defaultCurrency;
        } else {
            currency = defaultCurrency;
        }
        return currency;
    }

    /**
     * Returns all services with status "ACTIVE" & type "end_user"
     */
    getPaidServices() {
        // toate serviciile platite de ownerul contului
        // daca ownerul e gainer, nu plateste serviciul respectiv
        const paidServices = [];
        for (const service of this.subscriptions.allServices) {
            if (service.status === this.subscriptionsValuesService.status.active && service.type === 'end_user' && !service?.processed?.sharedInfo?.gainer) {
                paidServices.push(service);
            }
        }
        return paidServices;
    }

    /**
     * Get all security single platform services
     */
    getSecuritySinglePlatformServices() {
        const singlePlatformServices = [];
        for (const bundle of this.getActiveBundles()) {
            if (bundle.isSinglePlatformSubscription && bundle.type !== this.subscriptionsValuesService.type.nfr &&
                bundle.type !== this.subscriptionsValuesService.type.free && this.valuesService.securityBundles.has(bundle.bundle_id)) {
                    singlePlatformServices.push(bundle);
            }
        }
        return singlePlatformServices;
    }

    /**
     * Get all security single platform services
     */
    getSecurityMultiPlatformServices() {
        const multiPlatformServices = [];
        for (const bundle of this.getActiveBundles()) {
            if (bundle.isMultiPlatformSubscription && bundle.type !== this.subscriptionsValuesService.type.nfr &&
                bundle.type !== this.subscriptionsValuesService.type.free && this.valuesService.securityBundles.has(bundle.bundle_id)) {
                    multiPlatformServices.push(bundle);
            }
        }
        return multiPlatformServices;
    }

    /*
    * Get all security bundles
    */
   getSecurityBundles() {
       const bundlesArr = [];
        for (const bundle of this.getActiveBundles()) {
            if (bundle.type !== this.subscriptionsValuesService.type.nfr && bundle.type !== this.subscriptionsValuesService.type.free &&
                this.valuesService.securityBundles.has(bundle.bundle_id)) {
                    bundlesArr.push(bundle);
            }
        }
        return bundlesArr;
   }

    /**
     * Get bundle image
     */
    getImage = function(bundle) {
        const lang = this.profilesService.getOwner().lang;
        const bundleObj = this.productsConfigService.getBundle(bundle.bundle_id, bundle?.processed?.isSharedBundle);

        if (bundleObj) {
            let pic = bundleObj['pic'];
            if (pic) {
                switch (typeof pic) {
                    case 'string': {
                        break;
                    }
                    case 'object': {
                        pic = pic.hasOwnProperty(lang) ? pic[lang] : pic['default'];
                        break;
                    }
                    default: {
                        return '';
                    }
                }
            }
            return pic;
        } else {
            return '';
        }
    };

    daysRemaining(bundle) {
        let result;
        if (bundle.end_date) {
            result = parseInt(((bundle.end_date - bundle.server_time) / this.valuesService.SECONDS_IN_A_DAY).toString());
        }
        return result;
    }

    /**
     * Returns bundles with status !== 'EXPIRED'
     */
    getActiveBundles() {
        return this.subscriptions.allActiveServices;
    }

    getFilteredActiveBundles() {
        return this.subscriptions.filtered.allActiveServices;
    }

    /**
     * Gets active filtered licenses and subscriptions where the user has a primary role
     * @public
     * @memberof SubscriptionsService
     * @returns {BundleModel[]} An array with all active primary bundles
     */
    public getFilteredActivePrimaryBundles(): BundleModel[] {
        const filteredBundles = [];
        for (const bundle of this.subscriptions.filtered.allPrimaryServices) {
            if (bundle.status !== this.subscriptionsValuesService.status.expired) {
                filteredBundles.push(bundle);
            }
        }
        return filteredBundles;
    }

    /**
     * Gets active filtered licenses and subscriptions where the user has a secondary role
     * @public
     * @memberof SubscriptionsService
     * @returns {BundleModel[]} An array with all active secondary bundles
     */
    public getFilteredActiveSecondaryBundles(): BundleModel[] {
        const filteredBundles = [];
        for (const bundle of this.subscriptions.filtered.allSecondaryServices) {
            if (bundle.status !== this.subscriptionsValuesService.status.expired) {
                filteredBundles.push(bundle);
            }
        }
        return filteredBundles;
    }

    /**
     * Get secondary bundle based on given service id
     * @public
     * @memberof SubscriptionsService
     * @param {string} serviceId The service id to search for
     * @returns {BundleModel} Returns the bundle found, or null
     */
    public getSecondaryBundleByServiceId(serviceId: string): BundleModel {
        for (const bundle of this.subscriptions.filtered.allSecondaryServices) {
            if (bundle.service_id === serviceId) {
                return bundle;
            }
        }
        return null;
    }

    /**
     * Gets active filtered licenses and subscriptions depending on user account role
     * @public
     * @param {string} accountRole
     * @returns {BundleModel[]}
     */
    public getFilteredActiveBundlesByAccountRole(accountRole?: string): BundleModel[] {
        if (accountRole) {
            if (accountRole === this.subscriptionsValuesService.accountType.primary) {
                return this.getFilteredActivePrimaryBundles();
            } else if (accountRole === this.subscriptionsValuesService.accountType.secondary) {
                return this.getFilteredActiveSecondaryBundles();
            }
        }
        return this.getFilteredActiveBundles();
    }

    /**
     * Returns an array with commercial ids
     */
    getCommercialIds() {
        // daca ownerul e gainer, nu plateste serviciul respectiv
        // deci nu are rost sa ii afisam oferte referitoare la acest serviciu
        const commercialIds = new Set();
        for (const service of this.subscriptions.allServices) {
            if (service.commercial_id && !service?.processed?.sharedInfo?.gainer) {
                commercialIds.add(service.commercial_id);
            }
        }
        return Array.from(commercialIds);
    }

    /**
     * Computes the array of device ids that occupy slots on the bundle
     * @public
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle Bundle object
     * @returns {Array} An array of device ids
     */
    public getActiveDevices(bundle: BundleModel): Array<string> {
        const devices = [];

        if (!bundle?.accounts) {
            return devices;
        }

        bundle.accounts.forEach(account => {
            if (account?.usage_summary) {
                account.usage_summary.forEach(usage => {
                    if (usage.slot_id
                        && devices.indexOf(usage.slot_id) === -1
                        && !this.valuesService.boxApps.has(usage.app_id)
                        && !this.valuesService.appsNotShownInManageDeviceAllocation.has(usage.app_id)) {
                        devices.push(usage.slot_id);
                    }
                });
            }
        });
        return devices;
    }

    hasPaidSubscription(): boolean {
        return !!this.subscriptions.hasPaidSubscription;
    }

    hasTrialSubscription(): boolean {
        return !!this.subscriptions.hasTrialSubscription;
    }

    hasParental(): boolean {
        return !!this.subscriptions.hasParental;
    }

    hasParentalNCCPremium(): boolean {
        return !!this.subscriptions.hasParentalNCCPremium;
    }

    hasParentalNCCBasic(): boolean {
        return !!this.subscriptions.hasParentalNCCBasic;
    }

    hasParentalNCC(): boolean {
        return this.hasParentalNCCPremium() || this.hasParentalNCCBasic();
    }

    hasClSubscriptions(): boolean {
        return !!this.subscriptions.hasClSubscription;
    }

    hasDataPrivacy(): boolean {
        return !!this.subscriptions.hasDataPrivacy;
    }

    /**
     * Check if user has a business assets app on the account
     * @returns {boolean} True if the user has a business assets app on the account
     */
    public hasBusinessAssetsExposure(): boolean {
        return !!(this.subscriptions.hasBusinessAssetsExposure || this.subscriptions.hasBusinessAssetsExposureExpired);
    }

    hasIdTheftProtection(): boolean {
        return !!this.subscriptions.hasIdTheftProtection;
    }

     /**
     * Gets 'hasWebmailProtection' subscription flag
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {boolean} is user has webmail protection
     */
    public hasWebmailProtection(): boolean {
        return this.subscriptions.hasWebmailProtection;
    }

    /**
     * Gets the expired Data Privacy subscription flag
     * @returns true if the user has only expired Data Privacy subscriptions
     */
    hasDataPrivacyExpired() {
        return this.subscriptions.hasDataPrivacyExpired && !this.subscriptions.hasDataPrivacy;
    }

    hasTSMD(): boolean {
        return !!this.subscriptions.hasTSMD;
    }

    hasTSMDExpired(): boolean {
        return !!this.subscriptions.hasTSMDExpired;
    }

    hasBox(): boolean {
        return !!this.subscriptions.hasBox;
    }

    hasBoxExpired(): boolean {
        return !!this.subscriptions.hasBoxExpired;
    }

    hasBox2(): boolean {
        return !!this.subscriptions.hasBox2;
    }

    hasBox2Expired(): boolean {
        return !!this.subscriptions.hasBox2Expired;
    }

    hasVpn(): boolean {
        return !!this.subscriptions.hasVpn;
    }

    hasPremiumVpn(): boolean {
        return !!this.subscriptions.hasPremiumVpn;
    }

    hasPasswordManagerExpired(): boolean {
        return !!this.subscriptions.hasPasswordManagerExpired;
    }

    hasPasswordManagerTrialExpired(): boolean {
        return this.subscriptions.hasPasswordManagerTrialExpired === true;
    }

    hasPasswordManager(): boolean {
        return !!this.subscriptions.hasPasswordManager;
    }

    hasPasswordManagerCompatible(): boolean {
        return !!(this.subscriptions.hasPasswordManagerThreeMonthsCompatible || this.subscriptions.hasPasswordManagerOneMonthCompatible);
    }

    hasPasswordManagerThreeMonthsCompatible(): boolean {
        return !!this.subscriptions.hasPasswordManagerThreeMonthsCompatible;
    }

    hasPasswordManagerOneMonthCompatible(): boolean {
        return !!this.subscriptions.hasPasswordManagerOneMonthCompatible;
    }

    /**
     * Check if user has any bundles with appId older than 7 days / expired
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} appId
     * @returns {boolean}
     */
    public hasServicesOlderThan7Days(appId: string): boolean {
        return !!this.subscriptions?.hasServicesOlderThan7Days?.[appId];
    }

    /**
     * Check if a password manager subscription exists or if a compatible subscriptions exists
     * @returns {boolean} true if a password manager subscription exists or if a compatible subscriptions exists
     */
    public hasPasswordManagerOrCompatibleBundle(): boolean {
        return !!(this.hasPasswordManager() || this.hasPasswordManagerCompatible());
    }

    /**
     * Check if an expired password manager subscription exists or if a trial subscriptions exists
     * @returns {boolean} true if an expired password manager subscription exists or if a trial subscriptions exists
     */
    public hasExpiredPasswordManagerOrTrial(): boolean {
        return !!(this.hasPasswordManagerExpired() || this.hasPasswordManagerTrialExpired());
    }

    /**
     * Check if managed msp subscription exists
     * @public
     * @memberof SubscriptionMgmtService
     * @returns {boolean} The value for 'hasMspManagedBundle' flag
     */
    public hasMspManagedBundle(): boolean {
        return !!this.subscriptions.hasMspManagedBundle;
    }

    /**
     * Check if user has shared subscription
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {Boolean}
     */
    public hasSharedSubscription(): boolean {
        return !!this.subscriptions.hasSharedSubscription;
    }

    /**
     * Checks if the user has a shared ATO subscription
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {boolean} Returns the value of the flag
     */
    public hasAtoSharedSubscription(): boolean {
        return !!this.subscriptions.hasAtoSharedSubscription;
    }

    /**
     * Check if user has active primary bundle with app id
     * @public
     * @param {string} appId
     * @returns {boolean} true if user has primary bundle, false otherwise
     */
    public hasActivePrimaryBundle(appId: string): boolean {
        for (const bundle of this.getFilteredActivePrimaryBundles()) {
            for (const app of bundle.applications) {
                if (app.app_id === appId) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check if premium service subscription exists
     * @param {nothing}
     * @returns {boolean} representing the value for 'hasPremiumServices' flag
     */
    hasPremiumServices(): boolean {
        return !!this.subscriptions.hasPremiumServices;
    }

    /**
     * Check if Premium Security Plus bundle exists
     * @public
     * @memberof SubscriptionsService
     * @returns {boolean} True if PSecPlus bundle exists
     */
    public hasPremiumSecurityPlus(): boolean {
        return !!this.subscriptions.hasPremiumSecPlus;
    }

    /**
     * Check if Premium Security bundle exists
     * @public
     * @memberof SubscriptionsService
     * @returns {boolean} True if PSec bundle exists
     */
    public hasPremiumSecurity(): boolean {
        return !!this.subscriptions.hasPremiumSec;
    }

    /**
     * Check if Ultimate Security Plus bundle exists
     * @public
     * @memberof SubscriptionsService
     * @returns {boolean} True if UltimateSecPlus bundle exists
     */
    public hasUltimateSecurityPlus(): boolean {
        return !!this.subscriptions.hasUltimateSecPlus;
    }

    /**
     * Check if Ultimate Security bundle exists
     * @public
     * @memberof SubscriptionsService
     * @returns {boolean} True if UltimateSec bundle exists
     */
    public hasUltimateSecurity(): boolean {
        return !!this.subscriptions.hasUltimateSec;
    }

    hasOnlyIdentitySubscriptions(): boolean {
        return this.subscriptions.hasOnlyIdentitySubscriptions !== false;
    }

    hasOnlySohoSubscriptions(): boolean {
        return this.subscriptions.hasOnlySohoSubscriptions !== false;
    }

    hasSohoSubscription(): boolean {
        return !!this.subscriptions.hasSohoSubscription;
    }

    hasSohoSubscriptionExpired(): boolean {
        return !!this.subscriptions.hasSohoSubscriptionExpired;
    }

    hasNoSubscriptions(): boolean {
        return this.getAllServices().length === 0;
    }

    hasNoActiveSubscriptions(): boolean {
        return this.getAllActiveServices().length === 0;
    }

    public hasATO(): boolean {
        return !!this.subscriptions.hasATO;
    }

    public hasATOExpired(): boolean {
        return !!this.subscriptions.hasATOExpired;
    }

    /**
     * Used to display the ato configration of the left side menu:
     * Ato + Vpn/Password Manager/Dip/IdTheftProtection/<Other non-protection related tabs> (or more than one at the same time) + My Subscriptions + Support
     * @returns {boolean} True if user has ATO subscription and no extra protection besides ATO
     */
    public hasATOConfiguration(): boolean {
        return !!(this.subscriptions.hasATO && !this.subscriptions.hasExtraProtectionBesidesATO);
    }

    /**
     * Returns 'max_shares' value for given bundle
     * @public
     * @memberof SubscriptionMgmtService
     * @param {object} bundle The bundle data object
     * @returns {number} The number of max shares
     */
    public getBundleMaxShares(bundle: BundleModel): number {
        let sharesNo = 0;

        if (bundle.hasOwnProperty('max_shares')) {
            sharesNo = bundle.max_shares;
        }
        return sharesNo;
    }

    /**
     * Checks eligibility for sharing the subscription based on bundleId, bundle type and the number of max_shares
     * @param {BundleModel} bundle The bundle object
     * @returns {boolean} Returns true if bundle is eligible for sharing, false otherwise
     */
    public isEligibleForSharingSubscription(bundle: BundleModel): boolean {
        return !!(this.isSharedSubscriptionBundle(bundle.bundle_id)
                && this.getBundleMaxShares(bundle) > 0
                && (bundle.type === this.subscriptionsValuesService.type.end_user || bundle.type === this.subscriptionsValuesService.type.nfr));
    }

    /**
     * Check if bundle is eligible for sharing a subscription
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} bundleId The id of the bundle
     * @returns {boolean} The eligibility of the subscription
     */
    public isSharedSubscriptionBundle(bundleId: string): boolean {
        return !!(this.valuesService.sharedSubscriptionStandaloneBundles.has(bundleId)
            || this.valuesService.sharedSubscriptionFamilyPlanBundles.has(bundleId)
            || this.valuesService.sharedSubscriptionBusinessPlanBundles.has(bundleId));
    }

    /**
     * Check if bundle is part of family plan bundles
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} bundleId The id of the bundle
     * @returns {boolean} True if bundleId is a family plan bundle
     */
    public isSharedSubscriptionFamilyPlanBundle(bundleId: string): boolean {
        return !!this.valuesService.sharedSubscriptionFamilyPlanBundles.has(bundleId);
    }

    /**
     * Check if bundle is part of business plan bundles
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} bundleId The id of the bundle
     * @returns {boolean} True if bundleId is a business plan bundle
     */
    public isSharedSubscriptionBusinessPlanBundle(bundleId: string): boolean {
        return !!this.valuesService.sharedSubscriptionBusinessPlanBundles.has(bundleId);
    }

    /**
     * Check if bundle is part of bundles that have group management capabilities
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} bundleId The id of the bundle
     * @returns {boolean} True if bundleId has group management capabilities
     */
    public isSharedSubscriptionWithGroupManagement(bundleId: string): boolean {
        return !!this.valuesService.sharedSubscriptionWithGroupManagement.has(bundleId);
    }

    /**
     * Check if bundle is part of standalone bundles
     * @public
     * @memberof SubscriptionMgmtService
     * @param {string} bundleId The id of the bundle
     * @returns {boolean} True if bundleId is a standalone bundle
     */
    public isSharedSubscriptionStandaloneBundle(bundleId: string): boolean {
        return !!this.valuesService.sharedSubscriptionStandaloneBundles.has(bundleId);
    }

    /**
     * Check if user is payer on the shared subscription
     * @public
     * @memberof SubscriptionMgmtService
     * @param {object} bundle The bundle data object
     * @returns {boolean} True if account is payer
     */
    public accountOwnerIsPayerForBundle(bundle: BundleModel): boolean {
        return !!bundle?.processed?.sharedInfo?.isPayer;
    }

    /**
     * Check if user is gainer on the shared subscription
     * @public
     * @memberof SubscriptionMgmtService
     * @param {object} bundle The bundle data object
     * @returns {boolean} True if account is gainer
     */
    public accountOwnerIsGainerForBundle(bundle: BundleModel): boolean {
        return !!bundle?.processed?.sharedInfo?.gainer;
    }

    /**
     * Checks if user is eligible for offline to online subscription conversion
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     * @returns {boolean} Returns true if bundle is eligible for conversion
     */
    public isEligibleForOfflineToOnlineConversion(bundle: BundleModel): boolean {
        return !!(bundle?.service_type === this.subscriptionsValuesService.serviceType.license
                    && !this.subscriptionsValuesService.excludedSourceTypesForARFlow.has(bundle?.source?.type));
    }

    /**
     * Checks if subscriptions page requires user attention
     * @public
     * @memberof SubscriptionsService
     * @returns {boolean} True if at least 1 non-trial subscription is expired,
     * or if a trial subscriptions is expired and there's no active service for the same product (with same bundle_id)
     */
    public subscriptionsPageRequiresAttention(): boolean {
        if (this.hasNonTrialBundleExpired()) {
            return true;
        }

        for (const bundle of this.subscriptions.allServices) {
            if (this.isTrialBundle(bundle)
                && this.isExpiredForLessThan30Days(bundle)
                && !this.subscriptions.activeBundleIds.has(bundle.bundle_id)) {
                return true;
            }
        }

        return false;
    }


    hasAutoRenewalOn() {
        return !!this.subscriptions.hasAutoRenewalOn;
    }

    hasPaidSecurityBundles(): boolean {
        for (const bundle of this.getActiveBundles()) {
            if (bundle.type === this.subscriptionsValuesService.type.end_user
                && bundle.status === this.subscriptionsValuesService.status.active
                && this.valuesService.securityBundles.has(bundle.bundle_id)) {
                return true;
            }
        }
        return false;
    }

    hasOnlyTrialSecurityBundles(): boolean {
        for (const bundle of this.getActiveBundles()) {
            if (bundle.type === this.subscriptionsValuesService.type.trial && !this.valuesService.securityBundles.has(bundle.bundle_id)) {
                return false;
            }
        }
        return true;
    }

    hasVpnRecomCard(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (this.valuesService.vpnApps.has(app.app_id)) {
                    return true;
                }
            }
        }
        return false;
    }

    hasPremiumVpnRecomCard(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (this.valuesService.vpnApps.has(app.app_id) && app?.app_params?.level === AppLevels.PREMIUM) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check if user has configured vpn (appVPN installed - at least 1 occupied slot)
     * @public
     * @memberof SubscriptionsService
     * @param {none}
     * @returns {boolean}
     */
    public hasVpnConfigured(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (app.app_id === this.valuesService.appVPN && app.interface?.configurationStatus === AppConfigurationStatus.INSTALLED) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check if user has configured security (any security product installed - at least 1 occupied slot)
     * @public
     * @memberof SubscriptionsService
     * @param {none}
     * @returns {boolean}
     */
    public hasSecurityConfigured(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (this.valuesService.protectionApps.has(app.app_id) && app.interface?.configurationStatus === AppConfigurationStatus.INSTALLED) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check if user has configured webmail protection
     * @memberof SubscriptionsService
     * @param {none}
     * @returns {boolean}
     */
    public hasWebmailProtectionConfigured(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (app.app_id === this.valuesService.appWebmailProtection && app.interface?.configurationStatus === AppConfigurationStatus.INSTALLED) {
                    return true;
                }
            }
        }
        return false;
    }

    getDevicesForVpn() {
        const servicesList = this.getAllFilteredServices();
        const serviceInfos = [];
        let found = false;

        for (const service of servicesList) {
            const serviceInfo = {
                allSlots: 0,
                unlimited: false,
                created: 0,
                slotIds: [],
                bundleId: '',
                serviceId: ''
            };

            found = false;
            if (service.status !== this.subscriptionsValuesService.status.active) {
                continue;
            }

            for (const app of service.applications) {
                if (app.app_id === this.valuesService.appVPN) {
                    serviceInfo.allSlots = app.slots.max;
                    if (app.slots.max === -1) {
                        // iau un numar maxim de 1000 de devices
                        serviceInfo.allSlots = 1000;
                        serviceInfo.unlimited = true;
                    }
                    found = true;
                }
            }

            if (found) {
                serviceInfo.bundleId = service.bundle_id;
                serviceInfo.created = service.created;
                serviceInfo.serviceId = service.service_id;
                serviceInfo.slotIds = this.getSlotIds(service);
                serviceInfos.push(serviceInfo);
            }
        }
        return serviceInfos;
    }

    getSlotIds(service) {
        const slotIds = [];
        const accounts = service.accounts;
        if (!accounts) {
            return slotIds;
        }

        for (const account of accounts) {
            const usageSummary = account.usage_summary;
            for (const summary of usageSummary) {
                if (summary.app_id === this.valuesService.appVPN) {
                    slotIds.push(summary.slot_id);
                }
            }
        }
        return slotIds;
    }

    // incearca sa gaseasca un serviciu >= cu cel curent, care are sloturi libere
    // daca se gaseste acest serviciu, inseamna ca nu avem limit reached pt serviuciul dat ca parametru
    // deoarece avem un alt serviciu cu sloturi libere care indeplineste aceleasi sarcini si userul poate sa adauge acolo device-uri
    hasSimilarOrSuperiorIncompleteService(bundle): boolean {
        const services = this.getAllFilteredActiveServices();

        for (const batchOrder of this.valuesService.orderedServices) {
            const currentBundleRank = batchOrder.indexOf(bundle.bundle_id);

            for (const service of services) {
                // daca serviciul  nu e activ sau e full, conitnuam cautarea
                if (service.status !== this.subscriptionsValuesService.status.active || this.allSlotsAreFull(service)) {
                    continue;
                }

                // cautam servicii identice cu sloturi goale
                if (service.bundle_id === bundle.bundle_id && !this.allSlotsAreFull(service)) {
                    return true;
                }

                // cautam daca avem un serviciu superior cu sloturi goale
                const otherBundleRank = batchOrder.indexOf(service.bundle_id);
                if (currentBundleRank !== -1 && otherBundleRank >= currentBundleRank) {
                    return true;
                }
            }
        }

        return false;
    }

    checkForLimitReachedBannerAppearance() {
        const services = this.getAllFilteredActiveServices();
        for (const service of services) {
            if (this.showBuyMoreProtectionLink(service)) {
                return true;
            }
        }
        return false;
    }

    getHighestLimitReachedService() {
        let maxPriority = -1;
        let maxBundle = null;
        const services = this.getAllFilteredActiveServices();

        for (const bundle of services) {
            if (this.showBuyMoreProtectionLink(bundle)) {
                const priority = this.valuesService.orderedServicesFirstRecommendationCard.indexOf(bundle.bundle_id);
                if (priority > maxPriority) {
                    maxBundle = bundle;
                    maxPriority = priority;
                }
            }
        }
        return maxBundle;
    }

    allSlotsAreFull(bundle: BundleModel) {
        return bundle.interface.devices.length === bundle.slots;
    }

    showBuyMoreProtectionLink(bundle: BundleModel) {
        const hasMsporXsp = this.profilesService.hasMspOrXspLevelOne();

        // momentan pt subscriptiile shared nu afisam buy more protection/ buy more slots
        if (bundle?.processed?.sharedInfo?.isShared) {
            return false;
        }

        // Will only be shown for specific bundles
        if (!this.valuesService.bundlesWithProtectMoreDevices.has(bundle.bundle_id)) {
            return false;
        }

        // "Buy more slots" won't be shown for msp level 1 or soho
        if (hasMsporXsp || this.hasSohoSubscription()) {
            return false;
        }

        // "Buy more slots" won't be shown if bundle is other than active (expired or cancelled)
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return false;
        }

        // "Buy more slots" won't be shown for life_cycle = lifetime or type = free, trial, nfr
        if (bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.lifetime || this.valuesService.typesWithoutProtectMoreDevices.has(bundle.type)) {
            return false;
        }

        // "Buy more slots" will not be displayed if some slots are still empty
        if (!this.allSlotsAreFull(bundle)) {
            return false;
        }

        // "Buy more slots" will not be displayed if superior or similar services has empty slots
        if (this.hasSimilarOrSuperiorIncompleteService(bundle)) {
            return false;
        }

        return true;
    }

    /**
     * Checks if the subscription end date should be hidden
     * @param {BundleModel} bundle The bundle object
     * @returns {boolean} Returns true if the end date should be hidden, false otherwise
     */
    public hideEndDate(bundle: BundleModel): boolean {
        return this.valuesService.boxBundles.has(bundle.bundle_id) && bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.recurrent;
    }

    /**
     * Gets the service to be reallocated based on given bundle
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     * @returns {BundleModel} The service to be reallocated or null if no service was found
     */
    public getServiceToBeReallocated(bundle: BundleModel): BundleModel {
        let serviceToBeReallocated = null;
        if (this.valuesService.bundlesThatReallocateDevices.has(bundle.bundle_id)) {
            let maxPriority = -1;

            for (const service of this.getAllActiveServices()) {
                const priority = this.valuesService.bundlesWhoseDevicesAreReallocated.indexOf(service.bundle_id);

                if (bundle.bundle_id !== service.bundle_id
                    && priority > -1
                    && bundle.slots >= service.slots
                    && service.active_slots >= 1
                    && service.life_cycle !== this.subscriptionsValuesService.lifeCycle.lifetime) {

                    // User has several bundles with same priority -> choose the one with the fewest days left
                    if (maxPriority === priority && service.end_date < serviceToBeReallocated?.end_date) {
                        serviceToBeReallocated = service;
                    }
                    if (priority > maxPriority) {
                        maxPriority = priority;
                        serviceToBeReallocated = service;
                    }
                }
            }
        }
        return serviceToBeReallocated;
    }

    /**
     * It is similar to the "createDevicesInfoAllocationInterface" from isubscriptions
     * It is used for a similar purpose and a similar flow
     * @param bundle
     * @returns
     */
    getAppsForEveryDevice(bundle) {
        const apps = {};
        const devices = Array.isArray(bundle?.interface?.devices) ? bundle.interface.devices : [];
        for (const deviceId of devices) {
            const appArr = [];
            const accounts = Array.isArray(bundle?.accounts) ? bundle.accounts : [];
            for (const account of accounts) {
                const usageSummary = Array.isArray(account?.usage_summary) ? account.usage_summary : [];
                for (const usage of usageSummary) {
                    if (usage.slot_id === deviceId) {
                        appArr.push(usage.app_id);
                    }
                }
            }
            apps[deviceId] = {
                appIds: appArr
            };
        }
        return apps;
    }

    /**
     * Get first service ID for install modal CTA
     * @public
     * @memberof SubscriptionsService
     * @returns {string} The service id found
     */
    public getInstallModalCtaServiceId(): string {
        return Object.values(this.subscriptions.servicesIds ?? {})[0] as string;
    }

    /**
     * Checks if the given invite is for a business owner
     * @param {SharedSubscriptionInvite} invite The invite object
     * @returns {boolean} True if the invite is active and the user is a business owner
     */
    public isBusinessOwner(invite: SharedSubscriptionInvite): boolean {
        return !!(adminRoles.has(invite.role)
                && invite.member_label === GroupRoleUI?.[invite.role]?.label?.[GroupMemberLabel.BUSINESS_OWNER]);
    }

    /**
     * Sets 'hasIndividualPlanWithoutParental' flag if the user has an individual plan without parental
     * @private
     * @memberof SubscriptionsService
     * @param {BundleModel} bundle The bundle object
     * @returns {void}
     */
    private setHasIndividualPlanWithoutParental(bundle: BundleModel): void {
        if (this.valuesService.individualBundlesWithoutParental.has(bundle.bundle_id)
            && !this.isEligibleForSharingSubscription(bundle)) {
            this.subscriptions.hasIndividualPlanWithoutParental = true;
        }
    }

    /**
     * Checks if the user has an individual plan without parental
     * @public
     * @memberof SubscriptionsService
     * @param {none}
     * @returns {boolean} Returns the value of the flag
     */
    public hasIndividualPlanWithNoParental(): boolean {
        return !!this.subscriptions.hasIndividualPlanWithoutParental;
    }
}
